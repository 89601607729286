define(["require", "exports", "@/util", "@/components/userModify/CooperationPopperUser", "./ModificationConfig", "./style/popper.css"], function (require, exports, util_1, CooperationPopperUser_1, ModificationConfig_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var CooperationBlockPopperManager = /** @class */ (function () {
        function CooperationBlockPopperManager(coGroup, popperCoUsers) {
            this.classNames = {
                container: 'block-avatar-group-invisible-avatar-container'
            };
            this.coGroup = coGroup;
            this.groupEmitter = coGroup.emitter;
            this.show = false;
            this.popperCoUsers = popperCoUsers;
            this.element = this.make();
            this.mount();
            this.updateContainerHeight();
            this.emitter = new util_1.BriefEmitter();
        }
        CooperationBlockPopperManager.prototype.make = function () {
            var dom = document.createElement('div');
            dom.classList.add(this.classNames.container);
            dom.append.apply(dom, this.initAvatar());
            return dom;
        };
        CooperationBlockPopperManager.prototype.mount = function () {
            this.coGroup.element.append(this.element);
        };
        CooperationBlockPopperManager.prototype.toggleShow = function () {
            if (this.show) {
                this.close();
            }
            else {
                this.open();
            }
        };
        CooperationBlockPopperManager.prototype.close = function () {
            this.show = false;
            this.element.style.setProperty('display', 'none');
        };
        CooperationBlockPopperManager.prototype.open = function () {
            this.show = true;
            this.element.style.setProperty('display', 'block');
        };
        CooperationBlockPopperManager.prototype.updateContainerHeight = function () {
            var containerWidth = ModificationConfig_1.width.popper;
            var avatarWidth = ModificationConfig_1.width.avatar;
            var rowAvatarCount = Math.floor(containerWidth / avatarWidth);
            var avatarMargin = (containerWidth - rowAvatarCount * avatarWidth) / (rowAvatarCount + 1);
            var rowCount = Math.ceil(this.popperCoUsers.length / rowAvatarCount);
            var containerHeight = rowCount * rowAvatarCount + (rowCount + 1) * avatarMargin;
            this.element.style.setProperty('height', containerHeight + 'px');
            this.popperCoUsers.forEach(function (poUser, index) {
                var rowIndex = Math.floor(index / rowAvatarCount);
                var columnIndex = index % rowAvatarCount;
                poUser.setFirstStyle((rowIndex + 1) * avatarMargin + rowIndex * avatarWidth, (columnIndex + 1) * avatarMargin + columnIndex * avatarWidth, 1, 'block');
            });
        };
        CooperationBlockPopperManager.prototype.initAvatar = function () {
            return this.popperCoUsers.map(function (poUser) {
                return poUser.element;
            });
        };
        CooperationBlockPopperManager.prototype.addPopperUser = function (info) {
            var existedPoUser = this.popperCoUsers.find(function (_PoUser) { return _PoUser.uid === info.uid; });
            if (existedPoUser) {
                Object.assign(existedPoUser, info);
            }
            else {
                var newPoUser = new CooperationPopperUser_1.CooperationPopperUser(info, this.emitter);
                this.element.appendChild(newPoUser.element);
                this.popperCoUsers.push(newPoUser);
            }
            this.updateContainerHeight();
        };
        CooperationBlockPopperManager.prototype.removePopperUse = function (uid) {
            var existedPoUser = this.popperCoUsers.find(function (_PoUser) { return _PoUser.uid === uid; });
            if (!existedPoUser) {
                console.error('existedPoUser null');
                return;
            }
            this.popperCoUsers = this.popperCoUsers.filter(function (_PoUser) { return _PoUser.uid !== uid; });
            existedPoUser.element.remove();
            this.updateContainerHeight();
        };
        return CooperationBlockPopperManager;
    }());
    exports.CooperationBlockPopperManager = CooperationBlockPopperManager;
});
