<template>
  <div id="dialog-wrapper">
    <div class="dialog-firstPart">
      <div class="dialog-firstPart-avatars"
           ref="avatarsArea"
           :style="{height:avatarsAreaHeight+'px',minHeight:avatarsAreaMinHeight+'px'}">
        <div v-for="user in users"
             :key="user.uid"
             :data-uid="user.uid"
             :style="{width:avatarWidth+'%',paddingBottom:avatarWidth+'%'}"
             @click="handleUserAvatarClick(user)"
             class="dialog-avatar container-client">
          <div class="container-proxy">
            <div class="dialog-avatar-state-showList">
              <div class="dialog-avatar-state-item"
                   v-for="state in (userState?.find(item => item.uid == user.uid)?.['state'])"
                   v-html="iconSvgRawContent[state.name]"
                   :style="{fill:userState?.find(item => item.uid == user.uid)?.['patchedColor']}"
              >
              </div>
            </div>
            <div class="dialog-avatar-wrapper">
<!--              <div class="dialog-avatar-img-proxy container-proxy">-->
                <img class="dialog-avatar-img"
                     @mouseenter="showBubbleDialog(user,$event)"
                     @mouseleave="closeBubbleDialog"
                     :alt="user.name"
                     :src="user.avatar">
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="bubble-dialog-cotainer">
        <bubble-dialog @handleClick="touchBubbleInfo" ref="bubbleDialog">
          <p class="coediteInfo">{{ currentUser?.name }}</p>
        </bubble-dialog>
      </div>
    </div>
    <div class="dialog-secondPart">
      <slot :users="users"></slot>


    </div>
  </div>
</template>
<script>
import anime from '../../assets/js/anime.es';
import BubbleDialog from "./BubbleDialog";
import {debounce,getBase64} from "../../util";

export default {
  name: "userInfoDialog",
  data() {
    return {
      users: [],
      userState: [],
      /**
       * dom style属性值
       */
      avatarsAreaHeight: 0,
      avatarsAreaMinHeight: 0,
      avatarWidth: 0,

      /**
       * 组件配置
       */
      userDefaultState: 'idle',
      colors: {
        used: [],
        remains: ['#d4237a', '#1296db','#669966', '#339999', '#f4ea2a', '#1afa29', '#d81e06', '#993333', '#CCCC00', '#FF9999', '#66CC00']
      },
      rowMaxVolume: 4, //每行能容纳的头像数量
      iconSvgRawContent: {
        idle: '<svg t="1629959249696" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9615" width="16" height="16"><path d="M512 512v-156.444444a42.666667 42.666667 0 0 0-85.333333 0v199.111111a42.666667 42.666667 0 0 0 42.666666 42.666666h142.222223a42.666667 42.666667 0 0 0 0-85.333333z m0 512a512 512 0 1 1 512-512 512 512 0 0 1-512 512z" fill="#1296db" p-id="9616"></path></svg>',
        lag: '<svg t="1629959413099" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10695" width="14" height="14" transform="translate(0,-2)"><path d="M950.316945 959.105905a218.27339 218.27339 0 0 1-310.600133 0L468.46009 786.227919 554.131116 699.703596 725.302508 872.666912c42.835513 43.176832 107.088782 43.176832 139.258082 0 42.835513-43.262161 42.835513-108.112739 0-140.538027L703.970081 559.250899l85.671026-86.438994 171.342051 172.877987a214.262894 214.262894 0 0 1-10.666213 313.416013z m21.332427-896.985878c21.503086 21.673746 21.503086 64.850577 0 97.275865l-802.952542 810.546885c-32.169299 32.425289-75.004812 32.425289-96.422568 0-21.332427-21.673746-21.332427-64.850577 0-97.275865L875.312133 62.120027c21.417756-21.588416 64.253269-21.588416 96.422568 0zM72.359591 72.956899c85.671026-97.275866 224.843777-97.275866 310.600133 0L554.045786 245.749556l-85.671025 75.68745-171.342052-162.126443c-42.835513-43.176832-107.088782-43.176832-139.172751 0-32.169299 43.262161-42.835513 108.112739 0 140.538027L329.287339 472.726576l-96.422569 86.438993L72.274262 386.372912c-96.422569-86.524323-96.422569-226.97702 0-313.416013z" p-id="10696" fill="#8a8a8a"></path></svg>',
        editing: '<svg t="1629959639688" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12216" width="16" height="16"><path d="M917.439544 95.570659c-41.392736-40.830941-83.744309-49.390897-112.073518-49.390897-17.34504 0-28.721135 3.322675-29.960359 3.716648l-4.787026 1.408069-589.086679 581.52547L41.638841 955.026092l326.419328-138.092102L953.652304 238.9553l2.758834-2.703575 1.520633-3.547803C959.114712 229.945089 986.71021 163.883511 917.439544 95.570659zM921.888879 214.119659 345.079828 783.423783l-226.736001 95.910397 97.148597-223.808322 440.781982-435.122075 103.362112 102.071723 28.497031-28.15934-103.392812-102.013395 31.956829-31.546483 103.369276 102.078887 28.498054-28.103058-103.407138-102.071723 46.299489-45.706995c3.15383-0.50756 7.941879-1.014096 13.910824-1.014096 20.668739 0 51.981908 6.532787 83.632769 37.732369C931.350368 165.57299 924.929122 203.362664 921.888879 214.119659zM592.991104 968.880635l0-39.760562L102.969675 929.120073l-61.330834 25.90602 0 13.854543L592.991104 968.880635z" p-id="12217"></path></svg>'
      },
      rules: {
        idle: {notAllow: ['editing', 'lag']},
        lag: {notAllow: ['editing', 'idle']},
        editing: {notAllow: ['idle', 'lag'], useTimer: true}
      },
      /**
       * 组件状态
       * */
      userNumDelta: 'inception',
      timer: null,
      fnTemp1: null,
      fnTemp2: null,
      domTemp1: null,
      positionAnime: null,
      currentUser: null,
      resizeHandler:null,
      bubbleMergeInterval:500,
    }
  },
  components: {
    BubbleDialog
  },
  methods: {
    /**
     * 主要用于vue挂载完成后获取dom信息来更新data的值
     */
    initData() {
      this.computeAvatarsAreaMinHeight();
    },

    handleUserAvatarClick({uid}){
      // this.removeUser(uid);
    },
    //播放头像动画
    animateAvatar( avatars, cb) {
      // console.log('%c this.userNumDelta','background:#8F6699;color:white',this.userNumDelta)
      // this.positionAnime?.seek(this.positionAnime.duration);
      // if (position.length == 0 || avatars.length == 0) {
      //   return;
      // }
      if (this.userNumDelta === 'add') {
        cb();
        let avatar = [].slice.call(avatars, -1)[0];
        // avatar.style.top = position[avatars.length - 1].top;
        // avatar.style.left = position[avatars.length - 1].left;
        this.positionAnime = anime({
          targets: avatar,
          scale: [{value: 0, duration: 0}, {value: 1, duration: 2000}]
        })
      } else if (this.userNumDelta === 'minus') {
        // let accumulation_time = 0;
        // this.positionAnime = anime({
        //   targets: avatars,
        //   delay: function (el, i) {
        //     if (el.style.top != position[i].top || el.style.left != position[i].left) {
        //       accumulation_time += 50;
        //     }
        //
        //     return accumulation_time;
        //   },
        //   top: function (el, i) {
        //     return position[i].top;
        //   },
        //   left: function (el, i) {
        //     return position[i].left;
        //   },
        //   duration: 1000,
        //   changeComplete: cb
        // });
      }else if( this.userNumDelta === 'resize'){
        // cb();
        // let accumulation_time = 0;
        // this.positionAnime = anime({
        //   targets: avatars,
        //   delay: function (el, i) {
        //     if (el.style.top != position[i].top || el.style.left != position[i].left) {
        //       accumulation_time += 50;
        //     }
        //
        //     return accumulation_time;
        //   },
        //   top: function (el, i) {
        //     return position[i].top;
        //   },
        //   left: function (el, i) {
        //     return position[i].left;
        //   },
        //   duration: 1000,
        // });
      }else {
        cb();
        // [].forEach.call(avatars, (avatar, i) => {
        //   avatar.style.top = position[i].top;
        //   avatar.style.left = position[i].left;
        // })
        this.positionAnime = anime({
          targets: avatars,
          delay: anime.stagger(50),
          scale: [{value: 0, duration: 0}, {value: 1, duration: 2000}]
        })
      }

    },
    addUser(userInfo) {
      if(userInfo.uid === this.$getCookie("uid")){
        return;
      }
      let user = this.users.find(_user => _user.uid === userInfo.uid);
      if(user){
        /**
         * 如果用户已经登录,检查sid是否一致
         */
        user.userSessions.add(userInfo.sessionId)
      }else{
        /**
         * 未登录则新添
         */
        const newUser = {
          avatar:userInfo.headImgUrl,
          uid:userInfo.uid,
          name:userInfo.userName,
          userSessions: new Set([userInfo.sessionId])
        };
        this.users.push(newUser)
        newUser.avatar = userInfo.headImgUrl;
      }

      // this.resolveUserState(state);
    },
    computeAvatarsAreaMinHeight(){
      //获取头像容器的宽度,根据rowMaxVolume(每行容纳多少头像)计算头像的高宽
      let {width: areaWidth} = this.$refs.avatarsArea.getBoundingClientRect()
      this.avatarsAreaMinHeight = areaWidth / this.rowMaxVolume;
    },
    computeAvatarAreaHeightWidth(){
      let avatarInfoHeight;
      let avatars = this.$refs.avatarsArea.querySelectorAll('.dialog-avatar');
      if (avatars.length !== 0) {
        let avatarRect = avatars[0].getBoundingClientRect();
        avatarInfoHeight = avatarRect.height;
      }
      this.avatarsAreaHeight = avatarInfoHeight * Math.ceil(this.userNum / this.rowMaxVolume);
    },
    showBubbleDialog(user, e) {

      this.currentUser = user;
      this.domTemp1?.removeEventListener('mouseenter', this.fnTemp1);
      this.domTemp1?.removeEventListener('mouseleave', this.fnTemp2);
      clearTimeout(this.timer);

      let imgWrapper = e.currentTarget.closest('.dialog-avatar');
      let bubbleDialog = this.$refs.bubbleDialog;
      let position = {};
      position.left = imgWrapper.offsetLeft + imgWrapper.offsetWidth / 2;
      position.top = imgWrapper.offsetTop + imgWrapper.offsetHeight;
      if (bubbleDialog.display === 'none') {
        bubbleDialog.createBubble(position)
      } else {
        bubbleDialog.moveBubble(position);
      }
      bubbleDialog.showBubbleDialog();
    },
    touchBubbleInfo(e){
      // this.$refs.bubbleDialog.flingBubble(e);
    },
    closeBubbleDialog() {
      let bubbleDialog = this.$refs.bubbleDialog;
      this.bindAutoHideEventOntoDom(bubbleDialog.$el, () => {
        bubbleDialog.pokeBubble();
      })
    },
    bindAutoHideEventOntoDom(targetDom, hideFn) {
      this.domTemp1 = targetDom;
      targetDom.removeEventListener('mouseenter', this.fnTemp1);
      targetDom.removeEventListener('mouseleave', this.fnTemp2);

      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        hideFn()
        this.timer = null;
      }, this.bubbleMergeInterval);

      this.fnTemp1 = () => {
        clearTimeout(this.timer);
        this.timer = null;
      };

      this.fnTemp2 = () => {
        this.timer = setTimeout(() => {
          hideFn()
          this.timer = null;
        }, this.bubbleMergeInterval);
      };

      targetDom.addEventListener('mouseenter', this.fnTemp1);
      targetDom.addEventListener('mouseleave', this.fnTemp2);
    },
    removeUser(deleteUid) {
      let avatar;
      if (deleteUid == null) {
        deleteUid = this.users.slice(0)[0].uid;
      }
      avatar = [].find.call(
          this.$refs.avatarsArea.querySelectorAll('.dialog-avatar'),
          (item) => {
            return item.dataset.uid == deleteUid
          }
      );


      let cb = () => {

        let index = this.users.findIndex(item => item.uid == deleteUid);
        let { avatar:needRevokeUrl } = this.users.splice(index,1);
        /**
         *  将createObjectURL方法的创建的对象通过revokeObjectURL方法来释放
         */

        URL.revokeObjectURL(needRevokeUrl);
        this.computeAvatarAreaHeightWidth();
        // this.removeUserState(deleteUid);
      }
      this.removeAvatarAnime(avatar, cb);
    },
    /**
     * 用户退出多端登录时调用
     * @param userInfo
     */
    removeUserSession(userInfo){
      let {uid,sessionId} = userInfo;
      const removeUser = this.users.find(_user => _user.uid === uid)
      if(removeUser){
        removeUser.userSessions.delete(sessionId)
        if(removeUser.userSessions.size === 0){
          this.removeUser(uid);
        }
      }

    },
    removeAvatarAnime(avatar, cb) {
      this.positionAnime?.seek(this.positionAnime.duration);
      anime({
        targets: avatar,
        scale: 0,
        duration: 500,
        easing: 'easeInOutCubic',
        complete: cb
      })
    },
    updateDialogAvatarInfo() {
      let avatars = this.$refs.avatarsArea.querySelectorAll('.dialog-avatar');
      // let position = this.updatePosition(this.userNum, avatarInfoWidth, this.rowMaxVolume, avatarInfoHeight);
      let cb = () => {
        this.computeAvatarAreaHeightWidth();
      }
      this.animateAvatar( avatars, cb);
    },
    updatePosition(avatarNum, avatarInfoWidth, rowMaxVolume, avatarInfoHeight) {
      if (avatarNum == 0) {
        return [];
      }
      let position = [];

      for (let i = 0; i < avatarNum; i++) {
        position.push({
          left: avatarInfoWidth * (i % rowMaxVolume) + 'px',
          top: (rowMaxVolume > i ? 0 : Math.floor(i / rowMaxVolume) * avatarInfoHeight) + 'px'
        })
      }
      return position;
    },

    devTool() {
      window.dbtn = (uid) => {
        this.removeUser(uid)
      };
      window.abtn = (uid) => {
        this.addUser({
          name: 'xxx',
          uid: uid ? uid : Date.now(),
          avatar: '/static/img/test_avatar.jpg'
        });
      }
    },
    bindWindowEventListeners(){
      let _updateDialogAvatarInfo = debounce(this.updateDialogAvatarInfo,500);
      this.resizeHandler = ()=>{
        this.computeAvatarsAreaMinHeight();
        this.computeAvatarAreaHeightWidth();

        this.userNumDelta = 'resize';

        _updateDialogAvatarInfo();
      }
      window.addEventListener('resize',this.resizeHandler);
    },
    removeWindowEventListeners(){
      window.removeEventListener('resize',this.resizeHandler);
    },
  },
  watch: {
    users: {
      deep: true,
      handler() {
        console.log('users watch emit');
        this.$nextTick(() => {
          if (this.users.length == 0) {
            return;
          }else{
            this.updateDialogAvatarInfo();
          }
        })
      }
    },
    userNum(newVal, oldVal) {
      this.userNumDelta = newVal - oldVal > 0 ? 'add' : 'minus'
    }
  },
  computed: {
    userNum() {
      return this.users.length;
    },
  },
  created() {
    this.avatarWidth = 100 / this.rowMaxVolume;
  },
  mounted() {
    this.devTool();
    this.initData();
    this.bindWindowEventListeners();
    if (this.userNum != 0) {
      this.updateDialogAvatarInfo();
    }

  },
  unmounted() {
    this.removeWindowEventListeners();
  }

}
</script>
<style scoped>
#dialog-wrapper {
  z-index: 99;
  width: 100%;
}

.dialog-firstPart {
  width: 100%;
  position: relative;
}

.dialog-firstPart-avatars {
  width: 100%;
  text-align: left;
  overflow: scroll;
}

.dialog-avatar {
  display: inline-block;
  /*position: absolute !important;*/
  /*top: 0;*/
  /*left: 0;*/
}

.container-client {
  height: 0;
  position: relative;
}

.container-proxy {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.dialog-avatar-wrapper {
  width: 100%;
  height: 100%;
  padding: 10%;
  box-sizing: border-box;
  /*padding-bottom: 100%;*/
}

.dialog-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.dialog-avatar-img-proxy {
  padding: 10%;
}

.bubble-dialog-cotainer {
  width: 50px;
}


.dialog-avatar-state-showList {
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 1;
  bottom: 0;
  text-align: right;
}

.dialog-avatar-state-item {
  display: inline-block;
  width: 30%;
  height: 20%;
  pointer-events: none;
}

.coediteInfo{
  color: #000;
  background: #fff;
  /*mix-blend-mode: lighten;*/
  padding: 10px;
  border-radius: 4px;
  /*user-select:none;*/
  margin: 0;
}
</style>
