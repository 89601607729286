<template >
  <el-popover ref="popover" width="530px" >
    <template #reference>
      <div>
        <span id="template-button" v-if="!readOnly">
          <button class="gray-btn-shimo">协作</button>

        </span>
      </div>
    </template>
    <div class="share-container" v-loading="loadingSendEmail" element-loading-spinner="el-icon-loading">
      <div class="title flex items-center">
        <div>
        <i class="el-icon-back" style="color: #0a84ff"
           v-if="shareEditMode" @click="shareTarget='';backAndReload();"></i></div>
        <h3>邀请他人加入协作</h3><span v-if="isApprover" style="background: #ff9100;color: white;padding: 2px 10px;margin-left: 10px">审批模式</span>
      </div>
      <div class="pd030" style="margin-bottom: 10px" v-if="isApprover"><span style="color: gray">审批模式下邀请的用户只在邀请人是审批者时才有权限查看或编辑相关内容</span></div>
      <div class="input-container">
        <el-input prefix-icon="el-icon-search" placeholder="输入 姓名/邮箱/手机号 添加协作权限" size="small" :input-style="{'border-radius':0}" v-model="shareTarget" @input="getRecentAndEdit" @click="showAutoComplete=true"></el-input>
      </div>



      <div v-if="!shareEditMode" style="margin-top: 20px" class="approver ">
        <div class="flex justify-between pd030">
          <span>协作者</span>
           <el-button type="text" size="mini" style="padding: 0;min-height: 0" @click="getRecentAndEdit"><i class="el-icon-circle-plus" style="margin-right: 5px"></i>添加协作者</el-button>

        </div>

        <div style="border-bottom: solid 1px #ddd;margin: 10px 0" class="pd030"></div>

        <el-scrollbar height="359px">
          <div class="pd030">
            <el-table  :data="sharedList" :show-header="false" :row-style="{cursor:'pointer'}" empty-text="没有协作者" >
              <el-table-column width="60">
                <template #default="scope">

                  <div class="head-icon">
                    <user-icon
                        :url="scope.row.headImgUrl"
                        :uid="scope.userName?getUserName(scope.userName).charAt(0):scope.row.email? scope.row.email.charAt(0) : scope.row.phone?scope.row.phone.charAt(0):scope.row.uid.charAt(0)"
                        :name="scope.userName?getUserName(scope.userName).charAt(0):scope.row.email? scope.row.email.charAt(0) : scope.row.phone?scope.row.phone.charAt(0):scope.row.uid.charAt(0)"
                        :subIcon="scope.row.status==0 ? 'email' :''"
                    >
                    </user-icon>
                  <!--
                    <img v-if="scope.row.headImgUrl" :src="scope.row.headImgUrl"/>
                    <span v-else>
                              {{scope.userName?getUserName(scope.userName).charAt(0):scope.row.email? scope.row.email.charAt(0) : scope.row.phone?scope.row.phone.charAt(0):scope.row.uid.charAt(0) }}
                            </span>
                   -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="60" show-overflow-tooltip>
                <template #default="scope">
                  <div style="overflow-x: hidden;text-overflow: ellipsis;">{{ scope.row.userName?scope.row.userName:'等待接受邀请'}}</div>
                </template>
              </el-table-column>
              <el-table-column min-width="60" show-overflow-tooltip>
                <template #default="scope">
                  <div style="overflow-x: hidden;text-overflow: ellipsis;">{{ scope.row.email?scope.row.email:scope.row.phone?scope.row.phone:null}}</div>
                </template>
              </el-table-column>
              <el-table-column width="160">
                <template #default="scope">
                  <div v-if="!scope.row.isInTeam">
                    <div v-if="scope.row.id">
                      <label>要求加入团队：</label><el-switch v-model="scope.row.needJoinTeam" :disabled="scope.row.viewType===ContractSharePermissionType.FULL_ACCESS" @change="updateNeedJoinTeam(scope.row)"></el-switch>
                    </div>
                    <div v-else>
                      <label>要求加入团队：</label><el-switch v-model="scope.row.needJoinTeam" :disabled="scope.row.viewType===ContractSharePermissionType.FULL_ACCESS"></el-switch>
                    </div>
                  </div>
                  <span v-else >团队成员</span>
                </template>
              </el-table-column>
              <el-table-column width="100" prop="operation">
                <template #default="scope">
                  <el-dropdown  trigger="click" :append-to-body="false">

                    <el-button size="mini" style="width: 82px;padding: 0"><span>{{ shareTypes[scope.row.viewType].name }} </span><i class="el-icon-caret-bottom" style="margin-left: 4px"></i></el-button>

                    <template #dropdown>

                      <el-dropdown-menu >
                        <el-radio-group style="width: 100%;" v-model="scope.row.viewType"
                                        @change="updateViewType(scope.row,scope.row.viewType)">
                          <el-dropdown-item v-for="(item,index) in shareTypes" :key="index" @click.stop>
                            <el-radio  @click.stop :label="item.value" style="width: 100%;height: 100%;display: flex;align-items: center" :disabled="(item.detail && !scope.row.isInTeam && !scope.row.needJoinTeam)||(readOnly&&item.disableOnReadOnly)">{{ item.name }}<span v-if="item.detail && !scope.row.isInTeam &&!scope.row.needJoinTeam" style="background: #0a84ff;color: white;margin-left: 4px;padding: 0px 4px">{{item.detail}}</span></el-radio>
                          </el-dropdown-item>

                        </el-radio-group>
                        <div style="border-bottom: solid 1px #ddd;margin: 10px 0"></div>
                        <el-dropdown-item v-if="scope.row.status==0" @click="resendShareEmail(scope.row)">
                          <div class="operation-btn flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="styles__StyledSVGIconPathComponent-sc-16fsqc8-0 cZjhBA svg-icon-path-icon fill" viewBox="0 0 32 32" width="15" height="15"><defs data-reactroot=""></defs><g><path d="M2 8.4v17.8h28v-17.8l-14 10.8z"></path><path d="M2 4.2v1.6l14 10.8 14-10.8v-1.6z"></path></g></svg>
                            <span>重新发送</span>
                          </div>
                        </el-dropdown-item>
                        <el-dropdown-item @click="deleteContractShareUser(scope.row,scope.$index)">
                          <div class="operation-btn flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="styles__StyledSVGIconPathComponent-sc-16fsqc8-0 cZjhBA svg-icon-path-icon fill" viewBox="0 0 24 24" width="15" height="15"><defs data-reactroot=""></defs><g><path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM6.5 10.5H17.5C18.3284 10.5 19 11.1716 19 12C19 12.8284 18.3284 13.5 17.5 13.5H6.5C5.67157 13.5 5 12.8284 5 12C5 11.1716 5.67157 10.5 6.5 10.5Z"></path></g></svg>
                            <span >移除分享</span>
                          </div>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>

<!--            <div class="flex justify-between " style="margin-top: 20px">-->
<!--              <span>管理者</span>-->
<!--            </div>-->

<!--              <div style="border-bottom: solid 1px #ddd;margin: 10px 0" ></div>-->


<!--            <el-table :data="approverList" :show-header="false">-->
<!--              <el-table-column width="48">-->
<!--                <template #default="scope">-->
<!--                  <user-icon :url="scope.row.headImgUrl" :name="scope.row.userName" :uid="scope.row.userName"></user-icon>-->
<!--&lt;!&ndash;                  <img :src="scope.row.headImgUrl" height="30" width="30" style="border-radius: 15px"/>&ndash;&gt;-->
<!--                </template>-->
<!--              </el-table-column>-->

<!--              <el-table-column class-name="column-email" min-width="100" show-overflow-tooltip>-->
<!--                <template #default="scope">-->
<!--                  <div style="overflow-x: hidden;text-overflow: ellipsis;">{{ scope.row.userName }}</div>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column min-width="100" show-overflow-tooltip>-->
<!--                <template #default="scope">-->
<!--                  <div style="overflow-x: hidden;text-overflow: ellipsis;">{{ scope.row.email }}</div>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column width="100">-->
<!--                <template #default="scope">-->
<!--                  <el-tag>{{ scope.row.role }}</el-tag>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--            </el-table>-->
          </div>
        </el-scrollbar>
      </div>
      <div v-else>
        <el-scrollbar height="400px">
        <div
            class="pd030 approver"
             >
          <el-table :data="FilterUser" :show-header="false" empty-text=" ">
            <el-table-column width="60">
              <template #default="scope">
                <div class="head-icon">
                  <user-icon :url="scope.row.headImgUrl"
                             :name="scope.userName?getUserName(scope.userName).charAt(0):scope.row.email? scope.row.email.charAt(0) : scope.row.phone?scope.row.phone.charAt(0):scope.row.uid.charAt(0)"
                             :uid="scope.userName?getUserName(scope.userName).charAt(0):scope.row.email? scope.row.email.charAt(0) : scope.row.phone?scope.row.phone.charAt(0):scope.row.uid.charAt(0)"
                              :sub-icon="scope.row.status==0 ? 'email':''"
                  >
                  </user-icon>
<!--                  <img v-if="scope.row.headImgUrl" :src="scope.row.headImgUrl"/>-->
<!--                  <span v-else>-->
<!--                          {{scope.userName?getUserName(scope.userName).charAt(0):scope.row.email? scope.row.email.charAt(0) : scope.row.phone?scope.row.phone.charAt(0):scope.row.uid.charAt(0) }}-->
<!--                        </span>-->

                </div>
              </template>

            </el-table-column>
            <el-table-column min-width="60" show-overflow-tooltip>
              <template #default="scope">
                <div style="overflow-x: hidden;text-overflow: ellipsis;">{{ scope.row.userName?scope.row.userName:'待接受邀请' }}</div>
              </template>
            </el-table-column>
            <el-table-column min-width="60" show-overflow-tooltip>
              <template #default="scope">
                <div style="overflow-x: hidden;text-overflow: ellipsis;">{{ scope.row.email?scope.row.email:scope.row.phone?scope.row.phone:null}}</div>
              </template>
            </el-table-column>
            <el-table-column width="160">
              <template #default="scope">
                <div v-if="!scope.row.isInTeam">
                  <div v-if="scope.row.id">
                    <label>要求加入团队：</label><el-switch v-model="scope.row.needJoinTeam" :disabled="scope.row.viewType===ContractSharePermissionType.FULL_ACCESS" @change="updateNeedJoinTeam(scope.row)" ></el-switch>
                  </div>
                  <div v-else>
                    <label>要求加入团队：</label><el-switch v-model="scope.row.needJoinTeam" :disabled="scope.row.viewType===ContractSharePermissionType.FULL_ACCESS"></el-switch>
                  </div>
                </div>
                <span v-else style="background: #0a84ff;color: white;margin-left: 4px;padding: 0px 4px">团队成员</span>
              </template>
            </el-table-column>
            <el-table-column width="100" prop="operation" align="left">
              <template #default="scope">
                <el-dropdown  trigger="click" :append-to-body="false">

                  <el-button size="mini" style="padding: 0;width: 82px"><span>{{ scope.row.viewType!=null?shareTypes[scope.row.viewType].name:'添加权限' }} </span><i class="el-icon-caret-bottom" style="margin-left: 4px"></i></el-button>

                  <template #dropdown>

                    <el-dropdown-menu>
                      <el-radio-group style="width: 100%;" v-model="scope.row.viewType"
                                      @change="updateViewType(scope.row,scope.row.viewType)">
                        <el-dropdown-item v-for="(item,index) in shareTypes" :key="index" >
                          <el-radio :label="item.value" style="width: 100%;height: 100%;display: flex;align-items: center" :disabled="(item.detail && !scope.row.isInTeam && !scope.row.needJoinTeam)||(readOnly&&item.disableOnReadOnly)">{{ item.name }}<span v-if="item.detail && !scope.row.isInTeam &&!scope.row.needJoinTeam" style="background: #0a84ff;color: white;margin-left: 4px;padding: 0px 4px">{{item.detail}}</span></el-radio>
                        </el-dropdown-item>

                      </el-radio-group>
                      <div style="border-bottom: solid 1px #ddd;margin: 10px 0" v-if="scope.row.viewType!=null"></div>
                      <el-dropdown-item v-if="scope.row.status==0" @click="resendShareEmail(scope.row)">
                        <div class="operation-btn flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" class="styles__StyledSVGIconPathComponent-sc-16fsqc8-0 cZjhBA svg-icon-path-icon fill" viewBox="0 0 32 32" width="15" height="15"><defs data-reactroot=""></defs><g><path d="M2 8.4v17.8h28v-17.8l-14 10.8z"></path><path d="M2 4.2v1.6l14 10.8 14-10.8v-1.6z"></path></g></svg>
                          <span>重新发送</span>
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item @click="deleteContractShareUser(scope.row)" v-if="scope.row.viewType!=null">
                        <div class="operation-btn flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" class="styles__StyledSVGIconPathComponent-sc-16fsqc8-0 cZjhBA svg-icon-path-icon fill" viewBox="0 0 24 24" width="15" height="15"><defs data-reactroot=""></defs><g><path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM6.5 10.5H17.5C18.3284 10.5 19 11.1716 19 12C19 12.8284 18.3284 13.5 17.5 13.5H6.5C5.67157 13.5 5 12.8284 5 12C5 11.1716 5.67157 10.5 6.5 10.5Z"></path></g></svg>
                          <span >移除分享</span>
                        </div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        </el-scrollbar>
      </div>

    </div>

  </el-popover>

</template>

<script>
import {
  deleteContractShareUser,
  getContractSharedList, getRecentShares, resendShareEmail, shareContract, updateContractShare,
} from "../../api/api";
import config from "../../config";
import {colorLog, getUserName, isEmail, isPhoneNumber, MetaMessage} from "../../util";
import {ContractSharePermissionType,ContractApprovalStatus} from "../../constant/contract";
import {checkUserBehaviorPermission,BehaviorPermission} from "../common/permissionUtil";
import UserIcon from "../UserIcon";

export default {



  name: "ContractShare",
  components: {UserIcon},
  props: ["contract", "teamMembers","readOnly","isApprover"],
  data() {
    return {
      menuActive: false,
      shareDialogVisible: false,
      approverList: [],
      sharedList: [],
      receivers: [],
      mysharedList:[],
      myShares:[],
      shareEditMode: false,
      shareTarget: '',
      inviteMsg: '请在MetaGo智能合同里查看合同文件.\n' + '谢谢.',
      usersNotInTeam: [],
      notInTeamDialogVisible: false,
      viewType: 0,
      showAutoComplete:false,
      userInfo:JSON.parse(sessionStorage.getItem("userInfo")),
      activeTab:'email',
      inputPhone:'',
      reciver:null,
      metaMessage:new MetaMessage(),
      getUserName,
      ContractSharePermissionType,
      shareTypes:[
        {name:'只读',value:ContractSharePermissionType.READ_ONLY},
        {name:'完全访问',value:ContractSharePermissionType.FULL_ACCESS,detail:'需要加入团队',disableOnReadOnly:true},
        {name:'可编辑',value:ContractSharePermissionType.EDITABLE,disableOnReadOnly:true},
        {name:'可评论',value:ContractSharePermissionType.CAN_COMMENT,disableOnReadOnly:true},
      ],
      ContractApprovalStatus,
      team_members:[],
      checkUserBehaviorPermission,
      BehaviorPermission,
      loadingSendEmail:false


    }

  },
  mounted() {
    this.team_members = this.teamMembers.map((item=>{return {uid:item.uid,userName:item.userName,headImgUrl:item.headImgUrl,email:item.email,phone:item.phone,isInTeam:true}}))
    for (let i in this.team_members) {
      if (this.contract.creatorId == this.teamMembers[i].uid) {
        let creator = JSON.parse(JSON.stringify(this.teamMembers[i]));
        creator.role = "管理者";
        this.approverList.push(creator);

      }

    }
    this.loadSharedList()
  },
  methods: {
    loadSharedList() {
      let getSharedListFunction = null;
      let param = null;
      getSharedListFunction = getContractSharedList;
      param = this.contract.id;
      getSharedListFunction(param).then(res => {
        if (res.status == 200) {
          this.sharedList = res.data.data;
          if(this.isApprover){
            this.sharedList=this.sharedList.filter((item)=>item.shareByApprover)
          }else{
            this.sharedList=this.sharedList.filter((item)=>!item.shareByApprover)
          }

          if (!this.userInfo.phone) {
            for (let i in this.sharedList) {
              if (this.userInfo.email == this.sharedList[i].email ) {
                if (this.sharedList[i].needJoinTeam) {
                  this.$router.push({
                    name:'register',
                    query:{
                      redirect:this.$route.fullPath
                    },
                  })
                  break;
                }
              }
            }
          }

          for (let i in this.sharedList) {
            let sharedItem = this.sharedList[i];
            let member = this.teamMembers.find((item) => (item.email && item.email == sharedItem.email)||(item.phone && item.phone==sharedItem.phone)||(item.uid &&item.uid==sharedItem.uid));
            if (member) {
              sharedItem.uid = member.uid;
              sharedItem.headImgUrl = member.headImgUrl;
              sharedItem.isInTeam=true;
            }

          }



        }
      }).catch(err=>{
        console.log(err)
      })

    },
    getRecentShareList(){
      getRecentShares().then(res=>{
        this.mysharedList = res.data.data;
        for (let i in this.mysharedList) {
          let sharedItem = this.mysharedList[i];
          let member = this.teamMembers.find((item) => (item.email && item.email == sharedItem.email)||(item.phone && item.phone==sharedItem.phone)||(item.uid &&item.uid==sharedItem.uid));
          if (member) {
            sharedItem.uid = member.uid;
            sharedItem.headImgUrl = member.headImgUrl;
            sharedItem.isInTeam=true;
          }
          let shared = this.sharedList.find((item) => (item.email && item.email == sharedItem.email)||(item.phone && item.phone==sharedItem.phone)||(item.uid &&item.uid==sharedItem.uid))
          if(shared){
            sharedItem.id=shared.id;
            sharedItem.viewType=shared.viewType;
            sharedItem.needJoinTeam = shared.needJoinTeam;
            sharedItem.shareByApprover=shared.shareByApprover;
            sharedItem.status = shared.status;
            sharedItem.contractId = shared.contractId;
          }
        }
      })
    },
    notEmpty(str){
      return str!==null && str!==''
    },
    async sendShare(){

      let data = {
        versionId:this.versionId,
        receiver: this.reciver,
        msg: this.inviteMsg,
        url: window.location.href,
        shareByApprover:this.isApprover?this.isApprover:false
      }
      let result = null;
      await shareContract(this.contract.id, data).then(res => {
        if (res.data.code == 0) {

          this.sharedList.push(res.data.data);
          if(res.data.data.message){
            this.metaMessage.info(res.data.data.message);
          }else{
            this.metaMessage.success("分享成功")
          }
          this.backAndReload(false);
          for (let i in this.sharedList) {
            let sharedItem = this.sharedList[i];
            let member = this.teamMembers.find((item) => this.notEmpty(item.phone)&&item.phone == sharedItem.phone);
            if (member) {
              sharedItem.uid = member.uid;
              sharedItem.headImgUrl = member.headImgUrl;
              sharedItem.email = member.email;
              sharedItem.isInTeam = true;
            }
          }
          result=res.data.data;
        }
      }).catch(err => {
        this.metaMessage.error(err)
      })
      return result;

    },

    FilterFuc(item) {
      return (   (item.userName && item.userName.indexOf(this.shareTarget) > -1)
              || (item.email && item.email.indexOf(this.shareTarget) > -1)
              || (this.notEmpty(item.phone)&&item.phone.indexOf(this.shareTarget)>-1)
            )
            && this.receivers.find((a) => (this.notEmpty(a.email) && a.email === item.email) || (this.notEmpty(a.phone) && a.phone === item.phone)) == null
            && this.sharedList.find((b) => (this.notEmpty(b.email) && b.email === item.email) || (this.notEmpty(b.phone) && b.phone === item.phone)) == null
            && item.userName!=this.userInfo.userName
    },
    findInShareList(item){
      return this.sharedList.find((user)=>(this.notEmpty(user.email) && user.email==item.email)||(this.notEmpty(user.phone) && user.phone==item.phone)||(this.notEmpty(user.uid) && user.uid==item.uid))
          || this.receivers.find((user)=>(this.notEmpty(user.email) && user.email==item.email)||(this.notEmpty(user.phone) && user.phone==item.phone)||(this.notEmpty(user.uid) && user.uid==item.uid))


    },
    updateNeedJoinTeam(item){
      console.log(item)
      let updateItem = {id:item.id,contractId:this.contract.id,needJoinTeam:item.needJoinTeam}
      this.updateShare(updateItem);
    },
    updateViewType(item){
      if(!this.findInShareList(item)){
        this.reciver=item;
        this.sendShare().then(res=>{
          Object.assign(item,res);
        })
      }else {
        let updateItem = {id: item.id,contractId:this.contract.id, viewType: item.viewType}
        this.updateShare(updateItem);
      }
    },
    updateShare(item) {


        updateContractShare(item).then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.metaMessage.success("修改成功");
            } else {
              this.metaMessage.error(res.data.msg);
              Object.assign(item,res.data.data);
            }
          } else {
            this.metaMessage.error("网络错误")
          }
        }).catch(e => {
          this.metaMessage.error("网络错误")
        })

    },
    deleteContractShareUser(item, index) {
      deleteContractShareUser(item.contractId,item.id).then(res => {
        if (res.status == 200) {
          if (res.data.code == 0) {
            if(index!=null){
              this.sharedList.splice(index,1)
            }else{
              let i = this.sharedList.findIndex((o)=>(o.uid && o.uid==item.uid)||(o.email && o.email==item.email)||(o.phone && o.phone==item.phone));
              this.sharedList.splice(i,1);
            }
            this.team_members = this.teamMembers.map((item=>{return {uid:item.uid,userName:item.userName,headImgUrl:item.headImgUrl,email:item.email,phone:item.phone,isInTeam:true}}))
            item.id=null;
            item.viewType=null;
            item.status=null;
            item.contractId=null;
            item.needJoinTeam=null;
            item.shareByApprover=null;

            this.metaMessage.success("修改成功");
          } else {
            this.metaMessage.error(res.data.msg);
          }
        } else {
          this.metaMessage.error("网络错误")
        }
      }).catch(e => {
        this.metaMessage.error("网络错误")
      })
    },
    resendShareEmail(item){
      resendShareEmail(item.contractId,item.id,window.location.href,this.inviteMsg).then(res=>{
        if(res.status==200){
          if(res.data.code==0){
            this.metaMessage.success("发送成功");
          }
          else{
            this.metaMessage.error(res.data.msg)
          }

        }else{
          this.metaMessage.error("网络错误");
        }
      }).catch(e=>{
        this.metaMessage.error("网络错误")
        console.error(e)
      })
    },
    backAndReload(back){
      if(back==null || back){
        this.shareEditMode=false;
      }

      this.loadSharedList();
    },
    getRecentAndEdit(){
      if(this.shareTarget=="") {
        this.getRecentShareList()
      }
      this.shareEditMode=true;
    }

  },
  computed:{

    FilterUser(){
      let result=[];

      if(this.shareTarget!==""){
        result= result.concat(this.sharedList.filter((item)=>((item.userName && item.userName.indexOf(this.shareTarget) > -1) || (item.email && item.email.indexOf(this.shareTarget) > -1) || (item.phone && item.phone.indexOf(this.shareTarget) > -1))))
        result=result.concat(this.team_members.filter(this.FilterFuc))

      }


      else{
        result = this.mysharedList
      }


      if(isPhoneNumber(this.shareTarget)){
        if(!result.find((item)=>item.phone===this.shareTarget) && this.approverList.find((item)=>item.phone==this.shareTarget)==null)
          result.push({phone:this.shareTarget});
      }
      else if(isEmail(this.shareTarget)){
        if(!result.find((item)=>item.email===this.shareTarget)&& this.approverList.find((item)=>item.email==this.shareTarget)==null)
          result.push({email:this.shareTarget})
      }
      return  result
    }
  }

}
</script>

<style scoped>
.approver >>> .el-table{
  overflow: unset;
}
.approver >>> .el-table__body-wrapper{
  overflow: unset;
}

.approver >>> .el-table::before {
  height: 0px;
}

.approver >>> .el-table td {
  border: none;
}

.approver >>> .el-table .cell {
  overflow: unset;
}


.head-icon {
}

.view-type-dropdown >>>   .el-button{

  border: 0;
  border-radius: 0;
  height: 53px;
  background: #F4F4F4;
  border-bottom: solid 1px #999;
}
#template-button >>> .el-button{
  background-color: #5280FF !important;
}

.view-type-dropdown >>> {
  margin-left: 10px;
}

.share-container >>> .el-tabs__content{
  overflow: visible;
}

.share-container{
  padding: 10px 0;
}

.title h3{
  margin: 0;
}
.title{
  padding: 0 30px;
  height: 36px;
}
.input-container{
  padding: 0 30px;
  margin-bottom: 20px;
}
.pd030{
  padding: 0 30px;
}

.column-email .cell{
  padding-left: 5px;
}

.share-container >>> .el-dropdown-menu__item{
  height: 40px;
  white-space: nowrap;
}


.share-container >>> .el-dropdown__popper{
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 6px 0px;
  border-radius: 5px;
}
.share-container >>> .el-dropdown__popper .el-dropdown-menu__item:hover{
  background: #ecf1f5;
}

.share-container >>> .el-dropdown__popper .el-popper__arrow::before{
  display: none;

}


.operation-btn svg{
  fill: rgb(176, 176, 176);
  margin-right: 10px;

}


</style>
