<template>
    <div class="extension">
        <div class="u_fNK" :style="{position: 'absolute', top: '0px', left: '0px'}">
            <div :style="{top: position.top + 'px',
        left: position.left + 'px',
        width: position.width + 'px',
        height: position.height + 'px', 'box-sizing': 'content-box',
        position: 'relative', 'pointer-events': 'none',
        overflow: 'hidden',border: '0px','border-radius': '0px',
        padding: '0px', margin: '0px'
        }">
                <div :style="{top:'0px', left: '0px', position: 'absolute'}">
                    <div :style="{width: clientWidth +'px', height: clientHeight + 'px'}">
                        <!--<div v-for="item in highlights" :key="item.id" :data-id="item.id" :ref="'highlight'+item.id"  class="_3F-Wk _3mEyK highlightContainer" :class="{_3GrEs: item.hover}" :style="{top: item.offsetPosition.top + 'px', left: item.offsetPosition.left + 'px', width: item.offsetPosition.width + 'px', height: item.offsetPosition.height + 'px'}"/>-->
                        <!-- class="_3F-Wk _3mEyK highlightContainer"  -->
                        <template v-for="item in highlights">
                            <div v-for="(offsetPos, index) in item.offsetPosition"
                                 :key="item.id+'-'+index" :ref="'highlight'+item.id"
                                 :data-id="item.id"
                                 class="highlightContainer"
                                 :class="getAlertHighlightClassName(item)"
                                 :style="{top: offsetPos.top + 'px', left: offsetPos.left + 'px', width: offsetPos.width + 'px', height: offsetPos.height + 'px'}"/>
                        </template>

                        <template v-if="searchHighLights">
                            <div v-for="(searchHighLight, index) in searchHighLights"
                                 :id="'searchTextHighLight' + index"
                                 :key="'searchTextHighLight' + index"
                                 class="_3F-Wk highlightContainer _3GrEs searchHighLightColor"
                                 :style="{top: searchHighLight.top + 'px', left: searchHighLight.left + 'px', width: searchHighLight.width + 'px', height: searchHighLight.height + 'px'}"/>
                        </template>

                      <template v-for="item in blockTagHighLights">
                        <div v-for="(offsetPos, index) in item.offsetPosition"
                             :key="item.id+'-'+index" :ref="'blockTagHighlight'+item.id"
                             :data-id="item.id"
                             class="blockTagHighlightContainer" :class="{'firstBlockTag':index===0,'active':item.hover}"
                             :style="{top: offsetPos.top + 'px', left: offsetPos.left + 'px', width: offsetPos.width + 'px', height: offsetPos.height + 'px'}"/>
                      </template>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    @import url('../../assets/css/alert.css');
    .blockTagHighlightContainer{
      position: absolute;
    }
    .blockTagHighlightContainer.firstBlockTag{
      border-left: 3px solid darkorange;
    }
    .blockTagHighlightContainer.active{
      background: rgba(10,132,255,0.5);
    }
</style>
<script>
    import {AlertShowType, ErrorType, getAlertShowType} from "../../constant/alert";

    export default {
        props: {
            position: Object
        },
        name: 'checkerInputBox',
        data: function () {
            return {
                clientWidth: document.body.clientWidth,
                clientHeight: document.body.clientHeight,
                highlights: [],
                searchHighLights: [],
              blockTagHighLights:[]
            }
        },
        methods: {
            setHighlights: function (highlights) {
                this.highlights = highlights
            },
            getHighlights: function () {
                return this.highlights
            },
            getHighlightRef: function (id) {
                return this.$refs['highlight' + id]
            },
            getHighlightItem: function (id) {
                for (let index in this.highlights) {
                    let highlight = this.highlights[index]
                    if (highlight['id'] === id) {
                        return highlight
                    }
                }
            },
            setSearchHighLight: function (searchHighLights) {
                this.searchHighLights = searchHighLights
                // console.log("this.searchHighLight === ", this.searchHighLight)
            },
            removeSearchHighLight: function () {
                this.searchHighLights = []
                // console.log("this.searchHighLight === ", this.searchHighLight)
            },
            getAlertHighlightClassName: function (item) {
                let classArray = [];
                const lineBoxType = getAlertShowType(item.showType)
                classArray.push(lineBoxType === AlertShowType.VERTICAL ? 'verticalLineBox' : '_3F-Wk')
                if (item.hover) {
                    classArray.push('_3GrEs');
                }
                if (ErrorType.TYPO === item.errorType) {
                    classArray.push('errorType_color_new_1');
                } else if (ErrorType.PUNCTUATION_MISUSE === item.errorType) {
                    classArray.push('errorType_color_new_2');
                } else if (ErrorType.WORD_ORDER_ERROR === item.errorType) {
                    classArray.push('errorType_color_new_3');
                } else if (ErrorType.SYNONYMOUS_CONFUSION === item.errorType || ErrorType.CONTRACT_RISK === item.errorType) {
                    classArray.push('errorType_color_new_4');
                } else if (ErrorType.GRAMMAR_ERROR === item.errorType || ErrorType.CUSTOM_COMMENT === item.errorType) {
                    classArray.push('errorType_color_5');
                } else if (item.errorType && item.errorType > 100 || ErrorType.NONSTANDARD_CLAUSE === item.errorType) {
                    classArray.push('errorType_color_100');
                } else if (ErrorType.STANDARD === item.errorType) {
                    classArray.push('errorType_color_new_5');
                } else {
                    classArray.push('errorType_color_100');
                }
                return classArray.join(" ")
            },
          setBlockTagHighlights:function (blockTags){
            this.blockTagHighLights = blockTags;
          },
          clearBlockTagHighlights:function (){
              this.blockTagHighLights = [];
          },
          getBlockTagHighlights:function (){
              return this.blockTagHighLights;
          },
          getBlockTagHighlightRef: function (id) {
            return this.$refs['blockTagHighlight' + id]
          },
        }
    }
</script>
