define(["require", "exports", "../../util", "./CooperationUser", "./CooperationBlockAvatarGroup", "./ModificationType", "./ModificationConfig", "./style/manager.css", "./style/variation.css"], function (require, exports, util_1, CooperationUser_1, CooperationBlockAvatarGroup_1, ModificationType_1, ModificationConfig_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var CooperationBlockAvatarManager = /** @class */ (function () {
        function CooperationBlockAvatarManager() {
            this.allCoUsers = [];
            this.allCoGroups = [];
            this.emitter = new util_1.BriefEmitter();
            this.element = this.make();
            this.bindEmitterEvents();
            this.setCssVariations();
        }
        CooperationBlockAvatarManager.prototype.make = function () {
            var dom = document.createElement('div');
            dom.classList.add('block-avatar-manager');
            return dom;
        };
        CooperationBlockAvatarManager.prototype.bindEmitterEvents = function () {
            var _this = this;
            this.emitter.on(ModificationType_1.GROUP_EVENTS.UPDATE_POSITION, function (movingCoGroup, lastPosition) {
                _this.allCoUsers.forEach(function (coUser) {
                    if (coUser.currentCoGroup != movingCoGroup)
                        return;
                    if (!movingCoGroup.referenceNode.isConnected) { //如果referenceNode脱离了文档 把destination为该coGroup的coUser 给drop掉
                        coUser.updateDestination(null); //这里设为空 status就在里面设为drop了
                        coUser.play();
                        return;
                    }
                    if (coUser.status === ModificationType_1.CO_USER_STATUS.Free) {
                        coUser.updateLastStyleToDestination(lastPosition);
                        coUser.play();
                    }
                });
            });
        };
        CooperationBlockAvatarManager.prototype.setCssVariations = function () {
            this.element.classList.add('modify-block-variation');
            this.element.style.setProperty("--modify-popper-width", ModificationConfig_1.width.popper + 'px');
            this.element.style.setProperty("--modify-group-width", ModificationConfig_1.width.avatarContainer + 'px');
            this.element.style.setProperty("--modify-group-horizontal-margin", ModificationConfig_1.width.groupHorizontalMargin + 'px');
            this.element.style.setProperty("--modify-avatar-width", ModificationConfig_1.width.avatar + 'px');
        };
        CooperationBlockAvatarManager.prototype.addUserInfo = function (userInfo) {
            var existedCoUser = this.allCoUsers.find(function (_coUser) { return _coUser.uid === userInfo.uid; });
            if (!existedCoUser) {
                this.allCoUsers.push(new CooperationUser_1.CooperationUser(userInfo, this));
            }
            else {
                //已存在一个uid一致的UserInfo,做覆盖处理
                Object.assign(existedCoUser, userInfo);
            }
        };
        CooperationBlockAvatarManager.prototype.userUpdateOwnBlock = function (referenceNode, uid) {
            var coUser = this.allCoUsers.find(function (_coUser) { return _coUser.uid === uid; });
            if (!coUser) {
                console.error("uid : " + uid + "\u7684 userInfo\u6CA1\u6709\u63D0\u524D\u8F7D\u5165");
                return;
            }
            var assignCoGroup = null;
            var oldCoGroup = null;
            for (var _i = 0, _a = this.allCoGroups; _i < _a.length; _i++) {
                var coGroup = _a[_i];
                var canAssign = coGroup.isSameReference(referenceNode);
                if (canAssign && coGroup.isIncludingUser(coUser) && coUser.status !== ModificationType_1.CO_USER_STATUS.Drop) {
                    //目的group还是自身的group不需要做处理
                    return;
                }
                var isOldGroup = false;
                if (coUser.destination) {
                    isOldGroup = coGroup.isSameReference(coUser.destination.referenceNode) && coGroup.isIncludingUser(coUser);
                }
                if (canAssign) {
                    assignCoGroup = coGroup;
                }
                if (isOldGroup) {
                    oldCoGroup = coGroup;
                }
            }
            if (!oldCoGroup && !assignCoGroup) {
                //(没有旧group也没有可分配的group)需要新建group
                assignCoGroup = new CooperationBlockAvatarGroup_1.CooperationBlockAvatarGroup(referenceNode, this);
                this.allCoGroups.push(assignCoGroup);
                coUser.updateDestination(assignCoGroup);
            }
            else if (!oldCoGroup && assignCoGroup) {
                //(没有旧group,有可分配的group)
                coUser.updateDestination(assignCoGroup);
            }
            else if (oldCoGroup && !assignCoGroup) {
                //(有旧group,没有可分配的group)需要新建group
                assignCoGroup = new CooperationBlockAvatarGroup_1.CooperationBlockAvatarGroup(referenceNode, this);
                this.allCoGroups.push(assignCoGroup);
                coUser.updateDestination(assignCoGroup);
            }
            else if (oldCoGroup && assignCoGroup) {
                //(有旧group,有可分配的group)
                coUser.updateDestination(assignCoGroup);
            }
            coUser.play();
        };
        CooperationBlockAvatarManager.prototype.userQuitCooperateEditing = function (uid) {
            var coUser = this.allCoUsers.find(function (_coUser) { return _coUser.uid === uid; });
            if (!coUser) {
                console.error("uid : " + uid + "\u7684 userInfo\u6CA1\u6709\u63D0\u524D\u8F7D\u5165");
                return Promise.reject();
            }
            var oldCoGroup = coUser.destination;
            coUser.updateDestination(null);
            return coUser.play();
        };
        CooperationBlockAvatarManager.prototype.updateGroupsPosition = function () {
            this.allCoGroups.forEach(function (coGroup) {
                coGroup.updateLastPosition();
                coGroup.play();
            });
        };
        CooperationBlockAvatarManager.prototype.removeUser = function (uid) {
            var targetCoUser = this.allCoUsers.find(function (_coUser) { return _coUser.uid === uid; });
            this.allCoUsers = this.allCoUsers.filter(function (_coUser) { return _coUser.uid !== uid; });
            if (targetCoUser) {
                targetCoUser.destroy();
            }
        };
        CooperationBlockAvatarManager.prototype.destroy = function () {
            for (var field in this) {
                if (Object.prototype.hasOwnProperty.call(this, field)) {
                    delete this[field];
                }
            }
            Object.setPrototypeOf(this, null);
        };
        return CooperationBlockAvatarManager;
    }());
    exports.CooperationBlockAvatarManager = CooperationBlockAvatarManager;
});
