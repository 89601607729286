export const duration = {
    group:200,
    node:100,
}
export const count = {
    avatarMaxCount:3,
}

export const width = {
    groupHorizontalMargin:3,
    avatarContainer:60,
    avatar:28,
    popper:130,
}
