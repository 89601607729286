define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var MODIFY_EVENTS;
    (function (MODIFY_EVENTS) {
        MODIFY_EVENTS[MODIFY_EVENTS["BeforeMoveGroupVerticalPosition"] = 0] = "BeforeMoveGroupVerticalPosition";
        MODIFY_EVENTS[MODIFY_EVENTS["MovingGroupVerticalPosition"] = 1] = "MovingGroupVerticalPosition";
    })(MODIFY_EVENTS = exports.MODIFY_EVENTS || (exports.MODIFY_EVENTS = {}));
    var GROUP_EVENTS;
    (function (GROUP_EVENTS) {
        GROUP_EVENTS[GROUP_EVENTS["UPDATE_CO_USERS"] = 0] = "UPDATE_CO_USERS";
        GROUP_EVENTS[GROUP_EVENTS["UPDATE_POSITION"] = 1] = "UPDATE_POSITION";
    })(GROUP_EVENTS = exports.GROUP_EVENTS || (exports.GROUP_EVENTS = {}));
    var UPDATE_BLOCK_WAYS;
    (function (UPDATE_BLOCK_WAYS) {
        UPDATE_BLOCK_WAYS[UPDATE_BLOCK_WAYS["Emerge"] = 0] = "Emerge";
        UPDATE_BLOCK_WAYS[UPDATE_BLOCK_WAYS["Transfer"] = 1] = "Transfer";
    })(UPDATE_BLOCK_WAYS = exports.UPDATE_BLOCK_WAYS || (exports.UPDATE_BLOCK_WAYS = {}));
    var CO_USER_ANIME_EVENTS;
    (function (CO_USER_ANIME_EVENTS) {
        CO_USER_ANIME_EVENTS[CO_USER_ANIME_EVENTS["BeforeLeavingCoGroup"] = 0] = "BeforeLeavingCoGroup";
        CO_USER_ANIME_EVENTS[CO_USER_ANIME_EVENTS["LeavingCoGroup"] = 1] = "LeavingCoGroup";
        CO_USER_ANIME_EVENTS[CO_USER_ANIME_EVENTS["LeftCoGroup"] = 2] = "LeftCoGroup";
        CO_USER_ANIME_EVENTS[CO_USER_ANIME_EVENTS["MovingToCoGroup"] = 3] = "MovingToCoGroup";
        CO_USER_ANIME_EVENTS[CO_USER_ANIME_EVENTS["BeforeEnteringCoGroup"] = 4] = "BeforeEnteringCoGroup";
        CO_USER_ANIME_EVENTS[CO_USER_ANIME_EVENTS["EnteringCoGroup"] = 5] = "EnteringCoGroup";
        CO_USER_ANIME_EVENTS[CO_USER_ANIME_EVENTS["EnteredCoGroup"] = 6] = "EnteredCoGroup";
    })(CO_USER_ANIME_EVENTS = exports.CO_USER_ANIME_EVENTS || (exports.CO_USER_ANIME_EVENTS = {}));
    var CO_USER_STATUS;
    (function (CO_USER_STATUS) {
        CO_USER_STATUS[CO_USER_STATUS["Initial"] = 0] = "Initial";
        CO_USER_STATUS[CO_USER_STATUS["Free"] = 1] = "Free";
        CO_USER_STATUS[CO_USER_STATUS["Grouped"] = 2] = "Grouped";
        CO_USER_STATUS[CO_USER_STATUS["Drop"] = 3] = "Drop";
    })(CO_USER_STATUS = exports.CO_USER_STATUS || (exports.CO_USER_STATUS = {}));
});
