import ReconnectSockJsClient from "./sock-js-client";
import EventEmitter from 'eventemitter3';
import Delta from "../delta/Delta";
import MD5 from "md5";
import {
    RequestMessageType, ConnectMessageType,
    CommandType, ResponseMessageType, EventsType, Source, CommentType, COEditorEventType,ResponseErrorMsgCode
} from "./websocket-message-type";

/**
 *
 */
export default class CoEditor {

    constructor(editor, uid,sid,fileId) {
        this.initData();
        this.editorManager = editor;
        this.uid = uid;
        this.sid = sid;
        this.fileId = fileId;
    }

    initData(){
        this.editorManager = null;
        this.saver = this.debounce(this.sendChange.bind(this), 500);
        this.emitter = new EventEmitter();
        this.changeCache = [];
        // 以下属性统一通过get set方法调用 统一监控
        this._version = 0;
        this._ackStatus = true;
        /**
         * 是否正在中文输入内容
         * @type {{op: *[], ackMsg: obj, state: boolean}}
         */
        this.compositionInfo = {
            state:false,
            op:[],
            ackMsg:null
        };
    }

    get version(){
        return this._version;
    }

    set version(version){
        this._version = version;
    }

    destroy(){
        this.sockClient.close();
        this.sockClient = null;
        this.initData();
    }

    /**
     * 是否正在等待服务器响应
     * @return {boolean}
     */
    get isWaitACK(){
        return this._ackStatus;
    }

    /**
     * 设置ack信息
     * @param status
     */
    set isWaitAck(status){
        this._ackStatus = status;
        console.log('******set ack:',status)
        this.emitter.emit(COEditorEventType.COMMON.ACK_CHANGE,status);
    }

    /**
     * 向服务器发送变化内容
     */
    sendChange() {
        if(this.changeCache.length > 0){
            console.log("changecache",JSON.stringify(this.changeCache))
            if(!this.isWaitACK){
                if(this.compositionInfo.state){
                    console.log("is composition,send err")
                    return;
                }
                this.isWaitAck = true;
                console.log("send changecache",JSON.stringify(this.changeCache))
                this.sendMsg(RequestMessageType.COMMAND,{fileId: this.fileId, commands: this.changeCache, version: this.version, uid:this.uid})
                this.changeCache = [];
            }
        }
    }

    /**
     * call this function when editor is ready
     * 连接到服务器
     */
    connect(url) {
        const sockClient  = this.sockClient = new ReconnectSockJsClient(`${url}?uid=${this.uid}&sid=${this.sid}&fileId=${this.fileId}`);
        sockClient.onopenfun = this.clientOnopen.bind(this);
        sockClient.onmessagefun = this.clientOnmessage.bind(this);
        sockClient.onerrorfun = this.clientOnError.bind(this);
        sockClient.connect();
        this.editorManager.on(EventsType.TEXT_CHANGE, (arg) => {
            const {change, source,type,id} = arg;
            console.log("tchange",JSON.stringify(change),source)
            if (source === Source.USER) {
                if(this.changeCache.length > 0){
                    let lastChange = this.changeCache.pop();
                    if( lastChange?.type === type
                        && lastChange.type !== CommandType.NUMBERING
                        && id === lastChange.id
                    ){
                        lastChange.delta = lastChange.delta.compose(change);
                        this.changeCache.push(lastChange);
                    }else{
                        let bunch = this.buildChangeBunch(change, source,type,id);
                        this.changeCache.push(lastChange);
                        this.changeCache.push(bunch);
                    }
                }else{
                    let bunch = this.buildChangeBunch(change, source,type,id);
                    this.changeCache.push(bunch);
                }
                this.saver();
            }
        });

        this.editorManager.on(EventsType.COMPOSITION_CHANGE,(state)=>{
            this.compositionInfo.state = state
            console.log("COMPOSITION_CHANGE",state,JSON.stringify(this.compositionInfo))
            if(!state){
                if(this.compositionInfo.ackMsg){
                    this.dealHistoryData(this.compositionInfo.ackMsg)
                    this.compositionInfo.ackMsg = null;
                }
                if(this.compositionInfo.op.length>0){
                    this.compositionInfo.op.forEach(op => {
                        this.applyHistoryOperation(op);
                    })
                    this.compositionInfo.op = []
                }
                this.saver();
            }
        })

        this.editorManager.on(EventsType.EDITOR_ERROR,()=>{
            alert("应用失败？？？")
            sockClient.close();
        });

        this.editorManager.on(EventsType.BLOCK_ON_FOCUS,(args) => {
            this.emitter.emit(EventsType.BLOCK_ON_EDIT,Object.assign({uid:this.uid},args))
        })

    }

    /**
     * 构建change的基础结构
     * @param change
     * @param source
     * @param type change类型
     * @param id change的所属id 可为NULL
     * @return {{source, type}}
     */
    buildChangeBunch(change, source,type,id){
        let bunch = {source,type};

        if(type === CommandType.NUMBERING){
            bunch.numbering = change;
        }else{
            bunch.delta = change;
        }

        if(id){
            bunch.id = id;
        }

        return bunch;
    }

    /**
     * socket发送信息
     * @param messageType
     * @param msg
     */
    sendMsg(messageType, msg={}){
        this.sockClient.send(JSON.stringify({messageType, msg}));
    }

    /**
     * 建立连接的回调
     * @param e
     */
    clientOnopen(e) {
        console.log("websocket connected", e)
    }

    /**
     * 连接断开的回调
     * @param e
     */
    clientOnError(e) {
        //TODO 出问题了，通知用户刷新
        console.error(e);
        this.emitter.emit(COEditorEventType.ConnectStatus.COOPERATION_DISCONNECT);
    }

    /**
     * 服务器的信息处理函数
     * @param e
     */
    clientOnmessage(e) {
        const response = JSON.parse(e.data);
        const {messageType, msg} = response;
        const data = msg;
        if(messageType === ResponseMessageType.GET_CONTENT) {
            this.version = this.editorManager.initViewData(data)
            this.emitter.emit(COEditorEventType.Content.GET_CONTENT_SUCCESS);

            this.isWaitAck = false;
        } else if (messageType === ResponseMessageType.ACK) {
            this.isWaitAck = false;
            this.dealHistoryData(data);
        } else if (messageType === ResponseMessageType.BROADCAST) {
            if(this.isWaitACK){
                console.error("等待ack消息返回，暂不处理该版本，当前消息版本，本地版本",data.version,this.version)
                return
            }
            this.applyHistoryOperation(data)
        } else if (messageType === ResponseMessageType.CONNECT){
            this.connectMessage(data);
        } else if(messageType === ResponseMessageType.SYNC_VERSION){
            this.dealHistoryData(data)
        } else if(messageType === ResponseMessageType.COMMENT){
            this.applyComment(data);
        }else if (messageType === ResponseMessageType.ERROR) {
            this.handleError(data);
        }
    }

    /**
     * 处理来自服务器的错误信息，比如没权限等。
     * @param data
     */
    handleError(data) {
        console.log('handle error',data)
        const requestType = data.requestType;
        const errorCode = data.code;
        if (requestType === RequestMessageType.COMMAND) {
            // 如果是delta变化错误，
            if (ResponseErrorMsgCode.NO_COMMENT_PERMISSION === errorCode) {
                // waitACK为true，让编辑器可以发其它内容变化请求。
                this.isWaitAck = false;
                return;
            }
        }
        // 将事件抛出去，做相应处理。
        this.emitter.emit(COEditorEventType.ERROR.HANDLE_ERROR,data)
    }

    /**
     * 应用来自服务器的批注信息
     * @param data
     */
    applyComment(data){
        const type = data.type;
        if(type === CommentType.CREATE){
            this.emitter.emit(COEditorEventType.CommentType.CREATE,data)
        }else if(type === CommentType.DELETE){
            this.emitter.emit(COEditorEventType.CommentType.DELETE,data)
        }else if(type === CommentType.SOLVE){
            this.emitter.emit(COEditorEventType.CommentType.SOLVE,data)
        }else if(type === CommentType.GET){
            this.emitter.emit(COEditorEventType.CommentType.GET,data)
        }
    }

    /**
     * 处理服务器的历史数据
     * @param data
     */
    dealHistoryData(data){
        if(this.compositionInfo.state){
            this.compositionInfo.ackMsg = data;
            return;
        }
        data.historyMsg.forEach((commandMsg) => {
            this.applyHistoryOperation(commandMsg);
        })
        this.version = data.version;
        this.saver();
    }

    /**
     * 应用历史操作
     * @param historyOperation
     */
    applyHistoryOperation(historyOperation){
        if(this.compositionInfo.state){
            this.compositionInfo.op.push(historyOperation)
            return
        }
        if(historyOperation.version > this.version){
            if(historyOperation.version === this.version + 1){
                /**
                 * 基于等待ack途中缓存的change转换操作
                 */
                if(this.changeCache.length > 0){
                    this.transformNextVersionData(historyOperation,this.changeCache);
                }
                /**
                 * 应用服务器的历史操作
                 */
                historyOperation.commands.forEach(delta => {
                    console.log('apply delta:', JSON.stringify(delta['delta']),JSON.stringify(this.changeCache));

                    this.editorManager.applyDelta(delta, Source.API);
                });
                this.calculateAPIDeltaStartBlockIndex(historyOperation.uid,historyOperation.commands[0]);
                this.version = historyOperation.version;
            } else {
                console.log("version产生间隔 history this",historyOperation.version,this.version)
                this.sockClient.close();
                // //todo 优化点 可以设置缓存，在一定时间内没有收到所有广播再向服务器请求
                // console.error("请求服务端拉取间隔的version")
                // this.sendMsg(RequestMessageType.SYNC_VERSION,{version:this.version})
            }
        }else{
            console.log("该版本已被处理")
        }
    }

    calculateAPIDeltaStartBlockIndex(uid,command){
        if(CommandType.DELTA !== command.type || command.delta.ops.length === 0){
            return;
        }
        const firstOp = command.delta.ops[0];
        let editHolder = this.editorManager.getMainEditorBlockHolderAndTextLen(0).holder;
        if(firstOp.retain && !firstOp.attributes){
            const blockLen = this.editorManager.getMainEditorBlocksLen();
            let count = 0;
            for(let index = 0;index<blockLen;index++){
                const {holder,len} = this.editorManager.getMainEditorBlockHolderAndTextLen(index);
                editHolder = holder
                count += len;
                if(count > firstOp.retain.retain){
                    break;
                }
            }
        }
        this.emitter.emit(EventsType.BLOCK_ON_EDIT,{uid,holder:editHolder})
    }

    /**
     * 连接相关信息处理
     * @param msg
     */
    connectMessage(msg){
        const type = msg.type;
        if(type === ConnectMessageType.LOGIN_SUCCESS){
            this.sendMsg(RequestMessageType.GET_CONTENT)
            this.emitter.emit(COEditorEventType.ConnectStatus.LOGIN_SUCCESS,msg)
        } else if(type === ConnectMessageType.LOGIN_FAIL){
            this.sockClient.close();
            this.emitter.emit(COEditorEventType.ConnectStatus.LOGIN_FAIL,msg)
            // todo 跳转？
        } else if (type === ConnectMessageType.COOPERATION_LOGIN){
            this.emitter.emit(COEditorEventType.ConnectStatus.COOPERATION_LOGIN,msg)
        } else if (type === ConnectMessageType.COOPERATION_LOGOUT){
            this.emitter.emit(COEditorEventType.ConnectStatus.COOPERATION_LOGOUT,msg)
        } else if (type === ConnectMessageType.COOPERATION_HELLO){
            this.emitter.emit(COEditorEventType.ConnectStatus.COOPERATION_HELLO,msg)
        }
    }

    debounce(fun, interval) {
        let timeoutId = null;
        const later = () => {
            fun();
            timeoutId = null;
        }

        return () => {
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(later, interval);
        }
    }

    on(...args){
        this.emitter.on(...args);
    }

    /**
     * 转换服务器的历史数据
     * @param historyData 服务器的历史数据
     * @param clientData 客户端已发生的数据
     */
    transformNextVersionData(historyData, clientData){
        historyData.commands.forEach((history) => {
            clientData.forEach((change) => {
                if(change.type === history.type) {
                    if(change.type === CommandType.DELTA){
                        const oldDelta = new Delta(history.delta);
                        history.delta = change.delta.transform(oldDelta, false);
                        change.delta = oldDelta.transform(change.delta,true);
                    }else if(change.type === CommandType.HEADER_DELTA || change.type === CommandType.FOOTER_DELTA){
                        const oldDelta = new Delta(history.delta);
                        history.delta = change.delta.transform(oldDelta, false);
                        change.delta = oldDelta.transform(change.delta,true);
                    }else if(change.type === CommandType.NUMBERING){
                        // numbering类型的数据不需要transform
                    }
                }
            })
        })
    }
}
