define(["require", "exports", "@/components/userModify/ModificationType", "../../../util", "@/assets/js/anime.es", "@/components/userModify/ModificationConfig", "../style/avatar.css"], function (require, exports, ModificationType_1, util_1, anime_es_1, ModificationConfig_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var UserFrame = /** @class */ (function () {
        function UserFrame(info) {
            this.headImgUrl = info.headImgUrl;
            this.uid = info.uid;
            this.userName = info.userName;
            this.isManagerRole = info.isManagerRole;
            this.status = ModificationType_1.CO_USER_STATUS.Initial;
            this._element = this.make();
            this.lastStyle = {
                scale: 1,
                display: 'block'
            };
            this.lastPosition = {
                top: 0,
                left: 0
            };
            this.animation = null;
            this.groupEmitter = null;
        }
        Object.defineProperty(UserFrame.prototype, "element", {
            get: function () {
                return this._element;
            },
            set: function (dom) {
                this._element = dom;
            },
            enumerable: true,
            configurable: true
        });
        UserFrame.prototype.make = function () {
            var dom = document.createElement('div');
            //avatarStyle.modifyBlockAvatar
            dom.classList.add('modify-block-avatar');
            dom.setAttribute('title', this.userName);
            if (!this.headImgUrl) {
                var textFeature = document.createElement('div');
                textFeature.classList.add('modify-block-avatar-text-feature');
                textFeature.style.setProperty("background-color", util_1.getHashColor(this.userName));
                var firstName = util_1.getUserName(this.userName)[0];
                var textSpan = document.createElement('span');
                textSpan.classList.add('mba-tx-span');
                textSpan.innerText = firstName.toUpperCase();
                textFeature.append(textSpan);
                dom.append(textFeature);
            }
            else {
                var img = new Image();
                img.classList.add('modify-block-avatar-img');
                img.src = this.headImgUrl;
                dom.append(img);
            }
            return dom;
        };
        UserFrame.prototype.setFirstStyle = function (top, left, scale, display) {
            if (display === void 0) { display = 'block'; }
            var nodeStyle = this.element.style;
            nodeStyle.setProperty("top", top + 'px');
            nodeStyle.setProperty("left", left + 'px');
            nodeStyle.setProperty("transform", "scale(" + scale + ")");
            nodeStyle.setProperty("display", display);
        };
        UserFrame.prototype.setLastStyle = function (top, left, scale, display) {
            if (scale === void 0) { scale = 1; }
            if (display === void 0) { display = 'block'; }
            this.lastPosition.top = top;
            this.lastPosition.left = left;
            this.lastStyle.scale = scale;
            this.lastStyle.display = display;
        };
        UserFrame.prototype.getElementStyle = function () {
            var styles = getComputedStyle(this.element);
            var regRes = (/^matrix\((\d+),/i).exec(styles.transform);
            var scale = 0;
            if (regRes) {
                scale = parseInt(regRes[1]);
            }
            return {
                top: parseInt(styles.top),
                left: parseInt(styles.left),
                scale: scale,
                display: styles.display,
            };
        };
        UserFrame.prototype.play = function () {
            var proRes = function () { };
            var pro = new Promise(function (res) { return proRes = res; });
            proRes();
            return pro;
        };
        UserFrame.prototype.animate = function (beginCallback, completeCallback, delay, dr) {
            var _this = this;
            if (delay === void 0) { delay = 0; }
            if (dr === void 0) { dr = ModificationConfig_1.duration.node; }
            // const {top} = this.getElementStyle();
            // const distance = Math.abs(top - this.lastPosition.top);
            // if(distance < 100){
            //     dr = dr * (distance) / 100;
            // }
            this.animation = anime_es_1.default({
                targets: this.element,
                scale: this.lastStyle.scale,
                left: this.lastPosition.left,
                top: this.lastPosition.top,
                duration: dr,
                delay: delay,
                easing: 'linear',
                begin: function () {
                    if (_this.lastStyle.display === 'block') {
                        _this.element.style.setProperty("display", "block");
                    }
                    beginCallback && beginCallback();
                },
                complete: function () {
                    if (_this.lastStyle.display === 'none') {
                        _this.element.style.setProperty("display", "none");
                    }
                    completeCallback && completeCallback();
                }
            });
        };
        return UserFrame;
    }());
    exports.UserFrame = UserFrame;
});
