<template>
  <div id="pageEditorTool" :style="{display}">
    <div class="editorTool pageHeader" :style="{display:headerDisplay,transform:'scaleY(0)',transformOrigin: 'top'}" ref="header">
      <div class="container-tip"><p>编辑页眉</p></div>
      <div class="editorTool-entity-container header-container" id="header-container" ref="headerEditorContainer">
      </div>
    </div>
    <div class="vacancy" ref="vacancy" @click="$emit('closeToolHandler')"></div>
    <div class="editorTool pageFooter" :style="{display:footerDisplay,transform: 'scaleY(0)',transformOrigin:'bottom'}" ref="footer">
      <div class="container-tip"><p>编辑页脚</p></div>
      <div class="editorTool-entity-container footer-container" id="footer-container" ref="footerEditorContainer">
      </div>
    </div>
  </div>
</template>

<script>
import anime from "../../assets/js/anime.es";
export default {
  name: "PageHeaderFooter",
  props:['data'],
  data:function(){
    return {
      display:'none',
      headerDisplay:'block',
      footerDisplay:'block',
      currentTool:null,
      enumValue:{
        HEADER:'header',
        FOOTER:'footer'
      }
    }
  },
  methods:{
    closeTool(callback){
      anime({
        targets:[this.$refs.header,this.$refs.footer,this.$refs.vacancy],
        scaleY:function(el,i){
          switch (i){
            case 0:
              return '0';
            case 1:
              return '0';
            case 2:
              return '1'
          }
        },
        opacity: 0,
        complete: () => {
          this.display = 'none';
          this.currentTool = null;
          callback && callback();
        },
        easing:'easeOutCubic',
        duration:500
      })
    },
    openHeaderTool(callback,compitable = false){
      if(this.currentTool === this.enumValue.HEADER){
        return
      }
      this.currentTool = this.enumValue.HEADER;

      this.display = 'block';
      anime({
        targets:[this.$refs.header,this.$refs.vacancy,this.$refs.footer],
        scaleY:function(el,i){
          switch (i){
            case 0:
              return ['0','1'];
            case 1:
              return 1;
            case 2:
              return compitable ? null : '0'
          }
        },
        opacity:1,
        easing:'easeOutCubic',
        complete: ()=>{
          callback && callback();
        },
        duration:500
      })
    },
    openFooterTool(callback,compitable = false){
      if(this.currentTool === this.enumValue.FOOTER){
        return
      }
      this.currentTool = this.enumValue.FOOTER;

      this.display = 'block';
      anime({
        targets:[this.$refs.footer,this.$refs.vacancy,this.$refs.header],
        scaleY:function(el,i){
          switch (i){
            case 0:
              return ['0','1'];
            case 1:
              return 1;
            case 2:
              return compitable ? null : '0'
          }
        },
        opacity:1,
        easing:'easeOutCubic',
        complete: ()=>{
          callback && callback();
        },
        duration:500
      })
    },
    // insertHeaderEditor(dom){
    //   this.$refs.headerEditorContainer.innerHTML = '';
    //   this.$refs.headerEditorContainer.appendChild(dom);
    // },
    // insertFooterEditor(dom){
    //   this.$refs.footerEditorContainer.innerHTML = '';
    //   this.$refs.footerEditorContainer.appendChild(dom);
    // }
  }
}
</script>

<style scoped>
#pageEditorTool{
  width: 100%;
  height:100%;
  position: relative;
}
.editorTool{
  height: 20%;
  min-height: 100px;
  width: 100%;
  position: absolute;
  z-index: 3;
  background: #fff;
}
.editorTool-entity-container{
  box-sizing: border-box;
  width: 100%;
  height: 70%;
  /*margin-top: -15px;*/
  overflow-y: scroll;
  overflow-x: hidden;
  /*overflow: hidden;*/
}

.editorTool-entity-container::-webkit-scrollbar {
  display: block;
}
.editorTool-entity-container::-webkit-scrollbar-track-piece {
  background-color: #f8f8f8;
}

.editorTool-entity-container::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.editorTool-entity-container::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
}

.editorTool-entity-container::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
.container-tip{
  height: 30%;
  margin: 0;
  box-sizing: border-box;
  position: relative;
}
.container-tip>p{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.pageHeader{
  top:0;
  left: 0;
}
.vacancy{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgba(0,0,0,.4);
}
.pageFooter{
  bottom: 0;
  left: 0;
}
</style>