define(["require", "exports", "./ModificationType", "../../util", "../../assets/js/anime.es", "./ModificationConfig", "./CooperationBlockPopperManager", "./style/group.css", "./style/avatar.css"], function (require, exports, ModificationType_1, util_1, anime_es_1, ModificationConfig_1, CooperationBlockPopperManager_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var CooperationBlockAvatarGroup = /** @class */ (function () {
        function CooperationBlockAvatarGroup(referenceNode, manager) {
            this.classNames = {
                container: 'block-avatar-group',
                ellipsis: 'block-avatar-group-ellipsis'
            };
            this.groupCoUsers = [];
            this.referenceNode = referenceNode;
            this.manager = manager;
            this.parentNode = manager.element;
            this.managerEmitter = manager.emitter;
            this.emitter = new util_1.BriefEmitter();
            this.domEventListenerManager = new util_1.DomEventListenerManager();
            this.lastPosition = {
                top: 0
            };
            this.integerAnimation = null;
            this.ellipsisAvatarNode = this.makeEllipsisAvatarNode();
            this.ellipsisAvatarStyle = {
                scale: 0,
                display: 'none'
            };
            this.ellipsisAvatarNodeAnimation = null;
            this.element = this.make();
            this.mount();
            this.bindEmitterEvents();
            this.ellipsisPopper = new CooperationBlockPopperManager_1.CooperationBlockPopperManager(this, []);
            this.bindPopper(this.ellipsisAvatarNode);
        }
        CooperationBlockAvatarGroup.prototype.make = function () {
            var dom = document.createElement('div');
            //style.blockAvatarGroup
            dom.classList.add(this.classNames.container);
            dom.append(this.ellipsisAvatarNode);
            return dom;
        };
        CooperationBlockAvatarGroup.prototype.bindEmitterEvents = function () {
            var _this = this;
            this.emitter.on(ModificationType_1.GROUP_EVENTS.UPDATE_CO_USERS, function () {
                _this.updateItemsOfContainer();
            });
        };
        CooperationBlockAvatarGroup.prototype.tryRemoveCoGroup = function (excludeCoUser) {
            var _this = this;
            var noOtherCoUsers = this.manager.allCoUsers.every(function (_coUser) {
                if (_coUser === excludeCoUser)
                    return true;
                return _coUser.destination != _this && _coUser.currentCoGroup != _this;
            });
            var isEmpty = this.groupCoUsers.length == 0;
            if (noOtherCoUsers && isEmpty) {
                this.manager.allCoGroups = this.manager.allCoGroups.filter(function (_coGroup) { return !_coGroup.isSameReference(_this.referenceNode); });
                this.destroy();
                return true;
            }
            return false;
        };
        CooperationBlockAvatarGroup.prototype.makeEllipsisAvatarNode = function () {
            var dom = document.createElement('button');
            //style.blockAvatarGroupEllipsis
            dom.classList.add(this.classNames.ellipsis);
            return dom;
        };
        CooperationBlockAvatarGroup.prototype.bindPopper = function (ellipsisAvatarNode) {
            var _this = this;
            this.domEventListenerManager.registerListener(ellipsisAvatarNode, 'click', function () {
                _this.ellipsisPopper.toggleShow();
            });
            this.domEventListenerManager.registerListener(window, 'click', function (e) {
                var originNode = e.target;
                if (originNode.closest('.' + _this.ellipsisPopper.classNames.container)
                    || originNode.closest('.' + _this.classNames.ellipsis)) {
                    return;
                }
                _this.ellipsisPopper.close();
            }, { capture: true });
        };
        CooperationBlockAvatarGroup.prototype.insertEllipsisAvatarNode = function () {
            this.element.append(this.ellipsisAvatarNode);
        };
        CooperationBlockAvatarGroup.prototype.play = function () {
            this.integerAnimation && this.integerAnimation.pause();
            this.managerEmitter.emit(ModificationType_1.GROUP_EVENTS.UPDATE_POSITION, this, this.lastPosition);
            this.integerAnimation = anime_es_1.default({
                targets: this.element,
                top: this.lastPosition.top,
                duration: ModificationConfig_1.duration.group
            });
        };
        CooperationBlockAvatarGroup.prototype.mount = function () {
            this.parentNode.append(this.element);
            this.updateLastPosition();
            this.element.style.setProperty("top", this.lastPosition.top + 'px');
        };
        CooperationBlockAvatarGroup.prototype.updateLastPosition = function () {
            if (!this.referenceNode.isConnected)
                return; //参考block脱离了文档,不更新group目的地位置
            var parentTop = this.parentNode.getBoundingClientRect().top;
            var _a = this.referenceNode.getBoundingClientRect(), referTop = _a.top, referHeight = _a.height;
            this.lastPosition.top = referTop - parentTop + (referHeight / 2) - (ModificationConfig_1.width.avatar / 2);
        };
        CooperationBlockAvatarGroup.prototype.updateEllipsisAvatarNodeStyle = function () {
            var avatarLength = this.groupCoUsers.length;
            if (avatarLength > ModificationConfig_1.count.avatarMaxCount) {
                this.ellipsisAvatarStyle.scale = 1;
                this.ellipsisAvatarStyle.display = 'block';
            }
            else {
                this.ellipsisAvatarStyle.scale = 0;
                this.ellipsisAvatarStyle.display = 'none';
            }
        };
        CooperationBlockAvatarGroup.prototype.getVisibleCount = function () {
            var elementChildCount = this.element.childElementCount;
            var avatarCount = elementChildCount - 1;
            var visibleCount = Math.min(avatarCount, ModificationConfig_1.count.avatarMaxCount);
            return visibleCount;
        };
        CooperationBlockAvatarGroup.prototype.getCurrentOffsetLeft = function () {
            var visibleCount = this.getVisibleCount();
            var offsetLeft = 0;
            if (visibleCount * ModificationConfig_1.width.avatar > ModificationConfig_1.width.avatarContainer) {
                offsetLeft = (visibleCount * ModificationConfig_1.width.avatar - ModificationConfig_1.width.avatarContainer) / (visibleCount - 1);
            }
            return offsetLeft;
        };
        CooperationBlockAvatarGroup.prototype.getNextAvatarPosition = function () {
            var offsetLeft = this.getCurrentOffsetLeft();
            var visibleCount = this.getVisibleCount();
            var position = {
                top: 0,
                left: visibleCount < ModificationConfig_1.count.avatarMaxCount ? visibleCount * (ModificationConfig_1.width.avatar - offsetLeft) : (ModificationConfig_1.width.avatarContainer - ModificationConfig_1.width.avatar)
            };
            return position;
        };
        CooperationBlockAvatarGroup.prototype.animateEllipsisAvatarNode = function () {
            var _this = this;
            this.ellipsisAvatarNodeAnimation && this.ellipsisAvatarNodeAnimation.pause();
            // @ts-ignore
            this.ellipsisAvatarNodeAnimation = anime_es_1.default({
                targets: this.ellipsisAvatarNode,
                scale: this.ellipsisAvatarStyle.scale,
                duration: ModificationConfig_1.duration.node,
                begin: function () {
                    //因为时异步,这时可能group已经被删除
                    if (_this.ellipsisAvatarStyle && _this.ellipsisAvatarStyle.display === 'block') {
                        _this.ellipsisAvatarNode.style.setProperty("display", "block");
                    }
                },
                complete: function () {
                    //因为时异步,这时可能group已经被删除
                    if (_this.ellipsisAvatarStyle && _this.ellipsisAvatarStyle.display === 'none') {
                        _this.ellipsisAvatarNode.style.setProperty("display", "none");
                    }
                }
            });
        };
        //调整内部的元素位置和样式并进行动画
        CooperationBlockAvatarGroup.prototype.updateItemsOfContainer = function () {
            var sliceIndex = this.getVisibleCount();
            if (this.groupCoUsers.length > ModificationConfig_1.count.avatarMaxCount) {
                sliceIndex -= 1;
            }
            var offsetLeft = this.getCurrentOffsetLeft();
            this.groupCoUsers.slice(0, sliceIndex).forEach(function (coUser, index) {
                coUser.isCovered = false;
                if (index === 0) {
                    coUser.setLastStyle(0, 0, 1, 'block');
                }
                else {
                    coUser.setLastStyle(0, index * (ModificationConfig_1.width.avatar - offsetLeft), 1, 'block');
                }
            });
            this.groupCoUsers.slice(sliceIndex).forEach(function (coUser) {
                coUser.setLastStyle(0, ModificationConfig_1.width.avatarContainer - ModificationConfig_1.width.avatar, 0, 'none');
                coUser.isCovered = true;
            });
            this.updateEllipsisAvatarNodeStyle();
            this.animateEllipsisAvatarNode();
            this.groupCoUsers.forEach(function (coUser) {
                coUser.status === ModificationType_1.CO_USER_STATUS.Grouped && coUser.play();
            });
        };
        CooperationBlockAvatarGroup.prototype.addCoUser = function (coUser) {
            var existedCoUser = this.groupCoUsers.find(function (_coUser) { return _coUser.uid === coUser.uid; });
            if (existedCoUser) {
                Object.assign(existedCoUser, coUser);
            }
            else {
                this.groupCoUsers.push(coUser);
            }
            this.element.append(coUser.element);
            this.ellipsisPopper.addPopperUser(coUser);
        };
        CooperationBlockAvatarGroup.prototype.removeCoUserByUid = function (uid) {
            var targetCoUser = this.groupCoUsers.find(function (coUser) { return coUser.uid === uid; });
            this.groupCoUsers = this.groupCoUsers.filter(function (coUser) { return coUser.uid !== uid; });
            if (targetCoUser) {
                this.element.removeChild(targetCoUser.element);
            }
            this.updateItemsOfContainer();
            this.ellipsisPopper.removePopperUse(uid);
            this.tryRemoveCoGroup();
            return targetCoUser;
        };
        CooperationBlockAvatarGroup.prototype.isSameReference = function (otherReferenceNode) {
            return this.referenceNode === otherReferenceNode;
        };
        CooperationBlockAvatarGroup.prototype.isIncludingUser = function (otherUserInfo) {
            var found = this.groupCoUsers.find(function (coUser) {
                return coUser.uid == otherUserInfo.uid;
            });
            return found ? true : false;
        };
        CooperationBlockAvatarGroup.prototype.destroy = function () {
            this.element.remove();
            this.domEventListenerManager.removeListener();
            for (var field in this) {
                if (Object.prototype.hasOwnProperty.call(this, field)) {
                    delete this[field];
                }
            }
            Object.setPrototypeOf(this, null);
        };
        return CooperationBlockAvatarGroup;
    }());
    exports.CooperationBlockAvatarGroup = CooperationBlockAvatarGroup;
});
