/**
 * 判断段落身份标识是否可以融合
 * @param modifications
 * @param newModification
 * @returns {{isOnly: boolean, canMergePreviousModificationInfo: null, canMergeNextModificationInfo: null, usedModificationInfo: {modificationIndex: number, isSameUid: boolean, referenceNodeIndex: number, modification: null}}}
 */
import {colorLog, ColorInfo, getHashCode, hexToRgb, BriefEmitter} from "../../util";

export const modificationPrefix = {
    block:'user-block-',
    insert:'user-insert-',
    delete:'user-delete-'
}
/**
 * @param random
 * @param base
 * @returns {{rgba: string, r: number, b: number, g: number}|{rgba: string, r: number, b: number, g: number}|{rgba: string}}
 */

export const generateColor = (random,base) => {
    if(random){
        const randomGenerator = () => Math.floor(Math.random() * 256);
        let r,b,g;
        r = randomGenerator();
        b = randomGenerator();
        g = randomGenerator();
        return  new ColorInfo(`rgb(${r},${g},${b})`,r,g,b);
    }

    let code = getHashCode(base);
    let hex = '#'+code.toString(16);
    while(hex.length < 9){
        hex += 'f';
    }
    while(hex.length > 9){
        hex.slice(0,-1);
    }
    return hexToRgb(hex);
}

export function contrastColor(c1,c2) {
    const contrastSum = Math.abs(c1.r - c2.r) + Math.abs(c1.g - c2.g) + Math.abs(c1.b - c2.b);
    return contrastSum;
}

export function getUniqueColor(base,existedColors,contrastThreshold = 5){
    let newColorInfo = generateColor(false,base);

    function contrast(){
        return existedColors.some(color => {
            let contrastSum = contrastColor(newColorInfo,hexToRgb(color));
            if(contrastSum < contrastThreshold){
                return true;
            }
        });
    }

    while(contrast(newColorInfo.rgba)){
        newColorInfo = generateColor(true);
    }

    return newColorInfo;
}




