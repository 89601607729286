import EditorJS from '../editorjs/editor'
import {CommandType, EventsType, isUserSource, Source} from './websocket-message-type'
import EventEmitter from 'eventemitter3';
import {HdrFtrManager} from "./hdrFtrManager";
import {cloneDeep} from "lodash";
import Delta from "../delta/Delta";
import {toRaw} from 'vue'
export default class EditorManager {
    constructor(numberingConfig,commonConfig,mainEditorConfig,...otherConfigs) {
        this.editorCluster = new Map();
        this.allPromise = [];
        this.resolveOnReady(numberingConfig,commonConfig,mainEditorConfig,...otherConfigs);
        this.emitter = new EventEmitter();
        this.mainHolder = mainEditorConfig.holder;
    }

    on(...args){
        this.emitter.on(...args);
    }

    off(...args){
        this.emitter.off(...args);
    }

    /**
     * 打开额外的editor
     * @param type
     * @param uniqueId
     */
    produceEditor(type,uniqueId){
        const manager = this.editorCluster.get(type);
        if(manager){
            this.editorCluster.get(type)?.openEditor(uniqueId);
        }else{
            throw new Error(`没有 类型${CommandType[type]} 配置项`);
        }
    }

    /**
     * editor的销毁
     * @param type editor类型 没有传全部destroy
     */
    destroy(type){
        if(type){
            this.editorCluster.get(type)?.destroy();
        }else{
            this.editorCluster.forEach((_editor)=>{
                _editor.destroy && _editor.destroy();
            })

            this.editorCluster = new Map();
            this.emitter = null;
        }
    }

    isMainEditor(editorType){
        return CommandType.DELTA === editorType;
    }

    getMainEditor(){
        return this.editorCluster.get(CommandType.DELTA)
    }

    /**
     * 绑定事件
     */
    bindEvent(){
        this.editorCluster.forEach((entity,editorType) => {
            entity.on(EventsType.TEXT_CHANGE,(args)=>{
                args.type = editorType;
                this.emitter.emit(EventsType.TEXT_CHANGE,args);
            })
            entity.on(EventsType.LIST_ITEM_REFRESHED,()=>{
                this.emitter.emit(EventsType.LIST_ITEM_REFRESHED,null);
            })
            entity.on(EventsType.COMPOSITION_CHANGE,(args)=>{
                this.emitter.emit(EventsType.COMPOSITION_CHANGE,args.state);
            })
            entity.on(EventsType.EDITOR_ERROR,(e)=>{
                console.error(e);
                this.emitter.emit(EventsType.EDITOR_ERROR);
            })
            entity.on(EventsType.ADD_EDIT_USER,(args)=>{
                this.emitter.emit(EventsType.ADD_EDIT_USER,args);
            })

            if(this.isMainEditor(editorType)){
                entity.on(EventsType.OPEN_PAGE_HEADER,(args)=>{
                    this.emitter.emit(EventsType.OPEN_PAGE_HEADER,args);
                })
                entity.on(EventsType.OPEN_PAGE_FOOTER,(args)=>{
                    this.emitter.emit(EventsType.OPEN_PAGE_FOOTER,args);
                })
                entity.on(EventsType.REQUEST_CREATE_COMMENTS,(args)=>{
                    this.emitter.emit(EventsType.REQUEST_CREATE_COMMENTS,args);
                })
                entity.on(EventsType.START_CHECK,(args) =>{
                    this.emitter.emit(EventsType.START_CHECK,args);
                })
                entity.on(EventsType.BLOCK_ON_FOCUS, (args) => {
                    this.emitter.emit(EventsType.BLOCK_ON_FOCUS, args);
                })
            }
        })
    }

    /**
     * 初始化editor及额外editor的管理器
     * @param number 序标config
     * @param common 公共config
     * @param mainEditorConfig 主editor config
     * @param otherConfig 其他config
     */
    resolveOnReady(number,common,mainEditorConfig,...otherConfig){
        /**
         * numbering 初始化
         */
        const numbering = new number.class;
        const numberConfig = {
            type:"instance",
            instance:numbering
        };
        this.editorCluster.set(number.type,numbering);
        const commonBak = cloneDeep(common);
        if(mainEditorConfig.tools){
            mainEditorConfig.tools = Object.assign(mainEditorConfig.tools,commonBak?.tools);
        }
        if(mainEditorConfig.modules){
            mainEditorConfig.modules = Object.assign(mainEditorConfig.modules,commonBak?.modules);
        }
        /**
         * 主editor初始化
         */
        mainEditorConfig = Object.assign({},cloneDeep(common),mainEditorConfig);
        mainEditorConfig.modules["Numbering"] = numberConfig
        let mainEditor =  new EditorJS(mainEditorConfig);

        this.editorCluster.set(mainEditorConfig.type,mainEditor);

        this.allPromise.push(new Promise((resolve)=>{
            mainEditor.isReady.then( () => {
                mainEditor.on(EventsType.EDITOR_SELECTED,() => {
                    this.emitter.emit(EventsType.EDITOR_SELECTED,mainEditor)
                })
                resolve();
            })
        }));

        /**
         * 其他editor初始化
         */
        for(let config of otherConfig){
            config = Object.assign(config,cloneDeep(common))
            const type = config.type;
            if(type === CommandType.HEADER_DELTA || type === CommandType.FOOTER_DELTA){
                let manager = new HdrFtrManager(config,type,numberConfig);
                manager.on(EventsType.EDITOR_SELECTED,(editor) => {
                    this.emitter.emit(EventsType.EDITOR_SELECTED,editor)
                })
                this.editorCluster.set(type,manager);
            }
        }
    }

    /**
     * 初次渲染delta数据，基于结果的数据
     * @deprecated 请统一调用applyDelta
     * @param data
     * @param source
     * @return {Promise<unknown[]>}
     */
    render(data,source){
        let all = [];
        data.forEach(op => {
            all.push(this.getMainEditor().content?.render(op,source));
        })
        return Promise.all(all);
    }

    /**
     * 初始化视图中的数据
     * @param data
     * @return number 最新的版本号
     */
    initViewData(data){
        // this.editor.content.renderBlocks(data.blocks, 'api').then(res => {
        //     const delay = (data.delay || []);
        //     delay.forEach(message => {
        //         message.commands.forEach(delta => {
        //             this.editor.content.applyDelta(delta['delta'], 'silent');
        //         });
        //     });
        //     if (delay.length > 0) {
        //         this.version = delay[delay.length - 1].version;
        //     }
        //     this.ACKStatus = false;
        // });
        const numbering = this.editorCluster.get(CommandType.NUMBERING);
        numbering.init({
            numbering:data.numbering,
            meta:data.meta,
            section:data.section
        })
        numbering.initMeta(this.mainHolder);
        if(data['documentDelta']){
            this.applyMainEditorDelta(data['documentDelta'],Source.SILENT)
        }
        const delay = (data.delay || []);
        delay.forEach(message => {
            message.commands.forEach(op => {
                this.applyDelta(op, Source.SILENT);
            });
        });
        console.log('before refreshList Item')
        this.getMainEditor().blocks.refreshListItems()
        return (delay.length > 0? delay[delay.length - 1].version : data.ev) || 0;
    }

    /**
     * 应用delta
     * @param op 操作历史 基于过程的数据
     * @param source
     */
    applyDelta(op,source){
        let {type,delta,id,numbering} = op;
        if(type === CommandType.NUMBERING){
            this.editorCluster.get(type).applyDelta(numbering,source);
        }else if(this.isMainEditor(type)){
            this.applyMainEditorDelta(delta,source);
        }else if(type === CommandType.FOOTER_DELTA || type === CommandType.HEADER_DELTA){
            this.editorCluster.get(type).applyDelta(id,delta,source);
        }
    }

    applyMainEditorDelta(delta,source){
        this.getMainEditor().content.applyDelta(delta,source);
    }

    getMainEditorBlocksLen(){
        return this.getMainEditor().blocks.getBlocksCount();
    }

    getMainEditorBlockHolderAndTextLen(blockIndex){
        return this.getMainEditor().blocks.getBlockHolderAndTextLen(blockIndex);
    }

    onReady(cb){
        Promise.all(this.allPromise).then( ()=>{
            this.bindEvent();
            cb(this.editorCluster);
        });
    }
}
