
// 标记的插入线位于range的左侧还是右侧。

export const MarkLineRangeType = {
    BEFORE: 0, //线在range的前面
    AFTER: 1, //线在range的后面
    START: 2,//线在range的起始位置。
}

/**
 * 获取needMarkIndexes每一个index对应的插入线的range和type
 * @param editorElement 用于相对定位的editor元素
 * @param blocks editorjs的所有block节点
 * @param needMarkIndexes 需要获取对应的插入线位置的index集合
 * @return {Map} 以index为key，对应的插入线的rect（top,left,width,height）为value的map
 */
export function getIndexCaretRectMap(editorElement, blocks, needMarkIndexes) {

    let copyMarkIndexes = Array.from(new Set(needMarkIndexes)).sort((a,b)=>a-b);
    let traverseInfo = {index: 0, lastNode: null};
    let indexRangeInfoMap = new Map();
    for (let i = 0; i < blocks.length; i++) {
        traverseInfo.lastNode = null;
        traverseNode(blocks[i], traverseInfo, copyMarkIndexes, indexRangeInfoMap);
        // 如果这个字符刚好在这一个block的\n上，那么
        if (copyMarkIndexes[0] === traverseInfo.index) {
            if (traverseInfo.lastNode === null) {
                // 说明是一个空的block
                let range = new Range();
                range.selectNode(blocks[i])
                indexRangeInfoMap.set(copyMarkIndexes[0], {range, type: MarkLineRangeType.START})
            } else {
                // 说明该block有文本
                let range = new Range();
                if (traverseInfo.lastNode.nodeType === Node.TEXT_NODE) {
                    range.setStart(traverseInfo.lastNode,traverseInfo.lastNode.textContent.length-1)
                    range.setEndAfter(traverseInfo.lastNode)
                }else{
                    range.selectNode(traverseInfo.lastNode)
                }
                indexRangeInfoMap.set(copyMarkIndexes[0], {range, type: MarkLineRangeType.AFTER})
            }
            copyMarkIndexes.splice(0, 1);
        }
        // \n占一个字符
        traverseInfo.index++;
    }
    return indexAndRangeInfoMapToIndexRectMap(editorElement, indexRangeInfoMap);
}

function indexAndRangeInfoMapToIndexRectMap(editorElement, indexRangeInfoMap) {
    let res = new Map();
    const lineWidth = 1;
    const startTypeOffset = 2;
    let elementRect = editorElement.getBoundingClientRect()
    for (let [index, rangeInfo] of indexRangeInfoMap) {
        let rect = rangeInfo.range.getBoundingClientRect();
        if (rangeInfo.type === MarkLineRangeType.BEFORE) {
            res.set(index, {
                top: rect.top - elementRect.top,
                left: rect.left - lineWidth - elementRect.left,
                width: lineWidth,
                height: rect.height
            })
        } else if (rangeInfo.type === MarkLineRangeType.AFTER) {
            res.set(index, {
                top: rect.top - elementRect.top,
                left: rect.left + rect.width - elementRect.left,
                width: lineWidth,
                height: rect.height
            })
        } else if (rangeInfo.type === MarkLineRangeType.START) {
            // start这种划线稍稍往里面挪一点，免得太偏左了。
            res.set(index, {
                top: rect.top - elementRect.top,
                left: rect.left + startTypeOffset - elementRect.left,
                width: lineWidth,
                height: rect.height
            })
        } else {
            throw new Error("未处理的MarkLineRangeType")
        }
    }
    return res;
}

/***
 * 遍历node,并找到needMarkIndexes中index对应的range和type
 * @param node
 * @param {{index,lastNode}} traverseInfo
 * @param {Array} needMarkIndexes
 * @param {Map} indexRangeInfoMap
 */
export function traverseNode(node, traverseInfo, needMarkIndexes, indexRangeInfoMap) {
    if (!node) {
        return
    }
    if (node.nodeType === Node.TEXT_NODE) {
        let newIndex = traverseInfo.index + node.textContent.length;
        while (needMarkIndexes[0] >= traverseInfo.index && needMarkIndexes[0] < newIndex) {
            let range = new Range();
            range.setStart(node, needMarkIndexes[0] - traverseInfo.index);
            range.setEnd(node, needMarkIndexes[0] - traverseInfo.index + 1);
            indexRangeInfoMap.set(needMarkIndexes[0], {range, type: MarkLineRangeType.BEFORE})
            needMarkIndexes.splice(0, 1);
        }
        traverseInfo.index = newIndex;
        traverseInfo.lastNode = node;
    } else if (node.tagName === 'IMG') {
        if (needMarkIndexes[0] === traverseInfo.index) {
            let range = new Range();
            range.setStartBefore(node)
            range.setEnd(node, 1)
            indexRangeInfoMap.set(needMarkIndexes[0], {range, type: MarkLineRangeType.BEFORE})
            needMarkIndexes.splice(0, 1);
        }
        traverseInfo.index = traverseInfo.index + 1;
        traverseInfo.lastNode = node;
    } else {
        if (ignoreNode(node)) {
            if (isListNode(node)) {
                traverseInfo.lastNode = node;
            }
            return
        }
        // 遍历节点的子节点
        for (let i = 0; i < node.childNodes.length; i++) {
            traverseNode(node.childNodes[i], traverseInfo, needMarkIndexes, indexRangeInfoMap);
        }
    }
}

function ignoreNode(node) {
    return node.className && ((node.className.indexOf('cdx-list-serialNumber') !== -1 && node.contentEditable === 'false')
        || (node.className.indexOf('pd-entity') !== -1) || (node.className.indexOf('row-container') !== -1) || node.className.indexOf('extension') !== -1
        || node.className.indexOf('keywordtip') !== -1)
}

function isListNode(node) {
    return node.className && (node.className.indexOf('cdx-list-serialNumber') !== -1);
}

/**
 * 获取start、end在editorjs里面对应的矩形区域
 * @param editorElement
 * @param {[{block,childBlocks}]} blocks
 * @param ignoreSpecialNode 是否忽略特殊节点
 * @param start
 * @param end
 * @return {*[]}
 */
export function getEditorJsDivCaretCoordinates(editorElement, blocks,ignoreSpecialNode, start,end) {
    let range = getEditorJsRange(editorElement, blocks, ignoreSpecialNode,start, end);
    let rangeRects = range.getClientRects()
    let elementRect = editorElement.getBoundingClientRect()
    let result = []
    for (let i = 0; i < rangeRects.length; i++) {
        let rangeRect = rangeRects[i]
        result.push({
            top: rangeRect.top - elementRect.top,
            left: rangeRect.left - elementRect.left,
            height: rangeRect.height,
            width: rangeRect.width
        })
    }
    return result
}
export function getEditorJsRange(editorElement, blocks, ignoreSpecialNode,start,end){
    let oldStart = start,oldEnd = end;
    let traverseInfo = {index: 0, lastNode: null,rangeSetted:false};
    let range = new Range();
    for (let i = 0; i < blocks.length; i++) {
        let dealBlocks = [blocks[i].block];
        if (blocks[i].childBlocks) {
            // 如果有子block，比如table，则先算一个\n的位置
            let dealInfo = dealIfStartOrEndInNewline(start, end, traverseInfo, range);
            start = dealInfo.start;
            if (dealInfo.rangeSetted) {
                return range;
            }
            traverseInfo.index++;
            dealBlocks = blocks[i].childBlocks
        }
        for (let j = 0; j < dealBlocks.length; j++) {
            let block = dealBlocks[j]
            traverseNodeToSetRange(block, traverseInfo, ignoreSpecialNode,start,end, range);
            let dealInfo = dealIfStartOrEndInNewline(start, end, traverseInfo, range);
            start = dealInfo.start;
            if (dealInfo.rangeSetted) {
                return range;
            }
            // \n占一个字符
            traverseInfo.index++;
        }
    }
    console.error(`无法获取到对应range,start:${oldStart},end:${oldEnd}`);
    return new Range();
}
function dealIfStartOrEndInNewline(start,end,traverseInfo,range){
    if (start === traverseInfo.index) {
        // 如果start在\n上，则推到下一个节点
        start = start + 1;
    }
    let rangeSetted = traverseInfo.rangeSetted;
    // 如果end在\n上，则推到上一个节点
    if (end === traverseInfo.index) {
        range.setEndAfter(traverseInfo.lastNode);
        rangeSetted = true;
    }
    return {start,rangeSetted};
}


function traverseNodeToSetRange(node, traverseInfo, ignoreSpecialNode,start, end, range) {
    if (!node) {
        return false;
    }
    if (node.nodeType === Node.TEXT_NODE) {
        let newIndex = traverseInfo.index + node.textContent.length;
        if(start >= traverseInfo.index && start < newIndex){
            range.setStart(node, start - traverseInfo.index);
        }
        if(end >= traverseInfo.index && end < newIndex){
            range.setEnd(node, end - traverseInfo.index);
            traverseInfo.rangeSetted = true;
            return;
        }
        traverseInfo.index = newIndex;
        traverseInfo.lastNode = node;
    } else if (node.tagName === 'IMG') {
        if (start === traverseInfo.index) {
            range.setStartBefore(node)
        }
        if (end === traverseInfo.index) {
            range.setEndAfter(node)
            traverseInfo.rangeSetted = true;
            return;
        }
        traverseInfo.index = traverseInfo.index + 1;
        traverseInfo.lastNode = node;
    } else {
        if (ignoreSpecialNode && ignoreNode(node)) {
            if (isListNode(node)) {
                traverseInfo.lastNode = node;
            }
            return;
        }
        // 遍历节点的子节点
        for (let i = 0; i < node.childNodes.length; i++) {
            traverseNodeToSetRange(node.childNodes[i], traverseInfo,ignoreSpecialNode, start, end, range);
            if(traverseInfo.rangeSetted){
                return;
            }
        }
    }
}

export function getEditorJsText(editorId, ignoreSpecialNode) {
    const blocks = document.querySelectorAll(`#${editorId} .codex-editor__redactor > .ce-block`)
    let traverseInfo = {text: ''};
    for (let i = 0; i < blocks.length; i++) {
        let childBlocks = blocks[i].querySelectorAll(".ce-block");
        if (childBlocks.length > 0) {
            // 有子block的，比如table，先加一个\n
            traverseInfo.text += '\n';
            for (let j = 0; j < childBlocks.length; j++) {
                traverseNodeToGetText(childBlocks[j], ignoreSpecialNode, traverseInfo);
                traverseInfo.text += '\n';
            }
        }else{
            traverseNodeToGetText(blocks[i], ignoreSpecialNode, traverseInfo);
            traverseInfo.text += '\n';
        }
    }
    return traverseInfo.text;
}

function traverseNodeToGetText(node, ignoreSpecialNode, traverseInfo) {
    if (!node) {
        return false;
    }
    if (node.nodeType === Node.TEXT_NODE) {
        traverseInfo.text += node.textContent;
    } else if (node.tagName === 'IMG') {
        traverseInfo.text += ' ';
    } else {
        if (ignoreSpecialNode && ignoreNode(node)) {
            return;
        }
        // 遍历节点的子节点
        for (let i = 0; i < node.childNodes.length; i++) {
            traverseNodeToGetText(node.childNodes[i], ignoreSpecialNode, traverseInfo);
        }
    }
}

