var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
define(["require", "exports", "./ModificationConfig", "./ModificationType", "./base/UserFrame"], function (require, exports, ModificationConfig_1, ModificationType_1, UserFrame_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var CooperationUser = /** @class */ (function (_super) {
        __extends(CooperationUser, _super);
        function CooperationUser(info, manager) {
            var _this = _super.call(this, info) || this;
            _this.manager = manager;
            _this.managerEmitter = manager.emitter;
            _this.currentCoGroup = null;
            _this.destination = null;
            _this.isCovered = false;
            return _this;
        }
        CooperationUser.prototype.isSameUser = function (otherUser) {
            if (otherUser instanceof CooperationUser)
                console.error("otherUser \u7C7B\u578B\u4E0D\u662F " + CooperationUser.constructor.name);
            return this.uid === otherUser.uid;
        };
        CooperationUser.prototype.updateLastStyleToDestination = function (groupLastPosition) {
            if (this.status === ModificationType_1.CO_USER_STATUS.Grouped || this.status === ModificationType_1.CO_USER_STATUS.Initial) {
                console.error('需要avatar在manager容器里');
                return;
            }
            var _a = this.element.getBoundingClientRect(), coUserTop = _a.top, coUserLeft = _a.left;
            if (this.destination) {
                var groupTop = void 0;
                var groupRect = this.destination.element.getBoundingClientRect();
                if (groupLastPosition) {
                    groupTop = groupLastPosition.top;
                }
                else {
                    groupTop = groupRect.top;
                }
                var groupBottom = groupTop + groupRect.height;
                var _b = this.destination.parentNode.getBoundingClientRect(), managerTop = _b.top, managerLeft = _b.left;
                this.setLastStyle(groupTop > coUserTop ? (groupTop - managerTop) - ModificationConfig_1.width.avatar : (groupBottom - managerTop), coUserLeft - managerLeft, 1, 'block');
            }
            else {
                var _c = this.manager.element.getBoundingClientRect(), managerTop = _c.top, managerLeft = _c.left;
                if (this.isCovered) {
                    this.setFirstStyle(undefined, undefined, .8, 'block');
                }
                this.setLastStyle(coUserTop - managerTop, coUserLeft - managerLeft, 0, 'none');
            }
        };
        CooperationUser.prototype.updateDestination = function (destination) {
            //自己还有destination但是现在更改了目的,所以到达不了destination group
            if (this.destination) {
                this.destination.tryRemoveCoGroup(this);
            }
            if (!destination || !destination.referenceNode) {
                //Drop操作
                this.unmountOnGroup(null, ModificationType_1.CO_USER_STATUS.Drop);
                this.updateLastStyleToDestination(); ////setLS
                return;
            }
            var previousCoGroup = this.currentCoGroup;
            this.isCovered = false;
            if (this.status === ModificationType_1.CO_USER_STATUS.Initial) {
                this.destination = destination;
                this.groupEmitter = destination.emitter;
            }
            else if (this.status === ModificationType_1.CO_USER_STATUS.Free) {
                this.destination = destination;
                this.groupEmitter = destination.emitter;
                this.updateLastStyleToDestination(); //setLS
            }
            else if (this.status === ModificationType_1.CO_USER_STATUS.Grouped && previousCoGroup && !previousCoGroup.isSameReference(destination.referenceNode)) {
                this.unmountOnGroup(destination, ModificationType_1.CO_USER_STATUS.Free); //setFS setStatus Free
                this.updateLastStyleToDestination(); //setLS
            }
            else if (this.status === ModificationType_1.CO_USER_STATUS.Drop) {
                this.destination = destination;
                this.groupEmitter = destination.emitter;
            }
        };
        CooperationUser.prototype.mountOnGroup = function () {
            this.currentCoGroup = this.destination;
            this.destination = null;
            var coGroup = this.currentCoGroup;
            if (!coGroup) {
                console.error('没有可挂载的group');
                return;
            }
            //在insert dom 之前获取下一个位置
            var nextLeft = coGroup.getNextAvatarPosition().left;
            //会insert dom
            coGroup.addCoUser(this);
            if (this.status === ModificationType_1.CO_USER_STATUS.Initial) {
                if (coGroup.groupCoUsers.length > ModificationConfig_1.count.avatarMaxCount) {
                    this.setFirstStyle(-1 * ModificationConfig_1.width.avatar - 10, nextLeft, .8, 'block');
                }
                else {
                    this.setFirstStyle(0, nextLeft, 0, 'block');
                }
            }
            else {
                //此刻avatar的dom在group容器,但fs还是相对manager容器的,需要更改
                if (this.status === ModificationType_1.CO_USER_STATUS.Free || this.status === ModificationType_1.CO_USER_STATUS.Drop) {
                    this.setFirstStyle(undefined, undefined, undefined, 'block');
                    var _a = coGroup.element.getBoundingClientRect(), groupTop = _a.top, groupLeft = _a.left;
                    var _b = this.element.getBoundingClientRect(), coUserTop = _b.top, coUserLeft = _b.left;
                    var _c = this.manager.element.getBoundingClientRect(), managerTop = _c.top, managerLeft = _c.left;
                    this.setFirstStyle(this.lastPosition.top + managerTop - groupTop, this.lastPosition.left + managerLeft - groupLeft, this.getElementStyle().scale == 0 ? 0.8 : undefined, 'block');
                }
                else {
                    console.error('status不满足要求');
                }
            }
            this.status = ModificationType_1.CO_USER_STATUS.Grouped;
            this.groupEmitter && this.groupEmitter.emit(ModificationType_1.GROUP_EVENTS.UPDATE_CO_USERS); //group setLS -> user play(Free分支)
        };
        CooperationUser.prototype.unmountOnGroup = function (newDestination, status) {
            this.status = status;
            var previousCoGroup = this.currentCoGroup;
            this.currentCoGroup = null;
            this.setFirstStyle(undefined, undefined, undefined, 'block');
            var _a = this.element.getBoundingClientRect(), coUserTop = _a.top, coUserLeft = _a.left;
            if (previousCoGroup) {
                //从原来的group剔除自己
                previousCoGroup.removeCoUserByUid(this.uid);
            }
            //踢到manager容器
            this.manager.element.append(this.element);
            var _b = this.manager.element.getBoundingClientRect(), managerTop = _b.top, managerLeft = _b.left;
            this.setFirstStyle(coUserTop - managerTop, coUserLeft - managerLeft, undefined, undefined);
            if (!newDestination) {
                this.destination = null;
                this.groupEmitter = null;
                return;
            }
            this.destination = newDestination;
            this.groupEmitter = newDestination.emitter;
        };
        CooperationUser.prototype.play = function () {
            var _this = this;
            var proRes = function () { };
            var pro = new Promise(function (res) { return proRes = res; });
            this.animation && this.animation.pause();
            if (this.status === ModificationType_1.CO_USER_STATUS.Initial) {
                this.mountOnGroup(); //setFS
                proRes();
            }
            else if (this.status === ModificationType_1.CO_USER_STATUS.Grouped) {
                this.animate(null, proRes);
            }
            else if (this.status === ModificationType_1.CO_USER_STATUS.Free) {
                this.animate(null, function () {
                    proRes();
                    _this.mountOnGroup(); //setFS set status grouped
                });
            }
            else if (this.status === ModificationType_1.CO_USER_STATUS.Drop) {
                if (this.destination) {
                    //如果目的地存在说明是从drop到重新挂载
                    this.mountOnGroup();
                }
                this.animate(null, function () {
                    proRes();
                });
            }
            return pro;
        };
        CooperationUser.prototype.destroy = function () {
            this.destination && this.destination.removeCoUserByUid(this.uid);
            this.element.remove();
            for (var field in this) {
                if (Object.prototype.hasOwnProperty.call(this, field)) {
                    delete this[field];
                }
            }
            Object.setPrototypeOf(this, null);
        };
        return CooperationUser;
    }(UserFrame_1.UserFrame));
    exports.CooperationUser = CooperationUser;
});
