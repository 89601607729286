<template>
    <div>
<!--        <pro-file-title :file-id="fileId" :meta-pro-file-name="fileName"></pro-file-title>-->

        <div id="editor-header" ref="editorHeader" @keydown="editorKeydown" >
            <div style="position: absolute;top:0;z-index:101;width:100%;line-height:34px;" v-show="showCustomHeaderTab">
                <div class="custom-header-btn-wrapper" v-if="contractCheckModeOpenForThisUser">
                    <span @click="switchCheckMode"  class="custom-header-btn" :class="{'active':showRightPanel}">
                        <svg xmlns="http://www.w3.org/2000/svg" style="width: 16px;height:16px;vertical-align: middle" class="svg-icon-path-icon fill" viewBox="0 0 1024 1024"
                             width="18" height="18"><path
                                d="M960 96v320h-64V320H128v544h288v64H64v-832h896z m-193.6 353.6a224 224 0 0 1 20.992 292.544l137.408 137.408-45.248 45.248-137.408-137.408a224 224 0 1 1 24.256-337.792z m-271.552 45.248a160 160 0 1 0 226.304 226.304 160 160 0 0 0-226.304-226.304zM896 160H128V256h768V160z"
                                fill="#2C374D" p-id="7374"></path></svg>
                        <span style="vertical-align: middle;margin-left:5px;">审查</span>
                        <template v-if="showRightPanel">
                            <el-dropdown  size="small"  popper-class="errorTypeMenu" @command="filterAlertByType" @click.stop >
                            <span class="el-dropdown-link">
                              <i class="el-icon--right el-icon-arrow-down"></i>
                            </span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item :command="ErrorTypeMenuItemKey.ALL">所有错误({{getAlertCountOfTheErrorType(ErrorTypeMenuItemKey.ALL)}})</el-dropdown-item>
                                    <el-dropdown-item :command="ErrorTypeMenuItemKey.TYPO">字词错误({{getAlertCountOfTheErrorType(ErrorTypeMenuItemKey.TYPO)}})</el-dropdown-item>
                                    <el-dropdown-item :command="ErrorTypeMenuItemKey.PUNCTUATION_MISUSE">标点错误({{getAlertCountOfTheErrorType(ErrorTypeMenuItemKey.PUNCTUATION_MISUSE)}})</el-dropdown-item>
                                    <el-dropdown-item :command="ErrorTypeMenuItemKey.CONTRACT_RISK">合同风险({{getAlertCountOfTheErrorType(ErrorTypeMenuItemKey.CONTRACT_RISK)}})</el-dropdown-item>
                                    <!--                                                        <el-dropdown-item :command="ErrorTypeMenuItemKey.CUSTOM_COMMENT">用户批注(0)</el-dropdown-item>-->
                                    <!--                                                        <el-dropdown-item :command="ErrorTypeMenuItemKey.STANDARD">规范检查({{ getAlertCountOfTheErrorType(ErrorTypeMenuItemKey.STANDARD)}})</el-dropdown-item>-->
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                        </template>
                    </span>
                </div>
                <div class="custom-header-btn-wrapper">
                  <span @click="handleToggleShowModificationVisualizations" class="custom-header-btn" :class="{active:showModificationVisualizations}">
                    <svg-icon name="revise" width="16" height="16" style="vertical-align: -.6ex"></svg-icon>
                    <span style="vertical-align: middle;margin-left:5px;">显示修订</span>
                  </span>
                </div>
            </div>
        </div>
        <div id="metaProFileContentDiv">
            <div id="metaProFileView" class="metaProFileView">
                <div id="metaProScroller" style="height: 100%;margin: 0;" class="whiteBG">
                    <div id="metaProFileViewDiv"
                            class="editorDisplayModeTransition">

                        <div id="editors-wrap" class="bzbb" ref="editorsWrap">
                          <div class="holder" style="padding-top: 20px;z-index:1;position:relative;background: var(--page-gray);"></div>
                            <div class="pageHeaderFooter-toolContainer " ref="pageToolContainer">
                                <page-header-footer
                                        ref="pageHeaderFooter"
                                        @closeToolHandler="closePageTool">
                                </page-header-footer>
                            </div>
                            <div id="mainEditor-wrap"  :style="{'margin-left':editorMarginLeft,'padding-right':'230px'}"  ref="mainEditorWrap">
                                <Comment id="commentsPanel" class="commentsPanel" ref="commentsPanel">
                                  <CommentsInvisibleTipGroup
                                      v-if="showComments.length != 0"
                                      :thresholds="{top:0,bottom:0}"
                                      :position="{
                                        top:46,
                                        bottom:0
                                      }"
                                      :tip-positions="{
                                        top:{
                                          top:10,
                                          left:100,
                                        },
                                        bottom:{
                                          bottom:10,
                                          left:100,
                                        }
                                      }"
                                  ></CommentsInvisibleTipGroup>
                                  <template #comments>
                                    <div class="commentDialog-container"
                                         v-for="annotation in showComments"
                                         :key="annotation.markId"
                                         :data-markId="annotation.markId"
                                    >
                                      <comment-dialog
                                              v-if="!annotation.hidden"
                                          :comments="annotation.comments"
                                          :markId="annotation.markId"
                                          :editorPosition="annotation.editorPosition"
                                          :readonly="commentReadonly"
                                          :canSetPermissionMode="canSetPermissionMode"
                                          v-model:permission-mode="sendCommentPermissionMode"
                                          @addCommentMessage="addCommentMessage"
                                          @deleteCommentMessage="delCommentMessage"
                                          @deleteAnnotation="deleteAnnotationAndAttribute(annotation.markId)"
                                          @handlePositionChangeEvent="animeAnnotationsPositionTransformation"
                                          @resolveAnnotation="resolveAnnotation(annotation.markId)"
                                          @sendCommentOperation="sendCommentOperation"
                                          :isFocus="focusAnnotationMarkId === annotation.markId"
                                          :isHover="hoverAnnotationMarkId === annotation.markId"
                                          @updateFocusAnnotationMarkId="(val)=>{
                                  focusAnnotationMarkId = val;
                                }"
                                          @updateHoverAnnotationMarkId="(val)=>{
                                  hoverAnnotationMarkId = val;
                                }"
                                          :ref="`annotations-${annotation.markId}`"
                                      ></comment-dialog>
                                    </div>
                                  </template>

                                </Comment>
                                <div id="metaProFileEditor" ref="metaProFileEditor" @keydown="editorKeydown">
                                    <checker-input-box ref="highlights" v-show="showRightPanel"  :position="editorPosition"/>
                                  <keep-alive>
                                    <UserModifyVisualization
                                        ref="userModify"
                                        v-if="showModificationVisualizations"
                                        :block-dom-container="'.codex-editor'"
                                        :user-permission="{readOnly}"
                                    />
                                  </keep-alive>
                                  <contract-block-tag v-if="openBlockTag" ref="blockTag" :contract="contract" :editor-id="editorId" :getHighlightRef="getHighlightRef"></contract-block-tag>
                                </div>
                            </div>

                            <div  id="rightPanelTopMask" :style="{left:rightPanelLeft,width:rightPanelContainerWidth + 'px',top:maskTop}">
                              <div style=" background-color: var(--page-gray);width:100%;height:100%"></div>
                            </div>
                            <div id="alertContainer" class="alertContainer" v-show="showRightPanel" :style="{left:rightPanelLeft,width:rightPanelContainerWidth + 'px'}" style="position: absolute;top: 0;padding:20px 10px;box-sizing:border-box">
                                <el-tabs v-model="activeTab" class="demo-tabs">
                                    <el-tab-pane  :name="TAB_NAME.CONTRACT_CHECK">
                                        <template #label>
                                            辅助审查
                                            <el-tooltip
                                                    v-if="newTextNeedCheck"
                                                    class="box-item"
                                                    effect="dark"
                                                    content="文档内容发生变化，点击刷新查看最新审查结果"
                                                    placement="top"
                                            >
                                                <span @click.stop="startCheck" style="margin:0 4px;" >
                                                    <svg style="color: rgb(49, 103, 255);vertical-align: middle;width:16px;height:16px;" xmlns="http://www.w3.org/2000/svg" class="svg-icon-path-icon fill" viewBox="0 0 1024 1024"><path d="M512 0c282.304 0 512 229.696 512 512s-229.696 512-512 512S0 794.304 0 512 229.696 0 512 0z m112 742.272V640a32 32 0 0 0-64 0v192h192a32 32 0 0 0 0-64h-47.904A320 320 0 0 0 832 512c0-163.424-123.072-300.096-284.224-318.016a32 32 0 0 0-7.104 63.616A256.032 256.032 0 0 1 624 742.272zM384 290.24V384a32 32 0 0 0 64 0V192H256a32 32 0 1 0 0 64h63.936a320.064 320.064 0 0 0 151.488 573.44 32 32 0 0 0 8-63.488A256.064 256.064 0 0 1 384 290.24z" p-id="10328"></path></svg>
                                                </span>
                                            </el-tooltip>

                                        </template>
                                        <contract-checker-edit-mode
                                            v-if="editorManager"
                                            @check-content-completed="contractCheckSuccess()"
                                            @contract-check-progress-ratio-updated="contractCheckProgressRatioUpdated"
                                            :main-editor="editorManager.getMainEditor()"
                                            ref="contractChecker" :errorStatistic="errorStatistic" :contract="contract"
                                                                    :get-highlight-ref="getHighlightRef" @checking-status-change="checkingStatusChange"
                                                                    v-model:editor-position="editorPosition" @new-text-need-check="textNeedCheck">
                                        </contract-checker-edit-mode>
                                    </el-tab-pane>
                                    <el-tab-pane  :name="TAB_NAME.COMMENTS">
                                        <template #label>
                                            评论({{commentsCount}})
                                        </template>

                                    </el-tab-pane>
                                </el-tabs>
                                <!--后面如果需要放开右侧悬浮菜单，则可以取消下面注释-->
<!--                                <div class="fixRightContainer" v-if="contractChecking">-->
<!--                                    <i class="el-icon-loading" style="font-size:22px;"> </i>-->
<!--                                    <div class="meta-button" v-if="TAB_NAME.CONTRACT_CHECK===activeTab" v-loading="contractChecking" @click="startCheck">{{contractChecked?'刷新AI审查':'开始AI审查'}}</div>-->
<!--                                </div>-->
                            </div>

                            <!--加一个loading-->
                            <div id="checkLoadingMask" style="" :style="{left:rightPanelLeft}" v-show="contractChecking&&activeTab===TAB_NAME.CONTRACT_CHECK">
                              <div  style="display: flex;justify-content: center;align-items: center;background: rgba(0, 0, 0, 0);width:100%;height:100%">
                                <el-progress type="circle" :percentage="contractCheckProgressRatio" :width="50" :stroke-width="3" :duration="1"/>
                              </div>
                            </div>
                        </div>
                        <div id="lastPageHeaderFooterTools" v-if="mainEditorConfig.showHeaderFooterBtn">
                            <button v-on:click="openHeaderTool()">设置最后一页页眉</button>
                            <button v-on:click="openFooterTool()">设置最后一页页脚</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>




        <el-dialog
                title="提示"
                v-model="disconnectDialogVisible"
                width="30%"
                :show-close="false"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
        >
            <span style="font-size: 18px">与服务器断开连接,请刷新页面</span>
            <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleReconnect"
          >刷新页面</el-button
          >
        </span>
            </template>
        </el-dialog>
        <Mask ref="mask" :display="'block'"></Mask>
    </div>

</template>

<script>
    import EditorJS from '../assets/js/editorjs/editor';
    import MetaSotaBlock from '../assets/js/editorjs/metasotablock'
    import * as InlineTools from "../assets/js/editorjs/inline-tools"
    import {ImageModule, Numbering} from "../assets/js/editorjs/editor-modules";
    import * as EditorHeader from "../assets/js/editorjs/editor-header";
    import UserInfoDialog from "./dialog/UserInfoDialog";
    import CommentDialog from "./dialog/CommentDialog";
    import Mask from "./universalUI/Mask";
    import PageHeaderFooter from "./dialog/PageHeaderFooter";
    import $ from 'jquery'
    // import ProFileTitle from "./checker/ProFileTitle";
    import CoEditor from "../assets/js/websocket/co-editor";
    import {
        ConnectStatus,
        RequestMessageType,
        CommandType,
        CommentType,
        EventsType,
        COEditorEventType, ResponseErrorMsgCode,HeaderEventsType
    } from "../assets/js/websocket/websocket-message-type";
    import EditorManager from "../assets/js/websocket/editor-manager";
    import {colorLog, debounce, DomEventListenerManager, getBase64, getUniqueId} from "../util";
    import anime from "../assets/js/anime.es";
    import * as EditorModules from "../assets/js/editorjs/editor-modules";
    import config from "../config";
    import {ErrorTypeMenuItemKey, SavingStatus} from "../constant/alert";
    import {CommentPermissionMode} from "./common/constant";
    import {checkCommentEditPermission} from "../constant/contract";
    import CheckerInputBox from "./checker/CheckerInputBox";
    import Comment from "./dialog/Comment";
    import ContractCheckerEditMode from "./contract/ContractCheckerEditMode";
    import CommentsInvisibleTipGroup from "./dialog/CommentsInvisibleTipGroup";
    import UserModifyVisualization from "./userModify/UserModifyVisualization";
    import {ElLoading} from "element-plus";
    import SvgIcon from "./SvgIcon/svgIcon";
    import ContractBlockTag from "@/components/contract/ContractBlockTag";

    const TAB_NAME = {
        CONTRACT_CHECK:'contract_check',
        COMMENTS:'comments'
    }


    export default {
        name: "ProCooperationEditor",
        props: ['fileId', 'content', 'fileName', 'changeContentCallBack','onUserLogin','contract','onUserLogout','canComment','readOnly','editUsers'],
        computed: {
            commentReadonly:function(){
                return !checkCommentEditPermission(this.canComment)
            },
            canSetPermissionMode:function () {
                return this.contract && this.contract.teamId === this.userInfo.mainSiteTeamId;
            },
            showComments: function () {
                if (this.activeTab === TAB_NAME.COMMENTS) {
                    return this.inlineAnnotations;
                }else{
                    return [];
                }
            },
            commentsCount:function(){
                return this.inlineAnnotations.filter(a => !a.hidden).length;
            },
            showRightPanel:function () {
                return this.rightPanelActive && this.contractCheckModeOpenForThisUser&&!this.rightPanelDelayShowing;
            }
        },
        async mounted() {
            // console.log('pro cooperation editor***************')
            console.log(this.fileId, this.content, this.fileName, this.changeContentCallBack)
          this.initOpenBlockTag();
            // console.log('pro cooperation editor***************')

        },
        data() {
            const teamInfo = JSON.parse(sessionStorage.getItem("teamInfo"));
            // teamInfo['contractReviewEnabled'] = false;
            // const contractReviewEnabled = teamInfo['contractReviewEnabled'];
            const contractCheckModeOpenForThisUser = teamInfo['contractReviewEnabled'] && (this.contract.teamId === teamInfo.teamId) && !config.noModel
            let reviewTabToolGroup = []
            if (!contractCheckModeOpenForThisUser){
                reviewTabToolGroup = [{
                    name: '批注',
                    tools: [{
                        class: InlineTools.CommentsTool,
                        adapter: EditorHeader.HeaderToolAdapter,
                        cnName:'评论'
                    }]
                }];
            }
            return {
                config:config,
                openBlockTag:this.$route.query.obt,
                errorStatistic:{},
                ErrorTypeMenuItemKey:ErrorTypeMenuItemKey,
                showCustomHeaderTab:false,
                currentCustomHeaderTabName:null,
                rightPanelActive:false,
                rightPanelDelayShowing:false,
                contractChecked:false,
                contractChecking:false,
                newTextNeedCheck:false,
                TAB_NAME:TAB_NAME,
                editorPosition: {top: 0, left: 0, width: 0, height: 0},
                editorMarginLeft:'auto',
                rightPanelLeft:window.innerWidth - 500+'px',
                rightPanelContainerWidth:466,
                maskTop:'80px',
                rightPanelContainerId:'alertContainer',
                teamInfo: teamInfo,
                contractCheckModeOpenForThisUser:contractCheckModeOpenForThisUser,
                editor: null,
                editorId: 'metaProFileEditor',
                scrollerId: 'metaProScroller',
                disconnectDialogVisible: false,
                domEventListenerManager:new DomEventListenerManager(),
                savingStatus: 0,//0:已保存，1:保存中,2:失败
                SavingStatus,
                //关于editor的对象
                editorManager: null,
                editorHeader:null,
                focusAnnotationMarkId: null,
                hoverAnnotationMarkId: null,
                inlineAnnotations: [],
                userInfo:JSON.parse(sessionStorage.getItem("userInfo")),
                currentUser:{},//当前用户的session信息
                sendCommentPermissionMode:this.canSetPermissionMode?CommentPermissionMode.Internal:CommentPermissionMode.Public,
                headerToolConfig: {
                    container: "#editor-header",
                    tabContainer:"#contract-edit-header-center",
                    tabs: [
                        {
                            name: "开始",
                            groups: [
                              {
                                name:"剪切板",
                                tools: [
                                  {
                                    class: InlineTools.FormatPainterTool,
                                    adapter: EditorHeader.HeaderToolAdapter,
                                    cnName:'格式刷(Ctrl + F)'
                                  },
                                  {
                                    class:EditorHeader.UndoTool,
                                    cnName:'撤销(Ctrl + Z)'
                                  },
                                  {
                                    class:EditorHeader.RedoTool,
                                    cnName:'恢复(Ctrl + Y)'
                                  }
                                ]
                              },{
                                name:"字体",
                                tools:[
                                  {
                                    class:InlineTools.FontFamilySelectorTool,
                                    adapter: EditorHeader.SelectorToolAdapter,
                                    cnName:'字体',
                                    config:{
                                      optionWidth:100,
                                      optionFz:14,
                                      width:49
                                    }
                                  },
                                  {
                                    class:InlineTools.FontSizeSelectorTool,
                                    adapter: EditorHeader.SelectorToolAdapter,
                                    cnName:'字号',
                                    config:{
                                      optionWidth:80,
                                      optionFz:12,
                                      width:24
                                    }
                                  },
                                  {
                                    class: InlineTools.FontSizeIncreaseTool,
                                    adapter: EditorHeader.FontSizeToolAdapter,
                                    cnName:'增大字号'
                                  },
                                  {
                                    class: InlineTools.FontSizeDecreaseTool,
                                    adapter: EditorHeader.FontSizeToolAdapter,
                                    cnName:'减小字号'
                                  },
                                  {
                                    class:InlineTools.BoldInlineTool,
                                    adapter: EditorHeader.HeaderToolAdapter,
                                    cnName:'加粗'
                                  },
                                  {
                                    class: InlineTools.ItalicInlineTool,
                                    adapter: EditorHeader.HeaderToolAdapter,
                                    cnName:'斜体'
                                  },
                                  {
                                    class: InlineTools.UnderlineInlineTool,
                                    adapter: EditorHeader.HeaderToolAdapter,
                                    cnName:'下划线'
                                  },
                                  {
                                    class: InlineTools.BackgroundMarkerTool,
                                    adapter: EditorHeader.MarkerToolAdapter,
                                    cnName: '高亮'
                                  },
                                  {
                                    class: InlineTools.FontMarkerTool,
                                    adapter: EditorHeader.MarkerToolAdapter,
                                    cnName: '字体颜色'
                                  }
                                ]
                              },
                              {
                                name:'段落',
                                tools:[
                                  {
                                    class:EditorHeader.PatternHeaderTool,
                                    cnName:'主题样式',
                                    config:{
                                      optionWidth:150,
                                      width:36,
                                      options:[
                                        {
                                          'label':'正文',
                                          'value':'pattern0'
                                        },
                                        {
                                          'label':'标题1',
                                          'value':'pattern1'
                                        },{
                                          'label':'标题2',
                                          'value':'pattern2'
                                        }
                                        ,{
                                          'label':'标题3',
                                          'value':'pattern3'
                                        }
                                      ],
                                      optionsStyle:{
                                        pattern0:{
                                          bold: true,
                                          rFonts: {ascii: "宋体", eastAsia: "宋体"},
                                          sz: 22
                                        },
                                        pattern1:{
                                          bold: true,
                                          rFonts: {ascii: "黑体", eastAsia: "黑体"},
                                          sz: 45,
                                          align:'center'
                                        },
                                        pattern2:{
                                          bold: true,
                                          rFonts: {ascii: "黑体", eastAsia: "黑体"},
                                          sz: 33,
                                          align:'center'
                                        },
                                        pattern3:{
                                          bold: true,
                                          rFonts: {ascii: "黑体", eastAsia: "黑体"},
                                          sz: 24,
                                          align:'center'
                                        }

                                      }
                                    }
                                  },
                                  {
                                    class: InlineTools.AlignLeft,
                                    adapter: EditorHeader.HeaderToolAdapter,
                                    cnName:'左对齐'
                                  },
                                  {
                                    class: InlineTools.AlignCenter,
                                    adapter: EditorHeader.HeaderToolAdapter,
                                    cnName:'居中对齐'
                                  },
                                  {
                                    class: InlineTools.AlignRight,
                                    adapter: EditorHeader.HeaderToolAdapter,
                                    cnName:'右对齐'
                                  },
                                  {
                                    class: EditorHeader.IncreaseIndentation,
                                    cnName:'增加缩进量'
                                  },
                                  {
                                    class: EditorHeader.DecreaseIndentation,
                                    cnName:'减少缩进量'
                                  },
                                  {
                                    class: EditorHeader.ListNormalStyle,
                                    cnName:'常规列表'
                                  },
                                  {
                                    class: EditorHeader.ListMultiStyle,
                                    cnName:'缩减列表'
                                  }
                                ]
                              }
                            ]
                        },
                      {
                            name: "插入",
                            groups: [
                                {
                                    name: "页面",
                                    tools: [
                                        {
                                            class: EditorHeader.MetaParagraph,
                                          cnName:'普通段落'
                                        },
                                      {
                                        class: EditorHeader.HeaderListItem,
                                        cnName:'列表'
                                      },
                                        // {
                                        //     class: EditorHeader.HeaderImage,
                                        //   cnName:'图片',
                                        //     config: {
                                        //         endpoints: {
                                        //             byFile: `${config.baseUrl}file/${this.fileId}/file-image`,
                                        //             byUrl: `${config.baseUrl}/file/${this.fileId}/file-image`,
                                        //             download: `/file/${this.fileId}/file-image/`
                                        //         },
                                        //     },
                                        // },
                                      {
                                        class: EditorHeader.HeaderTable,
                                        cnName:'表格'
                                      },
                                        {
                                            class: EditorHeader.Separator,
                                          cnName:'分页符'
                                        }
                                    ]
                                }
                            ]
                        },
                      {
                        name: "修订",
                        groups: [                {
                          name:'修订',
                          tools:[{
                            class:InlineTools.AcceptChangeTool,
                            adapter: EditorHeader.HeaderToolAdapter,
                            cnName:'接受'
                          },{
                            class:InlineTools.RefuseChangeTool,
                            adapter: EditorHeader.HeaderToolAdapter,
                            cnName:'拒绝'
                          },{
                            class:InlineTools.PreviousChangeTool,
                            adapter: EditorHeader.HeaderToolAdapter,
                            cnName:'上一处'
                          },{
                            class:InlineTools.NextChangeTool,
                            adapter: EditorHeader.HeaderToolAdapter,
                            cnName:'下一处'
                          }]
                        }]
                      },
                        {
                            name: "审阅",
                            groups: reviewTabToolGroup
                        }
                    ]
                },
                commonConfig: {
                    /**
                     * Tools list
                     */
                    tools: {
                        metaParagraph: {
                            class: MetaSotaBlock.MetaParagraph,
                            inlineToolbar: true,
                            config: {
                              endpoints: {
                                uploadByFile: `${config.baseUrl}file/${this.fileId}/file-image`,
                                uploadByUrl: `${config.baseUrl}file/${this.fileId}/file-image`,
                                downloadUrl: `${config.baseUrl}file/${this.fileId}/file-image/`
                              },
                            },
                        },
                        contents: {
                            class: MetaSotaBlock.ContentsBlock
                        },
                        boldInlineTool: {class: InlineTools.BoldInlineTool,},
                        fontSizeIncreaseTool: {class: InlineTools.FontSizeIncreaseTool,},
                        fontSizeDecreaseTool: {class: InlineTools.FontSizeDecreaseTool,},
                        linkInlineTool: {class: InlineTools.LinkInlineTool,},
                        italicInlineTool: {class: InlineTools.ItalicInlineTool,},
                        underLineInlineTool: {class: InlineTools.UnderlineInlineTool,},
                        backgroundMarkerTool:{class: InlineTools.BackgroundMarkerTool,},
                        fontMarkerTool:{class: InlineTools.FontMarkerTool,},
                        alignLeft: {class: InlineTools.AlignLeft},
                        alignCenter: {class: InlineTools.AlignCenter},
                        alignRight: {class: InlineTools.AlignRight},
                        inlineToolList: {class: InlineTools.InlineToolList},
                        fontSizeSelectorTool: {
                            class: InlineTools.FontSizeSelectorTool,
                        },
                        fontFamilySelectorTool: {
                            class: InlineTools.FontFamilySelectorTool,
                        },
                        listItem: {class: MetaSotaBlock.ListItem, inlineToolbar: true,
                            config: {
                                endpoints: {
                                    uploadByFile: `${config.baseUrl}file/${this.fileId}/file-image`,
                                    uploadByUrl: `${config.baseUrl}file/${this.fileId}/file-image`,
                                    downloadUrl: `${config.baseUrl}file/${this.fileId}/file-image/`
                                },
                            },
                        },
                        table: {class: MetaSotaBlock.Table, inlineToolbar: true,},

                        separator: {
                            class: MetaSotaBlock.Separator,
                            inlineToolbar: true,
                        },
                        formatPainter: {
                            class: InlineTools.FormatPainterTool
                        },
                        // commentsTool: {
                        //     class: InlineTools.CommentsTool
                        // },
                      acceptChangeTool:{
                        class:InlineTools.AcceptChangeTool,
                        trackingMode:true,
                      },
                      refuseChangeTool:{
                        class:InlineTools.RefuseChangeTool,
                        trackingMode:true,
                      },
                      previousChangeTool:{
                        class:InlineTools.PreviousChangeTool,
                        trackingMode:true,
                      },
                      nextChangeTool:{
                        class:InlineTools.NextChangeTool,
                        trackingMode:true,
                      }
                    },
                    modules: {
                        ImageModule: {
                            type: "class",
                            class: EditorModules.ImageModule
                        },
                    },
                    /**
                     * This Tool will be used as default
                     */
                    initialBlock: 'metaParagraph',
                    /**
                     * Initial Editor data
                     */
                    mode: 'DEFAULT',
                    readOnly:this.readOnly?this.readOnly:false,
                    tracking:true,
                    uid:this.$getCookie("uid")
                },
                mainEditorConfig: {
                    minHeight:500,
                    holder: 'metaProFileEditor',
                    type: CommandType.DELTA,
                },
                hdrEditorConfig: {
                    holder: 'header-container',
                    type: CommandType.HEADER_DELTA,
                    hideBlockMenu: true,
                    hideBlockInlineTool: true,
                },
                ftrEditorConfig: {
                    holder: 'footer-container',
                    type: CommandType.FOOTER_DELTA,
                    hideBlockMenu: true,
                    hideBlockInlineTool: true,
                },
                numberingConfig: {
                    class: EditorModules.Numbering,
                    type: CommandType.NUMBERING
                },
                commentClass:'comments',
                activeTab:  TAB_NAME.COMMENTS,
                showModificationVisualizations:false,
              contractCheckProgressRatio:0
            };
        },
        watch: {
          showRightPanel(show){
            if(show){
              // 自动调整遮挡版的位置。
              this.$nextTick(()=>{
                this.recalculateMaskPosition();
              });
            }
          },
            editUsers:{
              handler: function (newEditUsers){
              },
              immediate:true
            },
            activeTab(currentTab){
                let contractChecker = this.getContractCheckerRef();
                if(!contractChecker) return;
                currentTab === TAB_NAME.CONTRACT_CHECK ? contractChecker.active() : contractChecker.disActive();
                if (currentTab === TAB_NAME.COMMENTS) {
                    this.$nextTick(() => {
                        this.resetAnnotationsPosition();
                    })
                }
            },
            canSetPermissionMode(newVal){
                console.log('canSetPermissionMode changed',this.canSetPermissionMode)
                if (!this.canSetPermissionMode) {
                    this.sendCommentPermissionMode = CommentPermissionMode.Public;
                }
            },
            focusAnnotationMarkId(newMarkId, oldMarkId) {
                if (this.hoverAnnotationMarkId) {
                    this.reverHoverHightlightFn && this.reverHoverHightlightFn();
                    this.reverHoverHightlightFn = null;
                }
                let annotationsDom = this.getAllCommentsBox();

                if (newMarkId) {
                    [].forEach.call(annotationsDom, (node) => {
                        let markId = node.dataset.markid;
                        if (newMarkId != markId) {
                            this.getAnnotation(markId)?.minimizeDialog();
                        }
                    })
                }
                this.highlightAnnotation(newMarkId)
                this.unHighlightAnnotation(oldMarkId)
            },
            hoverAnnotationMarkId(newMarkId, oldMarkId) {
                this.highlightAnnotation(newMarkId)
                this.unHighlightAnnotation(oldMarkId, this.focusAnnotationMarkId);
            },
            'showComments': {
                handler: function (newVal, oldVal) {
                    this.refreshCommentsElementUI();
                },
                deep: true
            },
            savingStatus(val){
                this.$emit('sa')
            },
            'readOnly':function (){
              this.editorManager.getMainEditor().readOnly.toggle(this.readOnly);
              this.editorHeader.container.remove();
              this.editorHeader.tabContainer.remove();

            },
        },
      beforeUnmount() {
          this.destroy();
          this.domEventListenerManager.removeListener();
      },
      methods: {
        async startInit(){
          this.showLoading();
          this.initEditor();
          await this.editorManager.getMainEditor();
          //监听window resize,来计算编辑区域左右两边的空白距离
          this.resetMainEditorWrapMargin();
          this.initCheckMode();
          this.domEventListenerManager.registerListener(window,"resize",this.resetMainEditorWrapMargin.bind(this));

          //监听editorsWrap的上下滚动,然后播放editorHeader的box阴影动画
          let editorHeader = this.$refs.editorHeader;
          let editorsWrap = this.$refs.editorsWrap;
          this.domEventListenerManager.registerListener(editorsWrap,'scroll',()=>{
            if(editorsWrap.scrollTop != 0){
              editorHeader.style.boxShadow = "0 2px 4px 0px #ccc";
            }else{
              editorHeader.style.removeProperty("box-shadow");
            }
          })
        },
        initOpenBlockTag:function (){
           let queryObt = this.$route.query.obt
          console.log('obt:',queryObt)
          if (queryObt !== undefined) {
            localStorage.setItem('obt',queryObt)
          }
          let storageObt = localStorage.getItem('obt');
          this.openBlockTag = (storageObt == null ? false : storageObt === '1');
        },
          recalculateMaskPosition(){
            console.log('calculate')
            this.maskTop = $("#metaProFileContentDiv").offset().top + 'px';
          },
          initCheckMode(){
              if(this.contractCheckModeOpenForThisUser){
                  this.rightPanelActive = true;
                  this.activeTab = TAB_NAME.CONTRACT_CHECK;
                  this.$nextTick(()=>{
                      this.adjustPositionOnCheckMode();
                  })
              }else{
                  this.rightPanelActive = false;
                  this.activeTab = TAB_NAME.COMMENTS;
              }
          },
            resetMainEditorWrapMargin(){
              let editorsWarp = this.$refs.editorsWrap;
              let mainEditorWrap = this.$refs.mainEditorWrap;

              let outter = editorsWarp.offsetWidth;
              let inner = mainEditorWrap.offsetWidth;
              if(outter - inner > 0){
                let residual = outter - inner;
                //466是editor右侧固定菜单区域的宽度
                const threshold = this.rightPanelActive ? this.rightPanelContainerWidth : 230;
                if(residual > threshold){
                  mainEditorWrap.style.marginRight = (residual - threshold) / 2 + 'px';
                }else{
                  mainEditorWrap.style.removeProperty("marginRight");
                }
              }
              this.adjustPositionOnCheckMode()
            },
            getAllCommentsBox(){
                return this.$refs.commentsPanel.$el.querySelectorAll('.commentDialog-container');
            },
            openHeaderTool() {
                this.$refs.pageToolContainer.style.zIndex = 1;
                this.editorManager.produceEditor(CommandType.HEADER_DELTA, 1);
                this.$refs.pageHeaderFooter.openHeaderTool();
            },
            closePageTool() {
                this.$refs.pageHeaderFooter.closeTool(() => {
                    this.editorManager.destroy(CommandType.HEADER_DELTA)
                    this.editorManager.destroy(CommandType.FOOTER_DELTA)
                    this.$refs.pageToolContainer.style.zIndex = 'unset';
                });
            },
            openFooterTool() {
                this.$refs.pageToolContainer.style.zIndex = 1;
                this.editorManager.produceEditor(CommandType.FOOTER_DELTA, 2);
                this.$refs.pageHeaderFooter.openFooterTool();
            },
            initEditor: function () {

              // if( this.commentReadonly){
              //   //协作为只读和可评论权限下，不显示编辑选项“开始”、“插入”
              //   this.headerToolConfig.tabs =  this.headerToolConfig.tabs.filter(item => item.name != '插入' && item.name != '开始');
              // }
                this.commonConfig.showSomeInlineToolInReadonlyMode =!this.commentReadonly;
                const editorManager = this.editorManager = new EditorManager(this.numberingConfig, this.commonConfig,
                    this.mainEditorConfig,
                    this.hdrEditorConfig,
                    this.ftrEditorConfig
                )
              editorManager.getMainEditor().isReady.then(()=>{
                this.showModificationVisualizations = true;
                this.$nextTick(()=>{
                  //加上当前用户的信息
                  this.$refs.userModify.addOnlineUser(this.editUsers[0]);
                })
                if (this.openBlockTag) {
                  this.$refs.blockTag.init();
                }
              });
               if (!this.readOnly) {
                   // 非readonly初始化header
                    this.editorHeader = new EditorHeader.Header(this.headerToolConfig);
                    // this.editorH
                   console.log('editor bind header tab change event',this.editorHeader)
                   this.editorHeader.on(HeaderEventsType.TAB_CHANGED,(tabName) =>{
                       console.log('header tab changed*******,tabName:' + tabName);
                       this.switchCustomHeaderTab(tabName);
                   });

                   this.bindEditorEvent();
               }else{
                   this.bindEditorEventOnReadMode();
               }
                editorManager.onReady(() => {

                    coEditor.connect(`${config.baseUrl}doc`);
                });
                const coEditor = this.coEditor = new CoEditor(editorManager, this.$getCookie("uid"), this.$getCookie("sid"), this.fileId);
                this.bindCoEditorEvent(coEditor);

                let self = this
                let editorElement = this.getEditorElement();
                this.domEventListenerManager.registerListener(editorElement,'click', (e) => {
                  if (!(e.pageX || e.pageY)) {
                    // 如果不是一个正常的点击事件（有可能是有focus触发的）
                    return
                  }
                  self.listenCommentsClicked(e);
                }, {capture: true});
            },

            destroy:function(){
              this.coEditor.destroy();
              this.editorManager.destroy();
              this.editorHeader?.destroy();
              this.editorManager = null;
              this.editorHeader = null;
              this.coEditor = null;
            },
            headerRegisterEditorFun(editor){
              // 2022-7-25 mys临时注释
              if(!this.editorHeader){
                return;
              }
              this.editorHeader.registerEditor(editor);
            },
            bindEditorEvent: function (editorManager) {
                this.editorManager.on(EventsType.OPEN_PAGE_HEADER, (args) => {
                    this.openHeaderTool();
                    console.log(EventsType.OPEN_PAGE_HEADER, args);
                })
                this.editorManager.on(EventsType.OPEN_PAGE_FOOTER, (args) => {
                    this.openFooterTool();
                    console.log(EventsType.OPEN_PAGE_FOOTER, args);
                })

                this.editorManager.on(EventsType.EDITOR_SELECTED, this.headerRegisterEditorFun);
                // editorManager.on('open-inlineAnnotation',({markId,access})=>{
                //       this.addInlineComment(markId,access);
                // })
                this.bindEditorCommentEvent(editorManager);
                this.editorManager.on(EventsType.START_CHECK,() =>{
                    this.switchCheckMode();
                });



              //增加editor的协作用户
              this.editorManager.on(EventsType.ADD_EDIT_USER,(args)=>{
                let {uid} = args;
                this.$refs.userModify.addEditUser(uid);
              })

              // this.editorManager.on(EventsType.TEXT_CHANGE, () => {
              //     console.log('******text-change1')
              //   this.refreshCommentsElementUI();
              //   this.refreshAnnotationShowOrHide();
              //   this.$nextTick(()=>{
              //     // this.resetAnnotationsPosition();
              //     if (TAB_NAME.COMMENTS === this.activeTab) {
              //       this.animeAnnotationsPositionTransformation();
              //     }
              //   })
              //   this.getContractCheckerRef().editorTextChangeFunc();
              //   this.changeContentCallBack();
              // })


              //目前是监听的序号刷新，其实应该监听编辑器渲染完毕事件（目前编辑器尚未实现该事件，故先监听序号刷新（目前会在正文渲染之后异步刷新的只有序号。）。）。
              this.editorManager.on(EventsType.LIST_ITEM_REFRESHED, () => {
                if(!this.editorTextInited){
                  this.editorTextInited = true;
                  this.getContractCheckerRef().initEditorText()
                  this.autoCheckIfNeed();
                  this.hideLoading();
                }
              });
            },
            bindEditorEventOnReadMode:function (editorManager){
              //如果是只读，但是可以评论
              if (!this.commentReadOnly) {
                this.bindEditorCommentEvent(editorManager);
              }
              this.editorManager.on(EventsType.LIST_ITEM_REFRESHED, () => {
                // 只读模式下不需要辅助审查相关，直接隐藏对话框即可。
                this.hideLoading();
              })
            },
            bindEditorCommentEvent:function(){
                this.editorManager.on(EventsType.REQUEST_CREATE_COMMENTS, (param) => {
                    // this.addInlineComment(markId,access);
                    this.requestToCreateComment(param);
                })
            },
            bindCoEditorEvent: function (coEditor) {
                coEditor.on(COEditorEventType.COMMON.ACK_CHANGE, (saving) => {
                    this.savingStatus = saving ? this.SavingStatus.saving : this.SavingStatus.saved;
                    this.$emit('savingStateChanged',this.savingStatus);
                });
                coEditor.on(COEditorEventType.ConnectStatus.LOGIN_SUCCESS, (...args) => {
                    this.$refs.mask.closeMask();
                    [this.currentUser] = args
                    console.log(COEditorEventType.ConnectStatus.LOGIN_SUCCESS, ...args)

                })
                coEditor.on(COEditorEventType.ConnectStatus.LOGIN_FAIL, (...args) => {
                    console.log(COEditorEventType.ConnectStatus.LOGIN_FAIL, ...args)
                })
                coEditor.on(COEditorEventType.ConnectStatus.COOPERATION_LOGIN, (...args) => {
                    if(this.onUserLogin){
                      this.onUserLogin(...args);
                    }
                  this.$refs.userModify.addOnlineUser(args[0]);
                    console.log(COEditorEventType.ConnectStatus.COOPERATION_LOGIN, ...args)
                })
                coEditor.on(COEditorEventType.ConnectStatus.COOPERATION_LOGOUT, (...args) => {
                    let [userInfo] = args;
                    if(this.onUserLogout){
                      this.onUserLogout(userInfo)
                      this.$refs.userModify.removeOnlineUser(userInfo);
                    }
                    // this.$refs.userDialog.removeUserSession(userInfo);
                    console.log(COEditorEventType.ConnectStatus.COOPERATION_LOGOUT, ...args)
                })
                coEditor.on(COEditorEventType.ConnectStatus.COOPERATION_HELLO, (...args) => {
                    // this.$refs.userDialog.addUser(...args);
                  if(this.onUserLogin){
                    this.onUserLogin(...args);
                  }
                  this.$refs.userModify.addOnlineUser(args[0]);
                    console.log(COEditorEventType.ConnectStatus.COOPERATION_HELLO, ...args)
                })
                coEditor.on(COEditorEventType.ConnectStatus.COOPERATION_DISCONNECT, (...args) => {
                    this.handleDisconnect();
                    console.log(COEditorEventType.ConnectStatus.COOPERATION_DISCONNECT, ...args)
                })
                coEditor.on(COEditorEventType.Content.GET_CONTENT_SUCCESS,(...args) => {
                    this.sendCommentOperation(RequestMessageType.COMMENT, {type:CommentType.GET})
                    this.headerAutoBindEditor(this.editorManager.getMainEditor());
                })
                coEditor.on(COEditorEventType.CommentType.CREATE, (...args) => {
                    console.log('CommentType.CREATE', args);
                    args.forEach(arg => {
                        let {markId} = arg.comment;
                        this.addCommentMessage(markId, arg.comment);
                    })

                });
                coEditor.on(COEditorEventType.CommentType.DELETE, (...args) => {
                    console.log('CommentType.DELETE', args)
                    args.forEach(arg => {
                        let {id} = arg.comment;
                        this.delCommentMessage(id);
                    })
                })
                coEditor.on(COEditorEventType.CommentType.SOLVE, (...args) => {
                    console.log('CommentType.SOLVE', args)
                    args.forEach(arg => {
                        let {markId} = arg.comment;
                        this.resolveAnnotation(markId);
                    })

                })
                coEditor.on(COEditorEventType.CommentType.GET, (...args) => {
                    console.log('CommentType.GET', args)
                    let comments = args[0].comments;
                    let classfiedComments = {};
                    comments.forEach((comment, index) => {
                        let group = classfiedComments[comment.markId];
                        if (group) {
                            group.push(comment);
                        } else {
                            classfiedComments[comment.markId] = [comment];
                        }
                    })
                    let transformedComments = Object.keys(classfiedComments).map((markId, index) => {
                        return {markId, position: 0, comments: classfiedComments[markId]}
                    })

                    this.inlineAnnotations = [...this.inlineAnnotations, ...transformedComments];
                    this.refreshAnnotationShowOrHide();
                    this.$nextTick(() => {
                        this.resetAnnotationsPosition();
                    })
                })
                coEditor.on(COEditorEventType.ERROR.HANDLE_ERROR,(data)=>{
                    if (ResponseErrorMsgCode.NO_PERMISSION === data.code) {
                        const requestType = data.requestType;
                        if (RequestMessageType.COMMAND === requestType) {
                            this.$message.error({message:"您没有编辑本文档的权限，系统将不会保存您的修改。",duration:0})
                        }else if(RequestMessageType.COMMENT === requestType){
                            this.$message.error({message:"您没有评论本文档的权限，系统将不会保存您的评论信息。",duration:0})
                        }
                    }
                })

              const debounceUpdateAvatarGroupPosition = debounce(()=>{
                if(!this.$refs.userModify) return;
                this.$refs.userModify.updateGroupsPosition();
              },300);

                this.editorManager.on(EventsType.TEXT_CHANGE, () => {
                    debounceUpdateAvatarGroupPosition();
                    this.refreshCommentsElementUI();
                    this.refreshAnnotationShowOrHide();
                    this.$nextTick(()=>{
                      // this.resetAnnotationsPosition();
                        if (TAB_NAME.COMMENTS === this.activeTab) {
                            this.animeAnnotationsPositionTransformation();
                        }
                    })
                    this.getContractCheckerRef().editorTextChangeFunc();

                    this.changeContentCallBack();

                  if (this.openBlockTag && this.getBlockTagRef().editorTextChangeFunc) {
                    this.getBlockTagRef().editorTextChangeFunc();
                  }
                })

              coEditor.on(EventsType.BLOCK_ON_EDIT,(args) => {
                const {uid,holder} = args;
                this.$refs.userModify.updateUserAvatar(holder,uid);
              })

                // this.editorManager.on("text-change",debounce(()=>{
                //   let blocksInfo = this.editorManager.editorCluster.get(CommandType.DELTA).content.getBlocksByTextFormat('comments');
                //   let forntCommentsId = this.inlineAnnotations.map((annotation)=>annotation.markId);
                //
                //   let editorCommentsId = [];
                //   blocksInfo.forEach(({formats,block})=>{
                //     formats.forEach((format)=>{
                //       let commentsInfo = format.formats.comments;
                //       if(commentsInfo){
                //         editorCommentsId = editorCommentsId.concat(commentsInfo.reduce((idList,comment)=>{
                //           idList.push(comment.markId)
                //           return idList;
                //         },[]))
                //       }
                //     })
                //   })
                //   editorCommentsId = [...new Set(editorCommentsId)];
                //
                //   /**
                //    * 取反交集
                //    * @type {*[]}
                //    */
                //   let delCommentsId = forntCommentsId.filter((markId)=>{
                //     return !editorCommentsId.includes(markId);
                //   })
                //   console.log({editorCommentsId,forntCommentsId,delCommentsId});
                //   delCommentsId.forEach((markId)=>{
                //     console.log('删除',markId)
                //     this.sendCommentOperation(RequestMessageType.COMMENT,{
                //       type:CommentType.SOLVE,
                //       comment:{
                //         markId:markId
                //       }
                //     });
                //   });
                //
                // },300))

            },
            handleDisconnect() {
                this.disconnectDialogVisible = true;
            },
            handleReconnect() {
                window.location.reload();
            },
            sendCommentOperation(messageType, msg) {
                console.log('sendCommentOperation', {messageType, msg})
                this.coEditor.sendMsg(messageType, msg);
            },
            // 收到editorjs事件提醒，请求创建一个评论
            requestToCreateComment(param) {
                if (window.getSelection().isCollapsed) {
                    this.$message.error("请选中文本后再添加评论")
                    return
                }
                if (this.activeTab !== TAB_NAME.COMMENTS) {
                    this.activeTab = TAB_NAME.COMMENTS;
                    this.realRequestToCreateComment(param.uuid)
                }else{
                    console.log('now ***')
                    this.realRequestToCreateComment(param.uuid)
                }
            },
            realRequestToCreateComment(markId){
                console.log(this.editorManager.editorCluster.get(CommandType.DELTA));
                this.inlineAnnotations.push(this.getDefaultAnnotation(markId))
                this.$nextTick(()=>{
                    this.openAnnotation(markId);
                });
            },
            openAnnotation(commentsId){
                console.log('focus annotation',commentsId)
                this.activeTab=TAB_NAME.COMMENTS;
                this.$nextTick(() => {
                    let annotation = this.getAnnotation(commentsId);
                    this.focusAnnotationMarkId = commentsId;
                    annotation.openDialog();
                    this.$nextTick(() => {
                        annotation.createBlurListener();
                        this.animeAnnotationsPositionTransformation(commentsId);
                    })
                    // 这里延迟150毫秒是等右测评论box移动完成，防止focus导致页面跳到了box移动之前的地方。
                    // setTimeout(()=>{
                    //     annotation.focusTextArea();
                    // },300)
                })
            },
            //生成一个默认的annotation
            getDefaultAnnotation(commentsId) {
                return {
                    markId: commentsId,
                    access: false,
                    position: this.getCommentsElementOffsetEditor(commentsId),
                    comments: [],
                    hidden: false
                }
            },
            getEditorId:function(){
                return this.editorId
            },
            // 返回comments相对于editor的偏移
            getCommentsElementOffsetEditor: function (commentsId) {
                let editor = $(`#${this.getEditorId()}`)
                let editorOffset = editor.offset().top;
                let editorScrollTop = editor.scrollTop();
                let commentsElements = this.getCommentsElements(commentsId);
                if (commentsElements.length === 0) {
                    return 0;
                }
                return $(commentsElements[0]).offset().top - editorOffset + editorScrollTop;
            },
            //根据
            getCommentsElements: function (commentsId) {
                return this.editorManager.editorCluster.get(CommandType.DELTA).getCommentsElements(commentsId);
            },
            getAllCommentsElements: function () {
                const deltaEditor = this.editorManager.editorCluster.get(CommandType.DELTA);
                if (deltaEditor && deltaEditor.getAllCommentsElements) {
                    return deltaEditor.getAllCommentsElements();
                }
                console.error('editor not exist !')
                return [];
            },
            getCommentsIdsByElement: function (element) {
                return this.editorManager.editorCluster.get(CommandType.DELTA).getCommentsIdsByElement(element);
            },
            getCommentsIdsICanView: function () {
                let result = new Set();
                this.showComments.forEach(a => {
                    if (a.hidden || a.solve) {
                        return
                    }
                    result.add(a.markId);
                })
                return result;
            },
            refreshCommentsElementUI: function () {
                let commentsIdSet = this.getCommentsIdsICanView()
                let elements = this.getAllCommentsElements();
                elements.forEach(element => {
                    let ids = this.getCommentsIdsByElement(element);
                    // 只有自己能看到的评论才给他标记出来（划线）
                    if (ids.some(id => commentsIdSet.has(id))) {
                        element.classList.add(this.commentClass);
                    } else {
                        element.classList.remove(this.commentClass);
                    }
                });
            },
            getEditorAllCommentsId: function () {
                return this.editorManager.editorCluster.get(CommandType.DELTA).getAllCommentsId();
            },
            getInlineAnnotationMap: function () {
                const result = new Map();
                for (let i = 0; i < this.inlineAnnotations.length; i++) {
                    result.set(this.inlineAnnotations[i].markId, this.inlineAnnotations[i])
                }
                return result;
            },
            refreshAnnotationShowOrHide: function () {
                const currentAllCommentsId = new Set(this.getEditorAllCommentsId());
                let showMarkIds = new Set();
                this.inlineAnnotations.forEach((annotation) => {
                    annotation.hidden = annotation.isSolve || !currentAllCommentsId.has(annotation.markId)
                    if (!annotation.hidden) {
                        showMarkIds.add(annotation.markId);
                    }
                })
                $(`.${this.commentClass}`).each((index, element) => {
                    let elementCommentsIds = this.editorManager.editorCluster.get(CommandType.DELTA).getCommentsIdsByElement(element);
                    if (!elementCommentsIds || elementCommentsIds.length === 0) {
                        element.classList.remove(this.commentClass)
                        return
                    }
                    if (elementCommentsIds.every(id => !showMarkIds.has(id))) {
                        element.classList.remove(this.commentClass)
                        return
                    }
                })
            },
            getAnnotationById: function (id) {
                return this.inlineAnnotations.find(annotation => annotation.markId === id)
            },
            async addCommentMessage(cid, comment) {
                let annotation = this.getAnnotationById(cid);
                let localNoAnnotation = !annotation; // 本地没有这个annotation
                if (localNoAnnotation) {
                    annotation = this.getDefaultAnnotation(cid);
                    this.inlineAnnotations.push(annotation);
                }
                // 将comments按时间插入到annotation的comments里面
                let insertIndex = annotation.comments.findIndex(cmt => {
                    return cmt.time > comment;
                });
                if (insertIndex === -1) {
                    insertIndex = annotation.comments.length;
                }
                if (!localNoAnnotation && annotation.comments.length === 0) {
                    // 本地有这个annotation，并且这个annotation还没有comments，说明这是一个临时创建的annotation提交了，需要将comments由pending转为确认。
                    this.editorManager.editorCluster.get(CommandType.DELTA).confirmComments(cid);
                }
                annotation.comments.splice(insertIndex, 0, comment);

                this.$nextTick(() => {
                    this.refreshAnnotationShowOrHide();
                    let annotation = this.getAnnotation(cid)
                    annotation.restoreDraftCommentDefault();
                    // annotation.$refs.dialogComment.scrollTo(0,annotation.$refs.dialogComment.scrollHeight);
                    this.animeAnnotationsPositionTransformation();
                })
            },
            delCommentMessage(mid) {
                this.inlineAnnotations = this.inlineAnnotations.map((annotation) => {
                    annotation.comments = annotation.comments?.filter((message) => {
                        return message.id !== mid;
                    })
                    return annotation;
                })

                // this.$refs[`annotations-${markId}`]?.openDialog();

                this.$nextTick(() => {
                    this.animeAnnotationsPositionTransformation();
                })
            },
            deleteAnnotation(markId){
                // console.log('deleteAnnotation,commentsId:' + markId);
                const  removeIndex = this.inlineAnnotations.findIndex((annotation) => {
                    return annotation.markId === markId;
                })
                if (removeIndex >= 0) {
                    this.inlineAnnotations.splice(removeIndex, 1);
                }
            },
            deleteAnnotationAndAttribute(markId) {
                // console.log('deleteAnnotationAndAttribute,commentsId:' + markId);
                this.deleteAnnotation(markId)
                this.editorManager.editorCluster.get(CommandType.DELTA).deleteCommentsById(markId);

                this.$nextTick(() => {
                    this.animeAnnotationsPositionTransformation();
                })
            },
            resetPositionWithFocus() {
                let annotationsDom = this.getAllCommentsBox();
                let classfiedComments = this.getClassfiedCommentWithEditorPosition();
                let orderlyTop = Object.keys(classfiedComments).sort((a, b) => Number(a) - Number(b));
                let newInlineAnnotations = this.inlineAnnotations.map(a => ({
                    markId: a.markId,
                    position: a.position
                }));

                let offset = 0;
                let lastElHeight;
                let lastStartPosition;

                orderlyTop.forEach((topVal, index) => {
                    classfiedComments[topVal].forEach((annotationInfo, idx) => {
                        let {markId, position} = annotationInfo;
                        if (index !== 0 || idx !== 0) {
                            if (lastStartPosition + lastElHeight + 5 > position && markId !== this.focusAnnotationMarkId) {
                                //5是annotation之间最小距离
                                position = lastStartPosition + lastElHeight + 5;
                            } else if (markId === this.focusAnnotationMarkId) {
                                position = this.getCommentsElementOffsetEditor(markId)
                                if (position < lastStartPosition + lastElHeight + 5) {
                                    offset = position - (lastStartPosition + lastElHeight + 5);
                                }
                                newInlineAnnotations.forEach((annotationInfo) => {
                                    let targetAnnotationEditorPosition = this.getCommentsElementOffsetEditor(annotationInfo.markId)
                                    if (annotationInfo.markId !== this.focusAnnotationMarkId
                                        && targetAnnotationEditorPosition <= position
                                    ) {
                                        annotationInfo.position = annotationInfo.position + offset;
                                    }
                                })
                            }
                        } else {
                            position = this.getCommentsElementOffsetEditor(markId)
                        }
                        let targetInnotation = newInlineAnnotations.find(annotation => annotation.markId === markId);
                        targetInnotation.position = position;
                        lastStartPosition = position;
                        // console.log({annotationsDom, markId})
                        lastElHeight = [].find.call(annotationsDom, (node) => node.dataset.markid == markId).getBoundingClientRect().height
                    });
                })
                return {newInlineAnnotations, annotationsDom}
            },
            animeAnnotationsPositionTransformation(newMarkId) {
                let {newInlineAnnotations, annotationsDom} = this.resetPositionWithFocus();
                // newInlineAnnotations.forEach((annotationData)=>{
                //   let {markId,position} = annotationData;
                //   let targetNode = [].find.call(annotationsDom,(node)=>{
                //     return markId == node.dataset.markid;
                //   });
                //
                // })

                let that = this;
                anime({
                    targets: annotationsDom,
                    top: function (el, i) {
                        let targetMarkId = el.dataset.markid;
                        let newPosition = newInlineAnnotations.find(annotationData => {
                            return annotationData.markId == targetMarkId;
                        }).position;
                        if (newMarkId == el.dataset.markid) {
                            return [newPosition, newPosition]
                        } else {
                            return newPosition;
                        }
                    },
                    left: function (el) {
                        let targetMarkId = el.dataset.markid;
                        if (newMarkId || that.focusAnnotationMarkId) {
                            if (newMarkId == targetMarkId
                                || that.focusAnnotationMarkId == targetMarkId) {
                                return 0
                            } else {
                                return 15;
                            }
                        } else {
                            return 0
                        }
                    },
                    // opacity: function (el) {
                    //     let targetMarkId = el.dataset.markid;
                    //     if (newMarkId || that.focusAnnotationMarkId) {
                    //         if (newMarkId == targetMarkId
                    //             || that.focusAnnotationMarkId == targetMarkId) {
                    //             return 1
                    //         } else {
                    //             return 0.7;
                    //         }
                    //     } else {
                    //         return 1
                    //     }
                    // },
                    duration: 100,
                    easing: 'linear'
                })

                // 判断评论是否在屏幕外面，如果是则需要将它移过来。
                setTimeout(() => {
                    if (newMarkId && this.commentBoxOutOfView(newMarkId)) {
                        this.scrollToViewComment(newMarkId)
                    }
                }, 300);
            },
          commentBoxOutOfView: function (markId) {
              let annotation = this.getAnnotation(markId);
              let offset = $(annotation.$el).offset().top;
              return offset < 70 || offset > window.innerHeight - 140;
          },
          getAnnotation:function(markId){
              let annotation = this.$refs[`annotations-${markId}`];
              if (annotation && annotation instanceof Array) {
                  return annotation[0]
              }
              return annotation;
          },
          scrollToViewComment:function(markId){
              let annotation = this.getAnnotation(markId)
              let scroller = $("#editors-wrap");

              scroller.scrollTop(scroller.scrollTop()-(window.innerHeight/2-$(annotation.$el).offset().top))
          },
            getClassfiedCommentWithEditorPosition() {
                let classfiedComments = this.inlineAnnotations.reduce((classfied, annotation, index) => {
                    let top = this.getCommentsElementOffsetEditor(annotation.markId);
                    let group = classfied[top];
                    if (group) {
                        group.push({
                            markId: annotation.markId,
                            position: top
                        });
                    } else {
                        classfied[top] = [{
                            markId: annotation.markId,
                            position: top
                        }]
                    }
                    return classfied;
                }, {});
                return classfiedComments;
            },
            recomputeAnnotationsPosition() {
                let annotationsDom = this.getAllCommentsBox();
                console.log('length:'+annotationsDom.length)
                let classfiedComments = this.getClassfiedCommentWithEditorPosition();
                let lastElHeight;
                let lastStartPosition;
                let orderlyTop = Object.keys(classfiedComments).sort((a, b) => Number(a) - Number(b));

                let newInlineAnnotations = this.inlineAnnotations.map(a => ({
                    markId: a.markId,
                    position: a.position
                }));

                orderlyTop.forEach((topValue, index) => {
                    classfiedComments[topValue].forEach((annotationInfo, idx) => {
                        let {position, markId} = annotationInfo;
                        if (index !== 0 || idx !== 0) {
                            if (lastStartPosition + lastElHeight + 5 > position) {
                                //5是annotation之间最小距离
                                position = lastStartPosition + lastElHeight + 5;
                            }
                        }
                        let targetInnotation = newInlineAnnotations.find(annotation => annotation.markId === markId);
                        targetInnotation.position = position;

                        lastStartPosition = position;
                        // console.log({annotationsDom, markId})
                        lastElHeight = [].find.call(annotationsDom, (node) => node.dataset.markid == markId).getBoundingClientRect().height
                    })
                })
                return {newInlineAnnotations, annotationsDom};
            },
            resetAnnotationsPosition() {
                /**
                 * 静态改变位置
                 */
                if (this.activeTab !== TAB_NAME.COMMENTS) {
                    return;
                }
                let {newInlineAnnotations, annotationsDom} = this.recomputeAnnotationsPosition();
                this.inlineAnnotations = newInlineAnnotations.reduce((newAnnotations, newInfo, index) => {
                    let targetNode = [].find.call(annotationsDom, (node) => node.dataset.markid == newInfo.markId);
                    targetNode.style.top = newInfo.position + 'px';
                    newAnnotations.push(Object.assign({}, this.inlineAnnotations[index], newInfo));
                    return newAnnotations;
                }, []);
            },
            highlightAnnotation(markId) {
                if (!markId) {
                    return
                }
                this.getCommentsElements(markId).forEach(element => {
                    element.classList.add('highlightComments');
                });
            },
            unHighlightAnnotation(markId, excludeMarkId) {
                if (!markId) {
                    return
                }
                this.getCommentsElements(markId).forEach(element => {
                    // 当节点含有某个markId时，不删除它的高亮class
                    if (excludeMarkId) {
                        const commentsIds = this.getCommentsIdsByElement(element);
                        if (commentsIds.indexOf(excludeMarkId) >= 0) {
                            return
                        }
                    }
                    // 删除高亮class
                    element.classList.remove('highlightComments');
                });
            },
            unHighlightAllAnnotation() {
                this.getAllCommentsElements().forEach(element => {
                    element.classList.remove('highlightComments');
                });
            },
            resolveAnnotation(markId) {
                // resolve不删除editorjs里面的标注、留待恢复
                // this.deleteAnnotation(markId);
                this.deleteAnnotation(markId);
                //
                //
                //
                // let annotation = this.getAnnotationById(markId)
                // console.log('resolve**********', JSON.stringify(annotation));
                // annotation.hidden = true;
                // if (!annotation.comments) {
                //     return
                // }
                // annotation.comments.forEach(comment =>{
                //     comment.solve =true;
                // });
                // console.log('resolve**********',annotation)
                //
                // console.log('resolve')
            },
            listenCommentsClicked(e) {
                let selection = this.getSelection()
                if (selection && !selection.isCollapsed) {
                    return;
                }
                let dataMarkIds = this.getCommentsIdsICanView();
                let allCommentsElement = this.getAllCommentsElements();
                for (let i = 0; i < allCommentsElement.length; i++) {
                    let highlightEleRect = allCommentsElement[i].getBoundingClientRect()
                    if (e.clientX <= highlightEleRect.right && e.clientX >= highlightEleRect.left - 3
                        && e.clientY <= highlightEleRect.bottom + 3 && e.clientY >= highlightEleRect.top) {
                        let commentsIds = this.getCommentsIdsByElement(allCommentsElement[i]);
                        for (let j = 0; j < commentsIds.length; j++) {
                            if(dataMarkIds.has(commentsIds[j])){
                                this.openAnnotation(commentsIds[j]);
                                return
                            }
                        }
                    }
                }
            },
            getSelection: function () {
                if (window.getSelection) {
                    return window.getSelection();
                } else if (window.document.getSelection) {
                    return window.document.getSelection();
                } else if (window.document.selection) {
                    return window.document.selection;
                }
                return null
            },
            editorKeydown: function (e) {
                //自定义pageDown和pageup，防止错位bug的发生。
                let oldScrollTop = this.getScrollerJItem().scrollTop()
                let bodyClientHeight = window.innerHeight
                let scrollOffsetTop = this.getScrollerJItem().offset().top
                let newScrollTop = 0
                if (e.code === 'PageDown') {
                    // 向下翻页
                    newScrollTop = oldScrollTop + bodyClientHeight - scrollOffsetTop
                } else if (e.code === 'PageUp') {
                    // 向上翻页
                    newScrollTop = oldScrollTop - bodyClientHeight + scrollOffsetTop
                } else {
                    return
                }
                e.preventDefault()
                e.stopPropagation()
                this.getScrollerJItem().animate({'scrollTop': newScrollTop}, 150)
            },
            getEditorText: function () {
                return $('#' + this.editorId)[0].innerText.replace(/\n	\n/g, "\n\n").replace(/\t/g, "");
            },
            getEditorElement: function () {
                return document.getElementById(this.editorId)
            },
            getEditorJElement: function () {
                return $('#' + this.editorId)
            },
            getScrollerJItem: function () {
                return $('#' + this.scrollerId)
            },
            // 正文容器到可见区域顶部的距离
            getScrollerOffsetTop: function () {
                return this.getScrollerJItem().offset().top;
            },
            // 正文滚动的距离
            getScrollerScrollTop: function () {
                return this.getScrollerJItem().scrollTop()
            },
            //编辑器距离顶部的距离（也就是编辑器最开始的时候的offset.top）
            getEditorInitOffsetTop: function () {
                return this.getScrollerScrollTop() + this.getEditorJElement().offset().top;
            },
            // 正文容器高度
            getScrollerHeight: function () {
                return this.getScrollerJItem().height()
            },
            convertToInt: function (numberStr) {
                if (typeof numberStr === 'string') {
                    return parseInt(numberStr)
                }
                return numberStr
            },
          //切换成检查模式。
          switchCheckMode:function () {
              // console.log('switch check mode**')
              if (this.rightPanelActive) {
                  this.closeCheckMode();
                  return;
              }
              this.openCheckMode();
          },
          switchCustomHeaderTab:function(tabName){

              this.currentCustomHeaderTabName = tabName;
              if (tabName === '审阅') {
                  this.showCustomHeaderTab = true;
              }else{
                  this.showCustomHeaderTab = false;
              }
          },
          openCheckMode: function () {
              this.rightPanelActive = true;

              // 这里赋一次原值，是为了防止transition从auto到具体数字不生效。
              this.editorMarginLeft = $(`#${this.editorId}`).offset().left + 'px';
              this.$nextTick(() => {
                  this.activeTab = TAB_NAME.CONTRACT_CHECK
                  this.adjustPositionOnCheckMode();
                  if (!this.contractChecked) {
                      this.startCheck();
                  }
              });
          },
          adjustPositionOnCheckMode:function(){
              if (!this.rightPanelActive) {
                  return
              }
              const panelContainerWidth = parseInt(this.rightPanelContainerWidth);
              const  editorWidth = document.getElementById(`${this.editorId}`).offsetWidth
              let editorMarginLeft = (window.innerWidth - panelContainerWidth - 28 - editorWidth) / 2;
              editorMarginLeft = editorMarginLeft > 0 ? editorMarginLeft : 0;
              this.editorMarginLeft =  editorMarginLeft+ 'px';
            this.rightPanelLeft = editorMarginLeft + editorWidth + 28 + 'px';
              this.rightPanelDelayShowing=true;
              setTimeout(() => {
                  this.rightPanelDelayShowing = false
              }, 350);
          },
          closeCheckMode:function(){
              this.rightPanelActive = false;
              this.editorMarginLeft = 'auto';
              // console.log('close check mode*************,editorMarginLeft:' + this.editorMarginLeft);
              // const editorWidth = document.getElementById(`${this.editorId}`).offsetWidth;
              // this.editorMarginLeft = (window.innerWidth - editorWidth) / 2 + 'px';
              this.activeTab = TAB_NAME.COMMENTS
          },
          headerAutoBindEditor:function(editor){
                this.headerRegisterEditorFun(editor);
          },
          autoCheckIfNeed:function(){
              if (TAB_NAME.CONTRACT_CHECK === this.activeTab) {
                  this.startCheck();
              }
          },
          showLoading: function () {
            this.loadingInstance = ElLoading.service({fullscreen: true, text: '加载中'})
          },
          hideLoading: function () {
            if (this.loadingInstance) {
              this.loadingInstance.close();
            }
          },
          standpointChanged:function(settingJSONString){
              this.getContractCheckerRef().onSettingChanged(settingJSONString)
          },
          startCheck:function(){
              this.getContractCheckerRef().active()
              this.getContractCheckerRef().checkContent()
          },
          getAlertCountOfTheErrorType:function(errorType){
              return this.errorStatistic[errorType] ? this.errorStatistic[errorType] : 0;
          },
          filterAlertByType:function(command){
              console.log('filterAlertByType:' + command);
              this.getContractCheckerRef().filterAlertByType(command);
          },
          getContractCheckerRef(){
              return this.$refs['contractChecker'];
          },
          checkingStatusChange(checking){
              console.log('checking:' + checking);
              if (checking) {
                  this.contractChecked = true;
              }
              this.contractChecking = checking;
          },
          textNeedCheck(newTextNeedCheck){
              this.newTextNeedCheck = newTextNeedCheck;
          },
          getHighlightRef(){
              return this.$refs['highlights'];
          },
        contractCheckSuccess(){
          if (this.openBlockTag) {
            this.getBlockTagRef().refreshContractBlockTag();
          }
        },
        contractCheckProgressRatioUpdated(progressRatio, force = false) {
          console.log('progressRatio:' + progressRatio);
          if (force) {
            this.contractCheckProgressRatio = progressRatio;
          }else{
            this.contractCheckProgressRatio = Math.max(progressRatio, this.contractCheckProgressRatio);
          }
        },
        getBlockTagRef(){
          return this.$refs.blockTag;
        },
          handleToggleShowModificationVisualizations(){
            this.showModificationVisualizations = !this.showModificationVisualizations;
          }

        },
      components: {
        ContractBlockTag,
        SvgIcon,
        UserModifyVisualization,
        CommentsInvisibleTipGroup,
        Comment,
          ContractCheckerEditMode,
          CheckerInputBox,
            // ProFileTitle,
            UserInfoDialog,
            CommentDialog,
            Mask,
            PageHeaderFooter
        }
    }
</script>
<style>
    #checkLoadingMask {
        position: fixed;
        width: 454px;
        top: 164px;
        height: calc(100% - 100px);
        z-index: 2;
        pointer-events: none;
    }
    #checkLoadingMask .el-loading-mask{
        background-color: transparent;
        pointer-events: none;
    }
    .custom-header-btn-wrapper {
      display: inline-block;
    }
    .custom-header-btn-wrapper + .custom-header-btn-wrapper{
      margin-left: 15px;
    }
    .custom-header-btn{
        padding:5px;
        cursor: pointer;
    }
    .custom-header-btn:hover{
        background-color: #eff2f5;
    }
    .custom-header-btn.active {
        background-color: #E4EBFF;
    }
    .comments {
        border-bottom: 1px solid #f8e1a1;
    }

    .comments.highlightComments {
        background-color: #f8e1a1;
        border-bottom: 2px solid #f8e1a1;
    }
    .errorTypeMenu .el-dropdown__list{
        margin-top: 0;
        margin-bottom: 0;
    }
    .errorTypeMenu .el-dropdown-menu__item {
        height: 25px;
        line-height: 25px;
    }
    .errorTypeMenu .el-dropdown__list{
        padding-left: 0;
    }

</style>
<style scoped>

    #mainEditor-wrap {
        transition: margin-left  ease-in-out .5s;
    }

    #rightPanelTopMask{
        position: fixed;
        height: 20px;
        /*top: 80px;*/
        z-index: 5;
        padding: 0 10px;
    }

    #alertContainer >>> .el-tabs__header{
        position: fixed;
        width:466px;
    }
    #alertContainer >>> .el-tabs__content{
        padding-top:37px;
    }
    #metaProFileEditor{
      min-height: 1000px;
    }
    #metaProFileEditor >>> .codex-editor{
        background-color: transparent;
    }
    .fixRightContainer{
        position:fixed;right:20px;top:82px;z-index:100;
    }
    .meta-button{
        font-size: 12px;background-color: #5280ff;color: #fff;border-radius: 2px;cursor: pointer;padding:5px;
    }
    .meta-button >>> .el-loading-spinner{
        margin-top:-10px;
    }
    .meta-button >>> .el-loading-spinner .circular{
        width: 20px;
        height: 20px;
    }
    .alertContainer  >>>  .el-tabs .el-tabs__nav .el-tabs__item {
        padding: 3.5px 7px;
        border-radius: 3.5px;
        color: #999;
        height: auto;
        line-height: unset;
    }

    .alertContainer >>>   .el-tabs .el-tabs__nav .el-tabs__item:nth-child(n+3) {
        margin-left: 8px;
    }

    .alertContainer  >>>  .el-tabs .el-tabs__nav .el-tabs__item:not(.is-active):hover {
        background: #EEE !important;
    }

    .alertContainer >>> .el-tabs .el-tabs__nav .el-tabs__item.is-active {
        background: #e4ebff;
        color: #202124;
        line-height: unset;
        height: auto;
    }

    .alertContainer  >>>  .el-tabs .el-tabs__nav-wrap::after {
        display: none;
    }

    .alertContainer  >>>  .el-tabs .el-tabs__active-bar {
        display: none;
    }
    .refreshIcon:hover{
        font-size: 16px;
    }
    .commentDialog-container {
        font-size: 10px;
        position: absolute;
    }
    .commentsPanel {
        position: absolute;
        /*top: 0;*/
        right: 0px;
        /*background: #6a737c;*/
        width: 220px;
        height: 1px;
        font-size: 10px;
    }
    #metaProFileContentDiv {
        width: 100%;
        height: calc(100% - 33px);
    }

    #metaProFileView {
        width: 100%;
      height: 100%;
    }

    #metaProScroller::-webkit-scrollbar {
        display: block;
    }

    #metaProFileViewDiv {
        height: 100%;
        position: relative;
        border-radius: 6px;
        background: white;
    }

    #MetaProFile-open-menu img {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-top: 7px;
    }

    ::-webkit-scrollbar-track-piece {
        background-color: #f8f8f8;
    }

    ::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        background-clip: padding-box;
        min-height: 28px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #bbb;
    }

    .cdx-list--ordered li {
        list-style: decimal;
    }

    .cdx-list--unordered li {
        list-style: disc;
    }

    .whiteBG {
        background: white;
    }

    #metaProFileEditor {
      font-size: 16px;
      box-shadow:rgb(60 64 67 / 15%) 0 1px 3px 1px,inset rgb(60 64 67 / 15%) 0px 1px 1px 0px;
      background: white;
    }

    .editorDisplayModeTransition {
        transition: margin-left 0.5s, width 0.5s, min-width 0.5s, max-width 0.5s, flex 0.5s;
    }

    #mainEditor-wrap{
      padding-right: 230px;
      box-sizing: content-box;
      width: 816px;
      margin-left:auto;
      position:relative;
    }
    #editors-wrap{
      position:relative;
      overflow: auto;
      scrollbar-width: thin;
      height: 100%;
      overflow-y: auto;
      background: var(--page-gray);
    }
    .demo-tabs >>> .el-tabs__header{
      background: var(--page-gray);
      z-index: 1;
    }
    .demo-tabs >>> .el-tabs__content{
      overflow:visible;
    }

</style>
