<template>
  <div class="user-modify-visualization" :id="containerId" ref="umvSelf">
    <div class="span-modification-owner"
         v-show="showOwnerTip"
         :style="{
            top:modificationTipOwnerInfo.position.top + 'px',
            left:modificationTipOwnerInfo.position.left + 'px',
            background:getHashColor(modificationTipOwnerInfo.uid)
         }"
    >
      {{modificationTipOwnerInfo.userName}}
    </div>
  </div>
</template>

<script>
//使用setup方式构建的组件 这样做的好处是方便集中处理一部分的逻辑
import {ref, reactive, onMounted, onUnmounted, watch, computed, onDeactivated, onActivated,} from 'vue';
import {CooperationBlockAvatarManager} from './CooperationBlockAvatarManager';
import {
  DomEventListenerManager,
  getHashColor,
  colors,
  getHashCode, colorLog,
} from "../../util";
import {
  getUniqueColor,
  modificationPrefix,
} from "./modifyUtils";
import {getUserName} from "../../api/api";
export default {
  name: "UserModifyVisualization",
  components: {},
  props:{
    blockDomContainer:{
      required:false
    },
    userPermission:{
      required:false
    }
  },
  setup(props,ctx){
    const containerId = ref('editor-umv');
    const domEventListenerManager = new DomEventListenerManager();
    const usersInfo = reactive({});
    const colorsInfo = reactive(colors.map(color => {
      return {
        color,
        ownerUid:null
      }
    }));

    const alreadyHaveOwnerLength = computed(() => colorsInfo.reduce((sum,c) => sum + (c.ownerUid ? 1 : 0), 0));
    const addEditUser = async (uid) => {
        const res = await getUserName(uid);
        usersInfo[uid] = {
          userName:res.data.data
        };
        let hashCode = Math.abs(getHashCode(uid));
        let index = hashCode % colorsInfo.length;
        let target = colorsInfo[index];
        if(alreadyHaveOwnerLength.value == colorsInfo.length){
          let colorInfo = getUniqueColor(uid,colorsInfo.map(i => i.color));
          let newColor = colorInfo.hex;
          colorsInfo.push({
            color:newColor,
            ownerUid:uid
          });
        }else{
          while(target.ownerUid){
            ++index;
            target = colorsInfo[index];
          }

          target.ownerUid = uid;
        }
    }


    const styleSheet = document.createElement('style');
    styleSheet.setAttribute('type','text/css');
    styleSheet.setAttribute('cooperation-sheet','');
    document.querySelector('head').append(styleSheet);
    //从文档中卸载styleSheet dom元素
    onUnmounted(()=>{
      styleSheet.remove();
    })
    watch(colorsInfo,(_colorsInfo)=>{
      if(props.userPermission.readOnly) return;
      //人数为一个人时不需要标记(就不用加css了)
      // if(alreadyHaveOwnerLength.value < 2) return;

      // 2022-7-25 mys注释
      // [class*=${modificationPrefix.block}]::before{
      //     display:inline-block;
      //     min-width:100px;
      //     position:absolute;
      //     white-space:pre;
      //     height:100%;
      //     border-right:3px solid currentColor;
      //     transform:translateX(calc(-100% - var(---editor-padding-left) + 3px));
      // }
      let textContent = `
              .ce-block{
                  position:relative;
              }
              [class*=${modificationPrefix.insert}]{
                  border-bottom:2px dotted;
              }
              [class*=${modificationPrefix.delete}]{
                  text-decoration:line-through;
                  color:red !important;
              }
      `;
      _colorsInfo.forEach(info => {
        if(!info.ownerUid) return;
        // 2022-7-25 mys注释
        // let blockClass = `.${modificationPrefix.block}${info.ownerUid}`;
        // ${blockClass} +${blockClass}::before{
        //   content:'';
        // }
        // ${blockClass}::before{
        //   content:'${usersInfo[info.ownerUid].userName}    ';
        //   color:${info.color};
        // }
        let inlineClass = `.${modificationPrefix.insert}${info.ownerUid}`;
        textContent += `
          ${inlineClass}{
              border-bottom-color: ${info.color};
          }
        `
      })

      styleSheet.innerHTML = textContent;
    },{deep:true,immediate:true});

    //============文本Tooltip
    const showOwnerTip = ref(false);
    const modificationTipOwnerInfo = reactive({
      node:null,
      userName:'',
      uid:'',
      position:{
        top:0,
        left:0,
      },
    });
    const clearModificationTipOwnerInfo = () => {
      modificationTipOwnerInfo.node = null;
      modificationTipOwnerInfo.uid = '';
      modificationTipOwnerInfo.userName = '';
      modificationTipOwnerInfo.position = {
        top:0,
        left:0,
      };
    }

    const inlineToolTipHandler = (e)=>{
      if(props.userPermission.readOnly) return;
      let currentNode = e.target;
      [...currentNode.classList].find(className => {
        if(className.startsWith(modificationPrefix.insert)){
          modificationTipOwnerInfo.node = currentNode;
          modificationTipOwnerInfo.uid = className.slice(modificationPrefix.insert.length);
          modificationTipOwnerInfo.userName = usersInfo[modificationTipOwnerInfo.uid].userName;
          return true;
        }
      })

      // 2022-7-25 mys注释
      // if(modificationTipOwnerInfo.node) {
      //   let parentNodeBlockUserUid;
      //   [...modificationTipOwnerInfo.node.closest(`[class*=${modificationPrefix.block}]`).classList].find(
      //       className => {
      //         let reg = new RegExp(`${modificationPrefix.block}(.+)`,'g');
      //         let res = reg.exec(className);
      //         if(res){
      //           parentNodeBlockUserUid = res[1];
      //           return true;
      //         }
      //       }
      //   )
      //
      //   showOwnerTip.value = true;
      // }else{
        showOwnerTip.value = false;
        clearModificationTipOwnerInfo();
        return;
      // }
      let positionTop = -Infinity;
      let positionLeft = Infinity;
      let positionRight = -Infinity;
      [modificationTipOwnerInfo.node].forEach((node)=>{
        let {left,bottom,right} = node.getBoundingClientRect();
        if(positionRight < right){
          positionRight = right;
        }
        if(positionTop < bottom){
          positionTop = bottom;
        }
        if(positionLeft > left){
          positionLeft = left;
        }
      });
      modificationTipOwnerInfo.node = null;

      const attachmentNode = document.querySelector(".user-modify-visualization");
      let {top:offsetTop,left:offsetLeft} = attachmentNode.getBoundingClientRect();
      modificationTipOwnerInfo.position.top = positionTop + 5 - offsetTop;
      modificationTipOwnerInfo.position.left= positionLeft + (positionRight - positionLeft) / 2 - offsetLeft;

    };

    const componentActive = ref(false);
    onDeactivated(() => {
      colorLog('onDeactivated UserModifyVisualization',usersInfo);
      componentActive.value = false;
      styleSheet.remove();
      domEventListenerManager.removeListener(document.querySelector(props.blockDomContainer),'mouseover',inlineToolTipHandler);
    });
    onActivated(()=>{
      colorLog.blue('onActivated UserModifyVisualization',usersInfo);
      document.querySelector('head').append(styleSheet);
      componentActive.value = true;
      //绑定mouseover事件来显示文本标识的owner
      domEventListenerManager.registerListener(document.querySelector(props.blockDomContainer),'mouseover',inlineToolTipHandler);
    });
    //============文本Tooltip

    const onlineAvatarManager = new CooperationBlockAvatarManager();

    onMounted(()=>{
      const container = document.querySelector('#'+ containerId.value);
      container.append(onlineAvatarManager.element);
    })

    const addOnlineUser = (userInfo) => {
      onlineAvatarManager.addUserInfo(userInfo);
    }

    const removeOnlineUser = async (userInfo) => {
      // console.time('removeOnlineUser')
      await onlineAvatarManager.userQuitCooperateEditing(userInfo.uid);
      // console.timeEnd('removeOnlineUser')
    }


    const updateUserAvatar = (referenceNode,uid) => {
      onlineAvatarManager.userUpdateOwnBlock(referenceNode,uid);
    }

    const updateGroupsPosition = ()=>{
      onlineAvatarManager.updateGroupsPosition();
    }

    return {
      containerId,
      modificationTipOwnerInfo,
      showOwnerTip,

      addEditUser,
      getHashColor,
      onlineAvatarManager,
      addOnlineUser,
      removeOnlineUser,
      updateUserAvatar,
      updateGroupsPosition
    }
  },
  mounted() {
    window.updateUserAvatar = this.updateUserAvatar;
    window.removeOnlineUser = this.removeOnlineUser;
    window.updateGroupsPosition = this.updateGroupsPosition;
  }
}
</script>

<style scoped>
.user-modify-visualization{
  height: 100%;
  width: 0;
  position: absolute;
  left: 0;
  z-index: 1;
}
.span-modification-owner{
  position: absolute;
  width: max-content;
  color:white;
  padding: 2px 4px;
  font-size:.875rem;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: var(--box-shadow-1);
}
</style>
