/* jshint browser: true */

// var properties = [];
var isBrowser = (typeof window !== 'undefined')
// var isFirefox = (isBrowser && window.mozInnerScreenX != null);

function setStart (node, position, range) {
  if (position.start === 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      // 只有当前textNode有文本，才从它开始
      if (node.textContent.length > 0) {
        range.setStart(node, position.start);
        position.rangeSetted = true;
      }
    } else {
      // 遍历当前节点，只有寻找到第一个有文本的节点才终止，或者最终没有找到而终止
      for (let i = 0; i < node.childNodes.length; i++) {
        setStart(node.childNodes[i], position, range);
        if (position.rangeSetted) {
          return
        }
      }
    }
  } else if (node && position.start > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length <= position.start) {
        // position.content += node.textContent;
        position.start -= node.textContent.length;
      } else {
        range.setStart(node, position.start);
        position.start = 0
        position.rangeSetted = true
      }
    } else {
      for (let lp = 0; lp < node.childNodes.length; lp++) {
        setStart(node.childNodes[lp], position, range);
        if (position.rangeSetted) {
          return
        }
      }
    }
  }
}

function setEnd(node, position, range) {
  if (position.end === 0) {
    range.setEnd(node, position.end);
  } else if (node && position.end > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < position.end) {
        // position.content += node.textContent;
        position.end -= node.textContent.length;
      } else {
        // console.log("set end ")
        // console.log( node)
        // console.log( position.end)
        range.setEnd(node, position.end);
        position.end = 0;
      }
    } else {
      for (let lp = 0; lp < node.childNodes.length; lp++) {
        setEnd(node.childNodes[lp], position, range);

        if (position.end === 0) {
          return
        }
      }
    }
  }
}

export function getDivCaretCoordinates (element, startIndex, endIndex, options) {
  if (!isBrowser) {
    throw new Error('textarea-caret-position#getCaretCoordinates should only be called in a browser')
  }
  return setCurrentCursorPosition(element, element, startIndex, endIndex);
}
export function getRangeRelativePosition(relativeElement,textContainerElement,startIndex,endIndex){
  return setCurrentCursorPosition(relativeElement,textContainerElement, startIndex, endIndex);
}

function setCurrentCursorPosition (relativeElement,textContainerElement,startIndex,endIndex) {
  let range = getDivCaretRange(textContainerElement, startIndex, endIndex)
  // let rangeRect = range.getBoundingClientRect()
  let rangeRects = range.getClientRects()
  let elementRect = relativeElement.getBoundingClientRect()
  let result = []
  for (let i = 0; i < rangeRects.length; i++) {
    let rangeRect = rangeRects[i]
    result.push({
      top: rangeRect.top - elementRect.top,
      left: rangeRect.left - elementRect.left,
      height: rangeRect.height,
      width: rangeRect.width
    })
  }
  return result
}

export function getDivCaretRange (element, startIndex, endIndex) {
  let range = document.createRange()
  range.selectNode(element)
  range.collapse(false)

  setStart(element, {start: startIndex}, range)
  setEnd(element, {end: endIndex}, range)

  // let startPosition={start: startIndex, content: ''}
  // let endPosition={end: endIndex, content: ''}
  // console.log(`start:${startIndex} content:\n${startPosition.content}`);
  // setStart(element, startPosition, range)
  // setEnd(element, endPosition, range)
  // console.log(`end:${endIndex} content:\n${endPosition.content}`);
  return range
}

/**
 *
 * @param node
 * @param range {startOffset:, startContainer: , status, }
 * @returns {number}
 */
function getInputOffset (node, range) {
  if (range == null) {
    return 0
  }
  let offset = 0
  if (node === range.startContainer) {
    offset = range.startOffset
    range.status = 0
  } else if (node.nodeType === Node.TEXT_NODE){
    offset = node.textContent.length
  } else {
    for (let lp = 0; lp < node.childNodes.length; lp++) {
      if (range.status === 1) {
        offset += getInputOffset(node.childNodes[lp], range)
      }
    }
  }
  return offset
}
