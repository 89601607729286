import EditorJS from '../editorjs/editor';
import Delta from "../delta/Delta";
import EventEmitter from 'eventemitter3';
import {cloneDeep} from 'lodash';
import {colorLog} from "../../../util";

export class HdrFtrManager {

    constructor(config,type,numberConfig) {
        this.type = type;
        this.config = config;
        this.numberConfig = numberConfig;
        this.instances = new Map();
        this.emitter = new EventEmitter();
        this._activeInstance = null;
    }
    get activeInstance() {
        return this._activeInstance;
    }

    set activeInstance(value) {
        this._activeInstance = value;
    }

    /**
     * 新增页眉页脚实例
     * @param id 页眉页脚id
     * @param instance 实例对象
     */
    addInstance(id,instance){
        this.instances.set(id, instance);
        instance.on("text-change",(args)=>{
            args.id = id;
            this.emitter.emit("text-change",args);
        });
        instance.on("editor-selected",()=>{
            this.emitter.emit("editor-selected",instance.editor);
        })
    }

    getInstance(id){
        return this.instances.get(id);
    }

    /**
     * 事件绑定
     * @param args
     */
    on(...args){
        this.emitter.on(...args);
    }

    /**
     *
     * @param id
     */
    removeInstance(id){
        this.instances.get(id).destroy();
        this.instances.delete(id);
    }

    /**
     *
     * @param id
     * @return {boolean}
     */
    containsInstance(id){
        return this.instances.has(id);
    }

    /**
     *
     */
    destroy(){
        this.activeInstance?.destroy();
        this.activeInstance = null;
    }

    /**
     * 打开一个页眉页脚编辑器
     * @param uniqueId 页眉页脚的id
     */
    openEditor(uniqueId){
        let instance = this.getInstance(uniqueId);
        if(!instance){
            instance = new HdrFtrInstance();
            this.addInstance(uniqueId,instance);
        }
        instance.openEditor(this.config,this.numberConfig);
        this.activeInstance = instance;
    }

    /**
     * 应用页眉页脚数据
     * @param id 页眉页脚id
     * @param delta 数据
     * @param source 来源
     */
    applyDelta(id,delta,source){
        if(!this.containsInstance(id)){
            this.addInstance(id,new HdrFtrInstance())
        }
        const targetInstance = this.getInstance(id);
        targetInstance.applyDelta(delta,source);
    }
}

export class HdrFtrInstance {
    constructor() {
        this.emitter = new EventEmitter();
        this.delta = new Delta();
        this.isReady = null;
        this.changeHandler = (args)=>{
            this.delta = this.delta.compose(args.change);
            this.emitter.emit("text-change",args);
        }
        this.selectedHandler = (args)=>{
            this.emitter.emit("editor-selected",args)
        }
    }

    on(...args){
        this.emitter.on(...args);
    }

    /**
     *
     * @param delta
     * @param source
     */
    applyDelta(delta,source){
        if(this.editor){
            this.isReady.then(()=>{
                this.editor.content.applyDelta(new Delta(delta),source);
            })
        }

        this.delta = this.delta.compose(delta);

    }

    /**
     *
     * @param config
     * @param numberConfig
     */
    openEditor(config,numberConfig){
        if(this.editor){
            return
        }
        config = cloneDeep(config);
        config.modules["Numbering"] = numberConfig
        this.editor = new EditorJS(config);
        this.isReady = this.editor.isReady;
        this.isReady.then(()=>{
            // console.log('%cthis.delta','color:red',this.delta)
            this.editor.content.applyDelta(cloneDeep(this.delta),'silent');
            this.editor.on("text-change",this.changeHandler);
            this.editor.on("editor-selected",this.selectedHandler)
        })
    }

    /**
     *
     */
    destroy(){
        if(this.editor){
            colorLog.red('headerFooter destroy')
            this.isReady.then(()=> {
                this.editor.off("text-change", this.changeHandler);
                this.editor.off("editor-selected",this.selectedHandler);
                this.editor.destroy();
                this.editor = null;
            })
        }
    }
}
