<template>
  <div class="tagContainer" ref="tagContainer" v-if="activeTag" :style="{top:activeTagPosition.top+'px',left:activeTagPosition.left+'px'}">
    <div >
      <div v-for="item in activeTag.labels" :key="item">{{ item }}</div>
    </div>
  </div>

</template>
<style scoped>
 .tagContainer{
   position: absolute;
   padding:10px;
   background: whitesmoke;
   z-index: 100;
 }
</style>

<script>
import {getContractBlockTag} from "@/api/api";
import { RESULT_CODE} from "@/constant/alert";
import {mergeRectsByAdjacent} from "@/assets/js/alert";
import {getEditorJsDivCaretCoordinates, getEditorJsText} from "@/assets/js/editorjs-caret";
import {debounce} from "@/util";

export default {
  name: "ContractBlockTag",
  props: {
    'contract': {
      required: false,
    },
    'editorId': {
      required: true,
    },
    'getHighlightRef': {required: true},
  },
  data: function () {
    return {
      preCheckText: null,
      blockTags: [],
      activeTag: null,
      activeTagPosition: {
        left: 0,
        top: 0,
      },
      offsetChangeHistory: [],//距离上一次检查的坐标偏移历史
    };
  },
  async mounted() {

  },
  computed: {
    blockTagsMap() {
      let map = new Map();
      for (let i = 0; i < this.blockTags.length; i++) {
        map.set(this.blockTags[i].id, this.blockTags[i]);
      }
      return map;
    }
  },
  watch:{
    blockTags(val) {
      this.refreshErrorHighlight(val)
    },
  },
  methods: {
    init(){
      this.addEditorEventListener()
    },
    refreshContractBlockTag() {
      this.getContractBlockTagFunc();
    },
    getContractBlockTagFunc() {
      let self = this;
      this.editorText = this.getText();
      console.log('editorJSText:***********',{text:this.editorText})
      getContractBlockTag(this.contract.id).then(res => {
        if (res.data.code === RESULT_CODE.WAITING) {
          setTimeout(function () {
            // console.log('继续检查。')
            self.getContractBlockTagFunc()
          }, 1000);
          return;
        } else if (res.data.code !== 0) {
          self.$message.error("获取blockTag失败");
          self.setCheckStatus(false);
        } else {
          this.blockTags = res.data.data;
        }
      });
    },
    getText: function () {
      return getEditorJsText(this.editorId,false);
    },
    getEditorElement: function () {
      return document.getElementById(this.editorId)
    },
    refreshErrorHighlight: function (blockTags) {
      let highlights = []
      let editorElement = document.getElementById(this.editorId);
      let blocks = this.getBlocksElementInfo()
      for (let index in blockTags) {
        let blockTag = blockTags[index];
        let rects = getEditorJsDivCaretCoordinates(editorElement,blocks,false, blockTag.start, blockTag.end);
        //排序，进行merge时似乎需要排好序的range。
        rects = Array.from(rects).sort((a, b) => {
          if (Math.abs(a.top - b.top) > (a.height + b.height) * 0.5 * 0.5) {
            return a.top - b.top;
          }
          if (a.left !== b.left) {
            return a.left - b.left;
          }
          return 0
        })
        //根据不同的显示类型，来合并提示信息。
        const mergedRects = mergeRectsByAdjacent(rects);
        highlights.push({
          // offsetPosition: {top: caret.top, left: caret.left, width: caret.width, height: caret.height},
          offsetPosition: mergedRects,
          hover: false,
          id: blockTag['id'],
        })
      }
      console.log('blockTagHighlights:',highlights)
      this.getHighlightRef().setBlockTagHighlights(highlights)
    },
    getBlocksElementInfo:function (){
      let blocks = document.querySelectorAll(`#${this.editorId} .codex-editor__redactor > .ce-block`);
      let res = [];
      for (let i = 0; i < blocks.length; i++) {
        let childBlocks = blocks[i].querySelectorAll(".ce-block");
        let currentBlock = {block: blocks[i]}
        if (childBlocks.length > 0) {
          currentBlock.childBlocks = childBlocks;
        }
        res.push(currentBlock);
      }
      return res;
    },
    addEditorEventListener: function () {
      let self = this
      let editorElement = this.getEditorElement();
      editorElement.addEventListener('click', function (e) {
        if (!(e.pageX || e.pageY)) {
          // 如果不是一个正常的点击事件（有可能是有focus触发的）
          return
        }
        self.listenBlockTagClicked(e)
      },true);
      this.initEditorChangeFunc();
    },
    listenBlockTagClicked: function (e) {
      let highlights = this.getHighlightRef().getBlockTagHighlights()
      //获取点击点在哪些alert的高亮区域里面
      let activeBlockTagSetted = false;
      for (let i = 0; i < highlights.length; i++) {
        highlights[i].hover = false
        if (activeBlockTagSetted) {
          continue;
        }
        let highlightRefs = this.getHighlightRef().getBlockTagHighlightRef(highlights[i].id);
        if(!highlightRefs){
          continue;
        }
        for (let j = 0; j < highlightRefs.length; j++) {
          let highlightEleRect = highlightRefs[j].getBoundingClientRect()
          if (e.clientX <= highlightEleRect.right && e.clientX >= highlightEleRect.left - 3
              && e.clientY <= highlightEleRect.bottom + 3 && e.clientY >= highlightEleRect.top) {
            this.setActiveBlockTag(highlights[i].id,highlights[i])
            activeBlockTagSetted = true;
          }
        }
      }
      if (activeBlockTagSetted) {
        return;
      }
      this.activeTag = null;
    },
    getBlockTagById:function (id){
      return this.blockTagsMap.get(id);
    },
    setActiveBlockTag:function (id,blockTagHighLight){
      blockTagHighLight.hover = true;
      let firstBlockTagHighlight = blockTagHighLight.offsetPosition[0]
      let blockTag = this.getBlockTagById(id);
      this.activeTag = blockTag;
      this.$nextTick(()=>{
        this.activeTagPosition.top = firstBlockTagHighlight.top - this.$refs.tagContainer.clientHeight - 10;
        this.activeTagPosition.left = firstBlockTagHighlight.left
      })
    },
    initEditorChangeFunc :function(){
      this.editorTextChangeFunc = debounce(()=>{
        let self = this
        let temp = this.getText();
        if (temp.trim() === '') {
          self.restorePosition()
        }
        if (self.editorText != null) {
          let changeLength = temp.length - self.editorText.length;
          if (changeLength === 0) {
            self.refreshErrorHighlight(self.blockTags)
            return
          }
          let offset = self.getChangeOffset(self.editorText, temp);
          self.blockTags = self.updateAlertOffset(self.blockTags, offset, changeLength);
          self.offsetChangeHistory.push({offset: offset, changeLength: changeLength});
        }
        self.editorText = temp
      }, 100);
    },
    getChangeOffset: function (prevValue, newValue) {
      if (prevValue.length > newValue.length) {
        for (let index = 0; index < prevValue.length; index++) { // 删除
          if (newValue[index] !== prevValue[index]) {
            return index
          }
        }
      } else { // 增加
        for (let index = 0; index < newValue.length; index++) {
          if (newValue[index] !== prevValue[index]) {
            return index
          }
        }
      }
    },
    updateAlertOffset: function (blockTags, offset, changeLength) {
      // let originOffset = offset - changeLength
      blockTags = blockTags.filter(blockTag => {
          let changeStart = blockTag.start + changeLength
          //如果修改后range前一段被删除了
          if (blockTag.start > offset && changeStart < offset) {
            return false;
          }
          //如果修改的是range中间的
          if (blockTag.start < offset && blockTag.end > offset) {
            return false;
          }
        return true;
      });
      let newBlockTags = []
      for (let blockTag of blockTags) {
        let filter = false;
        if (blockTag.start >= offset ) {
          blockTag.start += changeLength;
          blockTag.end += changeLength;
          if (blockTag.start < 0) {
            // 如果start应用更改后发现小于0，则把该alert过滤掉。
            filter = true;
          }
        }
        if (!filter) {
          newBlockTags.push(blockTag);
        }
      }
      blockTags = newBlockTags
      return blockTags
    },
  },
  components: {}
}
</script>
